.provider-enh-organization-page {
    @apply max-w-[1152px]
        mx-auto
        pb-6;

    &__section {
        @apply mb-8;

        > p {
            @apply w-3/5;
        }

        .resource-card + .resource-card {
            @apply mt-8;
        }
    }

    &__event-card {
        p + p {
            @apply w-1/2;
        }

        &__columns {
            @apply grid
                grid-cols-2
                gap-7
                mt-4;
        }
    }
}
