.third-party-training-layout-sidebar {
    &__details {

        @apply px-6
            pb-6
            border-b
            border-b-neutral-70;


        p {
            @apply text-center;
        }

        &__tags {
            @apply flex
                flex-wrap
                justify-center
                items-center
                space-x-2
                mt-4
                text-center;

            > * {
                @apply mt-2;
            }
        }
    }

    &__training-type {
        @apply mb-4;

        p {
            @apply text-neutral-50
                uppercase;

            svg {
                @apply inline
                    fill-neutral-50
                    mr-2;
            }
        }
    }

    &__nav {
        @apply pt-6
            px-6;

        > * {
            @apply flex
                items-center
                space-x-2
                transition-colors
                w-full
                p-2
                pr-4
                text-lg
                text-white
                no-underline
                border
                rounded-md;

            border-color: transparent;

            &.-active {
                @apply border-accents-blue-base
                    text-accents-blue-base;
            }

            &.-disabled {
                @apply text-neutral-70;
                svg {
                    @apply fill-neutral-70;
                }
            }

            .text {
                @apply pl-1;
            }

            svg {
                @apply fill-neutral-50;
            }

            + * {
                @apply mt-2;
            }
        }
    }
}
