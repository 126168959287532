.product-activation-achievements-section {
    @apply mt-8;

    &__content {
        @apply mb-6;
    }

    &__expiration-duration {
        @apply mt-6;
    }

    &__sections {
        @apply my-4;
    }

    &__title {
        @apply mb-4;
    }

    .radio-buttons {
        @apply flex
            gap-12;
    }
}
