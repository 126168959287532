.add-bulk-enrollment-status-form {
    &__message {
        @apply leading-5
            text-sm
            mb-14;
    }

    h6 {
        @apply font-bold
            leading-5;
    }
}
