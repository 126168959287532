.user-instructor-teach {
    &__required {
        &__header {
            @apply flex
                items-center
                justify-between;
        }
    }

    table {
        td.action,
        td.status {
            width: 1%;
        }
    }
}
