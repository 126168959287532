.product-activation-courses-section {
    &__section {
        @apply mt-8;

        h2 {
            @apply mb-1;
        }

        h3 {
            @apply mb-0;
        }

        &__header-with-badge {
            @apply flex justify-start;
        }

        p + p {
            @apply mt-1;
        }

        .unit {
            @apply border
                border-neutral-50
                mt-6
                rounded-md
                pb-8;

            + .unit {
                @apply mt-6;
            }

            &.-empty {
                @apply text-center;

                .unit__wrapper {
                    h3 {
                        @apply text-neutral-70
                            mb-2;
                    }

                    > p {
                        @apply mb-6;
                    }
                }
            }

            &__header {
                @apply flex
                    items-center
                    justify-between
                    h-[50px]
                    bg-neutral-05
                    rounded-t-md
                    mb-6
                    pl-16
                    px-2;

                &__details {
                    @apply flex
                        items-center
                        space-x-2;

                    &__sort {
                        @apply w-auto;
                    }
                }

                &__actions {
                    @apply flex
                        space-x-2
                        items-center
                        whitespace-nowrap
                        relative;

                    &__badges {
                        @apply flex
                            space-x-2
                            pr-6;
                    }
                }

                h3 {
                    @apply text-lg
                        font-normal;
                }
            }

            &__wrapper {
                @apply px-6;
            }
        }
    }

    &__courses {
        h2 {
            @apply mb-0;
        }

        &__header {
            @apply grid grid-cols-2;

            &__title {
                @apply flex items-center  space-x-6;
            }

            &__toggle {
                @apply flex justify-end;
            }
        }
    }
}
