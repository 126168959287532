.course-details-form {
    @apply grid
        grid-cols-2
        gap-8;

    .form-field + .form-field,
    .form-field + .course-details-form__form-field-container,
    .course-details-form__form-field-container + .form-field {
        @apply mt-6;
    }

    &__form-field-container {
        @apply relative;
    }

    &__input-description {
        @apply italic
            mt-2
            text-xs
            text-neutral-70;
    }

    textarea {
        height: 8.25rem;
    }
}
