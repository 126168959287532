.learner-online-learning-unit-course-assessment-page {
    &__heading {
        @apply mb-8;
    }

    &__details {
        .assessment-overview {
            &__heading {
                @apply flex
                    items-center
                    space-x-2
                    mb-6;

                h5 {
                    @apply m-0
                        text-2xl
                        font-light;
                }
            }

            &__columns {
                @apply grid
                    grid-cols-1
                    gap-4
                    sm:grid-cols-2
                    md:gap-0
                    md:grid-cols-4;

                > * {
                    @apply flex
                        items-center
                        space-x-4;

                    p {
                        @apply text-sm;

                        &:first-child {
                            @apply font-bold;
                        }
                    }
                }
            }
        }
    }
}
