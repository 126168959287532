.file-input {
    @apply flex
        items-center
        mt-1;

    & > input[type="file"] {
        @apply hidden;
    }

    & > p {
        @apply py-2
            px-4
            text-neutral-90;
    }

    button {
        @apply whitespace-nowrap;
    }
}
