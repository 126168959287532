.event-product-refine-results {
    @apply mb-6;

    &__heading {
        @apply border-b
            border-neutral-50
            mb-1
            text-neutral-90;
    }

    .search-text-input,
    .form-field {
        @apply mb-5;
    }
}
