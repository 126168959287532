.cta-card {
    @apply w-full
        flex
        space-x-12
        p-0;

    &__image {
        @apply pl-7
            pt-7
            w-1/3;
    }

    &__content {
        @apply px-7
            py-7;

        h2 {
            @apply mb-2;
        }

        &__actions {
            @apply mt-4;
        }
    }
}
