.admin-edit-event-change-log-page {
    @apply max-w-[1152px]
    mx-auto
    flex
    justify-between
    p-8
    space-x-8
    h-full;

    &__main {
        @apply w-full
        h-full
        flex
        flex-col;
    }

    &__content {
        @apply h-full
            overflow-y-auto
            mb-8;

        table {
            td {
                @apply align-top;
            }
            .changed-by {
                .user-name,
                .user-id {
                    @apply block;
                }
                .user-id {
                    @apply font-bold
                        text-xxs
                        text-neutral-70
                        tracking-widest;
                }
            }
            .-status-change {
                @apply text-neutral-70
                    italic;
            }
        }
    }
}
