.assessment-question-breakdown-card {
    @apply flex-1;
    & > h3 {
        @apply font-light
            leading-8
            text-2xl;
    }

    td {
        @apply align-top;
    }
}
