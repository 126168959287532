.context-menu-wrap {
    @apply absolute
        mt-2
        z-40
        right-7
        top-7;

        .context-menu {
            @apply rounded-md
                bg-white
                shadow-lg
                border
                border-neutral-30;

                a:first-child {
                    @apply rounded-t-md;
                }
                a:last-child {
                    @apply rounded-b-md;
                }
        }
}
