.form-radio-input {
    @apply cursor-default;
    
    input {
        &:checked,
        &:not(:checked) {
            @apply left-[-9999px]
                absolute;
        }
        &:checked + label,
        &:not(:checked) + label {
            @apply text-neutral-90
                relative
                inline-block
                cursor-default;

            &:before {
                content: "";
                @apply absolute
                    left-0
                    top-[0px]
                    w-[20px]
                    h-[20px]
                    bg-white
                    border-solid
                    border-[1px]
                    rounded-full
                    border-accents-blue-base;
            }
            &:after {
                content: "";

                @apply absolute
                    top-[5px]
                    left-[5px]
                    w-[10px]
                    h-[10px]
                    rounded-full
                    bg-accents-blue-base
                    transition-all;
            }
        }
        &:focus + label {
            &:before {
                @apply border-accents-blue-dark
                    border-[1px]
                    border-solid;
            }
            &::after {
                @apply bg-accents-blue-dark;
            }
        }
        &:not(:checked) + label:before {
            @apply border-neutral-50;
        }
        &:not(:checked) + label:after {
            @apply opacity-0
                scale-0;
        }
        &:checked + label:after {
            @apply opacity-100
            scale-100;
        }
    }

    label {
        @apply pl-7
            text-sm
            tracking-tight
            break-words;
    }

    &.selected {
        label {
            // @apply from-accents-blue-base;
        }
    }
}
