.numbered-badge {
    @apply block
        font-normal
        h-8
        mt-[-0.35rem]
        p-[0.425rem]
        text-center
        w-8;

    @screen -lg {
        @apply mt-0;
    }
}
