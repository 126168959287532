.admin-announcements-list-page {
    @apply max-w-[1152px]
        mx-auto
        mt-8
        px-8;

    &__header {
        @apply grid
            gap-4
            mb-8;

        &__actions {
            @apply flex
                items-center
                relative
                space-x-4;
        }

        &__button {
            @apply disabled:fill-neutral-70;
        }

        @screen md {
            @apply grid-cols-[1fr_min-content]/* comment to keep things from breaking */;
        }
    }

    &__section {
        @apply mb-8;

        h2 {
            @apply mb-2;
        }

        &__empty {
            p + button {
                @apply mt-6;
            }
        }

        &:last-child {
            @apply mb-0;
        }

        @screen md {
            @apply mb-16;
        }
    }
}
