.user-contract-ill-detail-info {
    h3 {
        @apply mb-8;
    }

    &__section {
        @apply w-full;

        + .user-contract-ill-detail-info__section {
            @apply mt-10;
        }

        .badge + .badge {
            @apply ml-2;
        }
    }

    &__grid {
        @apply grid
            grid-cols-1
            md:grid-cols-2
            lg:grid-cols-3
            gap-8;

        &-half {
            @apply grid
                grid-cols-1
                md:grid-cols-2
                gap-8;

            > :last-child {
                > * {
                    max-width: 80%;
                }
            }
        }
    }
}
