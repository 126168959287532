.event-link-trial-form {
    &__header {
        @apply flex justify-between;

        h3 {
            @apply font-light
                mb-4
                text-2xl;
        }
    }

    &__grid {
        @apply grid
            grid-cols-2
            gap-8
            mb-4;
    }
}
