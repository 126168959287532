.event-cancellation-modal {
    &__content {
        .-description {
            @apply mb-6;
        }
        .-message-description {
            @apply mb-4;
        }
        .-cancellation-message {
            @apply mb-4;
        }
    }
}
