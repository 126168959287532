.create-online-product-materials-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__archived-banner {
        @apply pl-[3.5rem] rounded-none w-full;
    }

    &__cancel-edit-mode-modal {
        .modal__panel-inverted {
            @apply w-96;
        }
    }

    &__create-version-modal-title {
        @apply mb-3;
    }

    &__header {
        @apply mb-8;

        .button-icon {
            @apply align-middle
                px-6;
        }
    }
}
