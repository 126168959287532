.learner-enrollment-list {
    &__product-name {
        @apply cursor-pointer
            underline;
    }

    .-collapse {
        @apply px-0
        w-1
        whitespace-nowrap;
    }

    .-no-results-row {
        @apply text-center italic;
    }
}
