.bubble {
    @apply font-bold
        leading-[0.6875rem]
        px-3
        py-2
        rounded-[6.25rem]
        text-xs
        w-fit
        whitespace-nowrap
        border;

    &.-topic-beige {
        @apply bg-topic-beige
            border-topic-beige
            text-neutral-90;
    }

    &.-topic-brown {
        @apply bg-topic-brown
            border-topic-brown
            text-white;
    }

    &.-topic-dark-blue {
        @apply bg-topic-darkBlue
            border-topic-darkBlue
            text-white;
    }

    &.-topic-gray {
        @apply bg-topic-gray
            border-topic-gray
            text-white;
    }

    &.-topic-green {
        @apply bg-topic-green
            border-topic-green
            text-white;
    }

    &.-topic-light-blue {
        @apply bg-topic-lightBlue
            border-topic-lightBlue
            text-white;
    }

    &.-topic-orange {
        @apply bg-topic-orange
            border-topic-orange
            text-white;
    }

    &.-topic-red {
        @apply bg-topic-red
            border-topic-red
            text-white;
    }

    &.-topic-white {
        @apply bg-topic-white
            border-neutral-30
            text-neutral-90;
    }

    &.-blue-dark {
        @apply bg-accents-blue-dark
                text-white;
    }

    &.-blue {
        @apply bg-accents-blue-light
            border-accents-blue-light
            text-accents-blue-dark;
    }

    &.-brown {
        @apply bg-accents-brown-base
                text-white;
    }

    &.-grey {
        @apply bg-neutral-05
            text-neutral-70;
    }

    &.-grey-dark {
        @apply bg-neutral-90
            text-white;
    }

    &.-green {
        @apply bg-accents-green-dark
            text-white;
    }

    &.-orange {
        @apply bg-status-warning-base
            border-status-warning-base
            text-white;
    }

    &.-orange-light {
        @apply bg-status-warning-light
            border-status-warning-light
            text-accents-yellow-dark;
    }

    &.-red {
        @apply bg-accents-red-base
            border-accents-red-base
            text-white;
    }

    &.-white {
        @apply bg-white
            border-neutral-30
            text-neutral-90;
    }
}
