.activate-new-version-modal {
    .modal__panel {
        @screen sm {
            width: 885px;
        }
    }

    &__assignment {
        @apply bg-neutral-05
            grid
            gap-4
            grid-cols-2
            mt-[0.6rem]
            p-3
            rounded
            text-neutral-70
            text-xs;
    }

    &__bullet {
        @apply mr-2.5;
    }

    &__change-log {
        @apply mb-3;
    }

    &__modal-heading {
        @apply text-[1.625rem]
            leading-9;

        &-description {
            @apply mb-8;
        }
    }

    &__text-area {
        @apply mt-4;
    }
}
