* {
    @apply box-border;
}

html,
body {
    @apply min-h-screen;
}

body {
    @apply antialiased
        font-body
        text-neutral-90;
}

#app-root {
    @apply h-screen
        w-screen
        min-w-[320px]
        max-w-full;
}

a[href='#chakra-skip-nav'] {
    z-index: 101;
    text-decoration: underline;
}

@supports (height: 1dvh) {
    html,
    body {
        min-height: 100dvh;
    }
    #app-root {
        height: 100dvh;
    }
}

/**
    TYPOGRAPHY
    -------------------------------------
**/

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply font-heading
        font-normal
        tracking-normal;
}

h1 {
    @apply text-3xl;
}

h2 {
    @apply text-2xl;
}

h3 {
    @apply text-xl;
}

h4 {
    @apply text-lg;
}

h5 {
    @apply text-base;
}

h6 {
    @apply text-sm;
}

.clearfix:before,
.clearfix:after {
    content: ".";    
    display: block;    
    height: 0;    
    overflow: hidden; 
}
.clearfix:after { clear: both; }
.clearfix { zoom: 1; } /* IE < 8 */
