.c-full-screen-transition {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: #001a39;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        letter-spacing: 0.02em;
        text-align: center;
        width: 60%;

        &__loader {
            display: block;
            position: relative;
            height: 12px;
            width: 80%;
            border: 1px solid #ffffff;
            border-radius: 10px;
            overflow: hidden;
        }
        &__loader::after {
            content: "";
            width: 40%;
            height: 100%;
            background: #ffffff;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            animation: animloader 2s linear infinite;
        }

        @keyframes animloader {
            0% {
                left: 0;
                transform: translateX(-100%);
            }
            100% {
                left: 100%;
                transform: translateX(0%);
            }
        }

        img {
            padding: 100px;
        }

        &__transition-text {
            margin-top: 16px;
            color: white;
        }
    }
}
