.add-bulk-enrollment-form {
    @apply inline-block;

    &__bullet {
        @apply mx-2.5;
    }

    &__instructions {
        @apply mb-4
            text-sm;
    }

    &__file-upload {
        .button {
            @apply mb-6
                text-accents-blue-dark
                underline;
        }

        label {
            @apply font-bold
                leading-5
                mb-1
                text-heading;
        }
    }

    &__requirements {
        @apply
            text-sm;
    }

    h6 {
        @apply font-bold
        leading-5;
    }

    ul {
        @apply mb-12;
    }
}
