.event-activation-organization-section {
    @apply mt-8;

    h3 {
        @apply font-normal
            text-neutral-90
            text-lg
            mb-4;
    }

    p.-label + p {
        @apply mt-2;
    }

    &__container {
        @apply grid
            grid-cols-3
            grid-rows-2
            gap-y-8;
    }
}
