.side-content-left-layout {
    @apply flex
        flex-1;

    > *:first-child {
        @apply flex-col
            border-r
            border-r-neutral-50
            w-1/4;
    }

    > *:last-child {
        @apply flex
            flex-col
            flex-1
            w-3/4
            relative;

        .content-wrap {
            @apply grow
                overflow-y-scroll
                -lg:overflow-y-visible
                p-8;
    
            .content {
                @apply max-w-[1152px]
                    mx-auto;
            }
        }

        .footer {
            @apply border-t
                border-t-neutral-50
                shrink-0;
        }
    }

    @screen xl {
        >*:first-child {
            width: 320px;
        }

        >*:last-child {
            width: calc(100% - 320px);
        }
    }

    @screen -lg {
        @apply flex-col;

        > *:first-child {
            @apply w-full
                border-r-0
                border-b
                border-b-neutral-50;
        }

        > *:last-child {
            @apply w-full;
        }
    }
}
