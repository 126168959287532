.event-activation-ceu-section {
    @apply mt-8;

    &__header {
        @apply flex
            items-center;

        h3 {
            @apply mr-6;
        }

        .badge {
            @apply mb-6;
        }

        .badge + .badge {
            @apply ml-3;
        }
    }

    &__lock {
        @apply mr-1;
    }

    .modal__panel {
        @screen sm {
            width: 885px;
        }
    }
}
