.instructor-led-product-content-list {
    @apply mb-10;
    
    td {
        @apply align-top;
    }
    td.description {
        p {
            @apply max-w-[336px]
                text-xs;
        }
    }
    &__empty {
        @apply pt-0;
    }
}
