.event-schedule-footer {
    &__totals {
        span > button > svg {
            @apply fill-neutral-90;
        }

        .-exception-needed {
            p,
            span {
                @apply text-accents-red-base;
            }
            span > button > svg {
                @apply fill-accents-red-base;
            }
        }

        .-exception-granted {
            p,
            span {
                @apply text-accents-green-dark;
            }
            span > button > svg {
                @apply fill-accents-green-dark;
            }
        }

        @apply flex
            flex-row
            gap-6
            items-center;

        &__class-time,
        &__ceus {
            @apply leading-tight;

            .title,
            .amount {
                @apply block
                        text-sm;
            }

            .title {
                @apply font-bold;
            }
        }
    }

    &__aen-exceed-hours-message {
        p {
            @apply text-accents-red-base
                text-xs
                italic;
        }
    }
}
