.product-materials-changes-modal {
    .modal__panel {
        @screen sm {
            width: 885px;
        }
    }

    &__change-log {
        @apply mb-3;
    }

    &__modal-heading {
        @apply text-[1.625rem]
            leading-9;

        &-description {
            @apply mb-8;
        }
    }

    &__text-area {
        @apply mt-4;
    }
}
