.card {
    @apply h-auto
        pb-7
        pt-6
        px-7
        rounded-md
        shadow-card
        break-words;

    &.-stacked + .card.-stacked {
        @apply mt-8;
    }

    &.-tight {
        @apply py-3
            px-5;
    }

    &.-flat {
        @apply shadow-none
            border
            border-neutral-30;
    }

    &.-empty {
        @apply text-center;

        svg {
            @apply fill-neutral-70;
        }

        :first-child {
            @apply mt-6;
        }

        :last-child {
            @apply mb-6;
        }
    }
}
