.learner-online-learning-content-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;
    
    &__title {
        @apply flex
            fill-accents-blue-base
            items-center
            mb-4;

        svg {
            @apply mr-1;
        }
    }

    &__content-section {
        @apply gap-8
            grid
            grid-cols-2
            mb-10;
    }

    @screen -md {
        &__content-section {
            @apply grid-cols-1;
        }
    }

    &__content-footer {
        @apply italic;
    }
}
