.badge {
    @apply bg-neutral-05
        font-bold
        px-3
        py-1.5
        rounded-full
        text-neutral-70
        text-xs
        whitespace-nowrap
        inline-flex
        gap-2
        items-center;

    &.-error {
        @apply bg-accents-red-light
            text-accents-red-dark
            fill-accents-red-dark;
        svg {
            @apply fill-accents-red-dark;
        }
    }

    &.-inverted {
        @apply bg-neutral-70
            text-white
            fill-white;
        svg {
            @apply fill-white;
        }
    }

    &.-light {
        @apply border
            border-neutral-70
            text-neutral-70
            fill-neutral-70
            bg-white;
        svg {
            @apply fill-neutral-70;
        }
    }

    &.-neutral {
        @apply bg-accents-blue-light
            text-accents-blue-dark
            fill-accents-blue-dark;
        svg {
            @apply fill-accents-blue-dark;
        }
    }

    &.-dark {
        @apply bg-accents-blue-dark
            text-accents-blue-light
            fill-accents-blue-light;
        svg {
            @apply fill-accents-blue-light;
        }
    }

    &.-success {
        @apply bg-status-success-light
            text-status-success-dark
            fill-status-success-dark;
        svg {
            @apply fill-status-success-dark;
        }
    }

    &.-transparent {
        @apply text-white
            border
            border-white
            font-bold
            tracking-wide;

        background-color: transparent;
    }

    &.-transparent-alt {
        @apply text-neutral-70
            border
            border-neutral-70
            font-bold
            tracking-wide;

        background-color: transparent;
    }

    &.-warning {
        @apply bg-accents-yellow-light
            text-accents-yellow-dark;
        svg {
            @apply fill-accents-yellow-dark;
        }
    }

    &.-warning-bright {
        @apply bg-accents-yellow-light
            text-accents-yellow-dark;
        svg {
            @apply fill-accents-orange-dark;
        }
    }
}
