.course-row {
    @apply w-full
        flex
        items-center
        justify-between;

    padding-right: 3.8rem;

    + .course-row {
        @apply mt-3;
    }

    &__details {
        @apply flex
            justify-end;

        .bubble-wrap {
            @apply w-28;
        }
        .score-wrap {
            @apply w-14;

            p {
                @apply flex
                    h-full
                    items-center
                    text-neutral-70
                    font-bold;

                svg {
                    @apply fill-neutral-70
                        mr-1;
                }
            }
        }
    }
}
