.user-contracts-oll-new-page {
    @apply flex
        flex-col
        h-full;

    .side-content-left-layout {
        @apply overflow-hidden;

        & > *:last-child {
            @apply overflow-hidden;
        }
    }

    &__grid {
        @apply grid
            grid-cols-3
            gap-6;

        + .user-contracts-oll-new-page__grid {
            @apply mt-6;
        }
    }

    .card {
        @apply mb-6;
    }

    .footer {
        @apply flex
            items-center
            justify-end
            h-20
            p-8;
    }
}
