.operator-dashboard-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__banner {
        @apply flex
            items-center
            justify-between
            w-full
            py-2.5;

        &__message {
            @apply flex
                items-center
                space-x-1;
        }

        &__actions {
            @apply flex
                space-x-2;

            > a {
                @apply text-xs;
            }
        }

        + .operator-dashboard-page__banner {
            @apply mt-2;
        }
    }

    h2 {
        @apply mb-5;
    }

    &__section {
        @apply mb-20;
    }

    &__heading {
        @apply flex
            items-center
            justify-between
            w-full
            mb-4;

        h2 {
            @apply mb-0;
        }
    }

    &__notifications {
        @apply mb-10;
    }
}
