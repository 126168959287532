.event-schedule-card {
    .day,
    .session,
    .time {
        @apply whitespace-nowrap;
    }
    td {
        @apply align-top;
    }
    td.day,
    td.attendance,
    td.session,
    td.time {
        width: 1%;
    }
    td.attendance {
        @apply pt-3;
        
        .untracked {
            path {
                @apply fill-neutral-50;
            }
        }
        .attended {
            @apply fill-accents-green-base;
        }
        .not-attended {
            mask rect {
                @apply fill-neutral-70;
            }
        }
    }
    thead,
    tbody {
        tr:not(:last-child) {
            @apply border-b-0;
        }
    }
    tbody {
        tr:not(.event-day-block__additional-rows) {
            @apply border-t-[0.84px]
                border-t-neutral-30;
        }
    }
    tr.event-day-block {
        &__additional-rows {
            td {
                @apply pt-0;
            }
        }
    }
}
