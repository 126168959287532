.notification-banner {
    @apply max-w-[1152px]
        mx-auto
        py-6;

    &__banner {
        @apply flex
            gap-2
            items-center
            w-full
            py-2.5;

        &__content {
            @apply flex
                w-full;

            button {
                svg {
                    @apply mr-0;
                }
            }
        }

        &__message {
            @apply flex
            gap-2
            items-center
                w-full;

            .content {
                @apply grow
                    break-words;
            }
        }

        &__actions {
            @apply flex
                space-x-2
                whitespace-nowrap;

            > a {
                @apply text-xs;
            }
        }

        + .notification-banner__banner {
            @apply mt-2;
        }

        &.banner {
            svg {
                @apply mr-0;
            }
        }
    }

    &__icon-light-inverted {
        @apply fill-neutral-70;
    }

    @screen -md {
        &__banner {
            &__message {
                @apply flex-col
                    items-start;
            }
        }
    }
}
