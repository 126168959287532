.third-party-online-learning-enrollment-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;
    
    &__header {
        @apply flex
            items-center
            justify-between
            mb-8;

        &__title {
            @apply flex
                fill-accents-blue-base
                items-center
                space-x-2;
        }
    }

    &__table {
        @apply mt-6
            pb-0;
    }
}
