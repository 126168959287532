.assessment-row {
    @apply w-full
        flex
        gap-8
        items-center
        justify-between;

    + .assessment-row {
        @apply mt-3;
    }

    &__name {
        
    }

    &__details {
        @apply flex
            gap-8
            items-center;

        > * {
            @apply text-center;

            > * {
                @apply inline-block;
            }
        }

        > :nth-child(3) {
            p {
                @apply flex
                    gap-1
                    items-center
                    text-neutral-70
                    font-bold;

                svg {
                    @apply fill-neutral-70;
                }

                span {
                    @apply w-8;
                }
            }
        }
    }

    @screen -md {
        @apply flex-col
            gap-2
            items-start;
    }
}
