.user-trainings-page {
    @apply flex
        flex-col
        h-full;

    &__header {
        @apply flex
            gap-8    
            items-center
            mb-8;

        h2 {
            @apply mb-0;
        }

        &__search {
            @apply w-80;

            .search-text-input {
                @apply mb-0;
            }
        }

        &__actions {
            @apply flex
                grow
                items-center
                justify-end;
        }
    }

    table {
        td.type,
        td.enrollment,
        td.completion {
            width: 1%;
        }
    }
}
