.time-picker {
    @apply text-left;
    
    $clockIconheight: 0.875rem;
    $clockIconWidth: 0.765625rem;

    &__chevron {
        @apply fill-neutral-70
            flex-none
            mt-1;
    }

    &__clock {
        height: $clockIconheight;
        width: $clockIconWidth;
        @apply flex-initial
            mt-0.5;

        path {
            @apply fill-neutral-70;
        }
    }

    &__label {
        @apply flex-auto
            text-neutral-70
            text-sm
            pl-2;
    }

    &__text {
        @apply border
            flex
            h-10
            justify-items-center
            p-2
            rounded-md
            w-60
            disabled:bg-neutral-05
            disabled:border-neutral-50
            disabled:text-neutral-70
            border-neutral-70
            focus-visible:border-accents-blue-dark
            focus-visible:outline-none
            focus-visible:shadow-accents-blue-base/50
            focus-visible:shadow-focus-visible;

        &.-disabled {
            @apply bg-neutral-05
                        border-neutral-50
                        text-neutral-70;
        }
    }
}

.react-datepicker {
    &__header--time {
        @apply hidden
            bg-white;
    }
}
