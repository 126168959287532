.admin-layout-header-profile-selector {
    $headerHeight: 5rem;

    @apply bg-neutral-70
        w-[320px]
        overflow-y-scroll
        py-8;
    height: calc(100vh - $headerHeight);
    
    &__announcement {
        @apply bg-neutral-70
            overflow-y-scroll
            w-[320px]
            py-8;
        height: calc(100vh - $headerHeight);
        letter-spacing: 0.32px;

        &__Title {
            @apply px-8;
            color: #fff;
            font-size: 1.125rem;
            margin: revert;
        }
        
        &__Details {
            @apply px-8;
            
            & > * {
                color: #fff;
                margin: revert;
                padding: revert;
            }

            p,
            ul,
            ol,
            blockquote {
                font-size: 0.875rem;
            }

            ul,
            ol {
                list-style: revert;
            }

            a {
                text-decoration: revert;
            }
        }

        @screen -xs {
            @apply w-full;
        }
    }

    &__close {
        @apply block
            fill-neutral-05
            font-bold
            mb-9
            no-underline
            px-8
            text-end
            text-neutral-05
            text-xs
            tracking-[0.25rem]
            w-full
            hover:fill-accents-blue-base
            hover:text-accents-blue-base;
    }

    &__user-roles {
        @apply overflow-y-auto
            px-8;
    }

    @screen -xs {
        @apply w-full;
    }
}
