.event-instructor-card {
    @apply flex
        h-[128px]
        rounded
        shadow-neutral-50
        shadow-card
        relative;

    &__detail {
        @apply grow
            bg-white
            pt-4
            rounded-l;

        p {
            @apply px-4
            line-clamp-2;
            word-break: break-word;
        }
    }

    &__topic {
        @apply text-neutral-70
            text-sm
            flex-grow;
    }

    &__select-action {
        @apply mt-auto
            absolute
            bottom-4
            left-4;
    }

    &__image-container {
        @apply w-[90px]
            shrink-0;

        @apply rounded-r
            overflow-hidden;
    }

    &__info-button {
        @apply absolute
            top-4
            right-4
            z-20
            opacity-75;
    }
}
