.announcement-modal {
    @apply relative;

    &__container {
        @apply border
            border-neutral-50
            rounded-lg;

        .tab-bar {
            @apply px-3
                mb-0;
        }

        &__body {
            @apply p-3;
        }
    }

    .modal__header {
        @apply bg-white rounded-t-lg;

        &__actions {
            .button-icon {
                @apply text-neutral-70
                    tracking-widest;

                &__text {
                    @apply pr-0
                        text-xs
                        font-bold;
                }
            }
        }
    }

    .modal__header-close {
        @apply hidden;
    }

    .modal__body {
        @apply py-0
            px-6
            relative;
    }

    .modal__footer {
        @apply flex
            justify-between
            items-center
            border-0;
    }
}
