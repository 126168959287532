.quick-link {
    @apply flex
        items-center
        w-full
        p-3
        py-5
        gap-3
        bg-neutral-90
        rounded-md
        text-white
        hover:bg-neutral-70
        hover:text-white
        no-underline;

    .icon {
        @apply fill-accents-blue-base
            m-0;
    }

    &__text {
        @apply text-sm
            font-bold;
    }

    &.anchor > *:last-child {
        @apply hidden;
    }
}
