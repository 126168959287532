.product-activation-content-section {
    &__contents__header {
        @apply flex justify-between;
    }

    &__section {
        @apply mt-8;

        h2 {
            @apply mb-0;
        }

        h3 {
            @apply text-xl
                mb-0;
        }

        &__header-with-badge {
            @apply flex justify-start;
        }

        p + p {
            @apply mt-1;
        }
        .data-table {
            td {
                @apply align-top;
            }
            td.description {
                p {
                    @apply text-xs;
                }
            }
        }
    }

    &__second-table {
        @apply mt-4;
    }
}
