.product-archive-modal {
    &__header {
        @apply mb-8;
    }

    &__icon {
        @apply align-middle
            mr-2;
    }

    &__item {
        @apply mt-4 text-neutral-70;
    }

    .-checked {
        @apply fill-accents-green-base;
    }

    svg {
        @apply fill-neutral-50;
    }
}
