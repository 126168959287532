.admin-layout-header-announcements {
    @apply flex
        items-center
        bg-white
        border-b
        border-b-border;

    &__actions {
        @apply flex
            items-center
            h-full
            w-20
            space-x-4
            relative;

        &__close {
            @apply bg-neutral-90;

            svg {
                @apply fill-white;
            }
        }

        &__open {
            svg {
                @apply fill-neutral-70;
            }
        }

        &__close,
        &__open {
            @apply h-full
                        rounded-none
                        w-full;
        }

        .button {
            @apply h-full
                w-full;

            &.-unread {
                @apply relative;

                &::before {
                    @apply content-['']
                        absolute
                        top-7
                        bg-accents-blue-base
                        right-5
                        z-50
                        w-2
                        h-2
                        rounded-md;
                }
            }
        }
    }
}
