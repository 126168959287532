.enrollment-list {
    &__enrollment-id {
        @apply cursor-pointer
            underline;
    }

    &__account-name {
        @apply cursor-pointer
            text-base
            text-neutral-90;

        .badge {
            @apply mb-1;
        }
    }

    &__account-email {
        @apply text-neutral-70
            font-bold;
        text-transform: uppercase;
    }

    td.enrollment {
        width: 1%;
    }
}
