.instructor-assessment-card {
    &__section {
        @apply mb-8;
    }

    &__enrollments {
        .enrollment {
            @apply flex
                items-center
                justify-between
                mt-3
                w-full;

            &__name {
                width: 80%;
            }

            &__details {
                @apply grid
                    grid-cols-2;

                width: 20%;

                .action {
                    @apply relative
                        text-right;
                }

                .badge {
                    @apply justify-center;
                }
            }
        }
    }
}
