.instructor-info {
    &__about-me {
        @apply mb-6
            col-span-2;
    }

    &__bottom-section {
        @apply grid
            grid-cols-2
            grid-rows-2;
    }

    &__contact {
        @apply pr-8;
        dd {
            @apply break-words;
        }
    }

    &__details {
        @apply col-span-2
            mb-8
            ml-8
            pr-8
            w-full;
    }

    &__details-with-image {
        @apply grid
            grid-cols-3
            rounded;
    }

    &__details,
    &__image-container {
        @apply h-36
            w-36
            rounded;
    }

    &__download {
        @apply mt-[0.4rem]
            row-start-3;
    }

    &__header {
        @apply font-light
            col-span-2
            mb-0
            text-2xl;
    }

    &__image-container {
        img {
            @apply h-auto
                ml-auto
                w-[12rem]
                rounded;
        }
    }

    &__list {
        @apply list-disc
            pl-10;
    }

    &__location {
        @apply mb-3
            mt-[0.4rem]
            text-base;
    }

    dd {
        @apply mb-6
            text-neutral-90
            text-sm
            whitespace-normal;
    }

    dt {
        @apply font-bold
            capitalize
            text-neutral-90
            text-xs
            tracking-widest;
    }
}
