.product-details-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    .notification-banner__banner {
        @apply px-5;
    }

    &__archived-banner {
        @apply pl-[3.5rem] rounded-none w-full;
    }

    &__banner {
        @apply mb-6
                -mt-4;

        .banner {
            @apply justify-start;
        }
    }

    &__cancel-edit {
        .modal__body-inverted {
            @apply px-4;
        }

        .modal__body__content-wrap {
            @apply px-2
                    py-2;
        }

        .modal__panel-inverted {
            @screen sm {
                width: 336px;
            }
        }
    }

    &__cancel-edit-mode-modal {
        .modal__panel-inverted {
            @apply w-96;
        }
    }

    &__divider {
        @apply bg-neutral-30
            mb-8;

        height: 1px;
    }

    &__edit-mode-buttons {
        .button {
            @apply align-middle;
        }

        .button-icon {
            @apply px-6;
        }
    }

    &__header {
        @apply mb-8;
    }

    &__modal-heading {
        @apply mb-4;
    }
}
