.report-all-trainings {
    h2 {
        @apply mb-8;
    }
    
    table {
        td.id {
            width: 1%;
        }
    }
}
