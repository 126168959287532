.syllabus-unit-card {
    &__header {
        @apply flex
            items-center
            justify-between
            mb-6;
    }

    &__details {
        @apply grid
            grid-cols-2
            mt-8;
    }

    &__actions {
        @apply mt-8;

        button {
            @apply flex
                items-center
                space-x-2
                w-full;
        }
    }
}
