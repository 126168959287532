.product-content-manager {
    &__banner {
        @apply mb-8;
    }

    &__header {
        @apply w-full
            grid
            grid-cols-2
            justify-between
            items-center
            mb-8;

        h5 {
            @apply font-light m-0;
        }

        &__actions {
            @apply flex
            gap-5
            items-center
            justify-end;
        }
    }
    &__content {
        .empty-text {
            @apply mb-10;
        }
    }
}
