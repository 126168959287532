.admin-layout-header-profile {
    @apply border-b
        border-b-neutral-70
        flex
        h-24
        gap-4
        items-center
        px-6
        py-8
        w-full;

    &__avatar {
        @apply bg-accents-blue-base
            border-2
            border-accents-blue-dark
            flex
            h-9
            items-center
            justify-center
            rounded-full
            w-9;
    }

    &__name,
    &__profile-toggle {
        @apply block
            font-normal
            text-start
            text-white
            w-full;
    }

    &__profile-toggle {
        @apply text-xs
            underline
            hover:fill-accents-blue-base
            hover:text-accents-blue-base;
    }
}
