.admin-event-list-page {
    @apply h-full
        w-full;

    .header-banner {
        height: 126px;
    }

    &__calendar {
        &__toggles {
            @apply flex
                flex-row
                justify-start
                mb-12
                mt-2
                space-x-[0.0625rem]
                w-full
                h-12;
        }

        &__view {
            @apply pt-8
                px-8
                max-w-[1152px]
                mx-auto;
        }

        &__wrapper {
            @apply pb-12;
        }
    }

    &__view-type {
        @apply flex
            justify-start;
    }

    &__list {
        &__view {
            @apply flex
                flex-row
                grow
                w-full
                h-full;
        }
    }

    &__window {
        @apply w-full;

        height: calc(100% - 126px);

        &__sidebar {
            @apply self-stretch
                p-10
                flex-col
                overflow-y-auto
                w-1/4
                h-full;

            &__filter {
                &__title {
                    @apply my-4;
                }

                &__selections {
                    .search-text-input,
                    .form-field {
                        @apply mb-6;
                    }
                }
            }
        }

        &__main {
            @apply flex
                flex-col
                justify-between
                border-l
                border-neutral-50
                h-full
                w-3/4;

            &__events {
                @apply overflow-y-auto;
                max-height: calc(100% - 6.5rem);

                &__content {
                    @apply max-w-[1152px]
                        mx-auto
                        p-10;
                    &__calendar-view {
                        @apply flex justify-center;
                    }
                }
            }

            &__pager {
                @apply flex
                    h-[6.5rem]
                    border-t
                    border-neutral-50;

                .pager {
                    @apply py-0;
                }
            }
        }
    }

    @screen xl {
        &__window {
            &__sidebar {
                @apply w-[320px]/* ... */;
            }
            &__main {
                width: calc(100% - 320px);
            }
        }
    }
}
