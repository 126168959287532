.user-training-detail-ilt-progress {
    &__section {
        @apply mb-8;
    }

    &__assessments {
        .assessment {
            @apply w-full
                flex
                items-center
                justify-between
                mt-3;

            &__name {
                width: 50%;
            }

            &__details {
                @apply grid
                        grid-cols-5;

                width: 50%;

                > * {
                    @apply text-right;

                    > * {
                        @apply inline-block;
                    }
                }

                > :nth-child(4) {
                    p {
                        @apply text-neutral-70
                            font-bold;

                        svg {
                            @apply fill-neutral-70
                                mr-1;

                            + * {
                                @apply relative;
                                top: 1px;
                            }
                        }
                    }
                }

                > :last-child {
                    @apply relative
                        text-right;

                    .context-menu {
                        @apply text-left;
                        width: 175px;
                    }
                }
            }
        }
    }
}
