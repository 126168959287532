.assign-training-modal {
    table {
        th:last-child,
        td:last-child {
            @apply text-right;
        }
    }

    &__table {
        @apply h-80
            overflow-y-auto;
    }
}
