.maintenance-page {
    @apply h-full;

    &__body {
        @apply bg-accents-red-light
            flex
            flex-col
            h-full
            items-center
            justify-center
            m-auto
            text-accents-red-dark;
    }

    &__header {
        @apply border-b
        border-neutral-30
        flex
        h-20
        items-center;

        &-logo {
            @apply flex
            items-center
            h-[80px]
            w-[80px]
            justify-center;

            img {
                @apply w-[42px]
                min-w-[36px]
                m-0;
            }
        }

        &-text,
        &-actions {
            @apply flex
            h-full
            items-center
            px-4;
        }

        &-text {
            @apply border-l
            border-neutral-30
            grow;

            p {
                @apply text-2xl
                text-neutral-70;
            }
        }
    }
}
