.tooltip {
    @apply p-[12px]
        shadow-sm
        text-sm
        rounded-md
        relative
        break-words;

    &[data-placement^="bottom"] {
        // if trigger is scrolled to top of screen, tooltip drops below trigger
        .tippy-svg-arrow > svg {
            bottom: unset;
            bottom: initial;
            top: -6px;
        }
    }

    &.-primary {
        @apply bg-neutral-90;

        .tippy-content {
            div {
                @apply text-white;
            }
        }

        .tippy-svg-arrow {
            > svg {
                @apply fill-neutral-90
                    relative;
            }
        }
    }

    &.-secondary {
        @apply bg-neutral-05;

        .tippy-content {
            div {
                @apply text-neutral-90;
            }
        }

        .tippy-svg-arrow {
            > svg {
                @apply fill-neutral-05
                    relative;
            }
        }
    }
}
