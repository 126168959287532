.product-list {
    .search-text-input {
        @apply w-1/2;
    }

    /* tables */
    .data-table.product-list {
        td.id,
        td.type,
        td.version,
        td.last-change,
        td.changed-by,
        td.status {
            width: 1%;
        }
        .type,
        .last-change,
        .changed-by {
            @apply whitespace-nowrap;
        }
        .changed-by {
            .user-name,
            .user-id {
                @apply block;
            }
            .user-id {
                @apply font-bold
                    text-xxs
                    text-neutral-70
                    tracking-widest;
            }
        }
    }
}
