.non-admin-manage-event-layout-sidebar {
    &__details {
        @apply flex
            flex-col
            gap-4
            p-6
            border-b
            border-b-neutral-70;
        
        &__header {
            .donut {
                @apply justify-center;
            }
        }

        &__info-tags {
            @apply flex
                flex-col
                gap-4
                items-center
                w-full;
        }
        
        &__info {
            @apply text-center;
            
            &__training-type {
                @apply mb-4;
        
                p {
                    @apply text-neutral-50
                        uppercase;
        
                    svg {
                        @apply inline
                            fill-neutral-50
                            mr-2;
                    }
                }
            }

            .-expiration {
                @apply absolute
                    left-6
                    top-6
                    text-neutral-50;
    
                @screen -lg {
                    @apply relative
                        left-auto
                        top-auto;
                }
            }
        }

        &__tags {
            @apply flex
                flex-wrap
                gap-2
                justify-center
                items-center
                text-center;
        }

        @screen -lg {
            @apply flex-row
                items-center
                pt-6;

            &__info-tags {
                @apply flex-row;
            }

            &__info {
                @apply grow
                    text-left;

                &__training-type,
                .-expiration {
                    @apply mb-2;
                }
            }

            &__tags {
                @apply items-start;
            }
        }

        @screen -md {            
            &__info-tags {
                @apply flex-col
                    items-start;
            }
        }

        @screen -sm {
            @apply flex-col;

            &__info {
                @apply text-center
                    w-full;
            }

            &__tags {
                @apply w-full;
            }
        }
    }

    &__nav {
        @apply flex
            flex-col
            p-6;

        > * {
            @apply flex
                gap-2
                items-center
                transition-colors
                w-full
                p-2
                pr-4
                text-lg
                text-white
                no-underline
                border
                rounded-md;

            border-color: transparent;

            &.-active {
                @apply border-accents-blue-base
                    text-accents-blue-base;
            }

            &.-disabled {
                @apply text-neutral-70;
                svg {
                    @apply fill-neutral-70;
                }
            }

            &.-completed {
                svg {
                    @apply fill-white;
                }
            }

            .text {
                @apply pl-1;
            }

            svg {
                @apply fill-neutral-50;
            }
        }

        @screen -lg {
            @apply flex-row
                overflow-x-auto
                px-6
                py-4;

            > * {
                @apply w-auto;
            }
        }

        @screen -sm {
            .-active {
                @apply order-first;
            }
        }
    }
}
