.form-radio-card-list-with-description {
    &.disabled {
        .form-radio-card-list-with-description__cards {
            .radio-card {
                &:hover {
                    @apply cursor-not-allowed;
                }

                input {
                    &:checked + .radio-card__details {
                        @apply relative
                            bg-white;

                        svg path {
                            @apply fill-accents-blue-base;
                        }

                        h1 {
                            @apply text-neutral-90;
                        }
                    }

                    &:checked + .radio-card__details::before {
                        @apply absolute
                            -top-px
                            -right-px
                            -bottom-px
                            -left-px
                            border-2
                            border-accents-blue-base
                            content-['']
                            rounded-md;
                    }
                }

                &__details {
                    @apply bg-neutral-05
                        text-neutral-70;

                    svg path {
                        @apply fill-neutral-70;
                    }
                }

                &__text {
                    h1 {
                        @apply text-neutral-70;
                    }
                }
            }
        }
    }

    &.horizontal {
        .form-radio-card-list__cards {
            .radio-card {
                &__details {
                    @apply grid
                        grid-cols-2;

                    svg {
                        @apply inline-block
                            mx-0
                            my-0;
                    }
                }
            }
        }
    }

    &__cards {
        @apply grid
            gap-3
            items-stretch
            mt-2;

        &.two-columns {
            @apply grid-cols-2;
        }

        &.three-columns {
            @apply grid-cols-3;
        }

        &.two-rows {
            @apply grid-rows-2;
        }

        &.three-rows {
            @apply grid-rows-3;
        }

        .radio-card {
            @apply h-full;

            &:hover {
                @apply cursor-pointer;
            }

            input {
                @apply hidden;

                &:checked + .radio-card__details {
                    @apply relative;
                }
                &:checked + .radio-card__details::before {
                    @apply absolute
                        -top-px
                        -right-px
                        -bottom-px
                        -left-px
                        border-2
                        border-accents-blue-base
                        content-['']
                        rounded-md;
                }
            }

            &__details {
                @apply flex
                    gap-4
                    transition-all
                    h-full
                    items-center
                    p-4
                    rounded-md
                    border
                    border-neutral-30
                    bg-white
                    text-center
                    text-neutral-90
                    text-lg;

                svg {
                    @apply mx-auto
                        fill-accents-blue-base;
                }
            }

            &__icon {
                @apply self-center;
            }

            &__text {
                h1 {
                    @apply font-bold
                        text-base
                        text-left
                        text-neutral-90;
                }

                p {
                    @apply mt-0
                        text-left
                        text-neutral-90
                        text-sm;
                }
            }
        }
    }
}
