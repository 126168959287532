.assignment-card {
    @apply aspect-[4/3]
            align-middle
            items-center
            relative
            text-neutral-90;

    a {
        @apply absolute
                    bottom-6;
    }

    @screen -md {
        @apply aspect-auto;

        a {
            @apply bottom-auto
                    relative;
        }
    }

    &__content {
        h3,
        p.-label {
            @apply mb-2;
        }

        h3 {
            @apply line-clamp-2;
        }
    }

    &__status {
        @apply flex
            gap-2
            justify-between
            mb-4;

        .donut {
            @apply items-start;
        }

        @screen -sm {
            @apply flex-col;
        }
    }
}
