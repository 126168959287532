.checkbox {
    @apply block
        relative
        pl-7
        my-1
        text-neutral-90
        text-left
        select-none
        cursor-pointer;

    &.-small {
        @apply text-xs;
    }

    &.-medium {
        @apply text-sm;
    }

    &.-disabled {
        @apply cursor-not-allowed;

        .checkbox__checkmark {
            @apply bg-neutral-05
                border-neutral-70;

            &:after {
                @apply text-neutral-90;
            }
        }
    }

    &:hover,
    &:focus {
        &.-disabled {
            .checkbox__checkmark {
                @apply shadow-none;
            }
        }

        .checkbox__checkmark {
            @apply border-accents-blue-dark
            shadow-accents-blue-base/50
            shadow-focus-visible;
        }
    }

    &.-checked {
        @apply text-accents-blue-dark;

        .checkbox__checkmark {
            @apply border-accents-blue-dark;

            &:after {
                @apply block
                    text-accents-blue-dark;
            }
        }

        &.-disabled {
            .checkbox__checkmark {
                @apply border-neutral-70;

                &:after {
                    @apply block
                        text-neutral-70
                        border-neutral-90;
                }
            }

            @apply text-neutral-70;
        }
    }

    &__checkmark {
        @apply absolute
            top-0
            left-0
            h-5
            w-5
            border
            border-solid
            rounded
            outline-none;

        &:after {
            @apply absolute
            hidden;
            content: "";
        }

        /* This creates the checkbox with a border. */
        &:after {
            @apply left-[6px]
                top-[3px]
                w-[5px]
                h-[10px]
                border-solid
                border-accents-blue-base
                border-t-0
                border-r-2
                border-b-2
                border-l-0
                rotate-45;
        }

        & {
            @apply border-neutral-70
                text-neutral-90
                disabled:bg-neutral-05
                disabled:border-neutral-50
                disabled:text-neutral-70
                focus-visible:border-accents-blue-dark
                focus-visible:outline-none
                focus-visible:shadow-accents-blue-base/50
                focus-visible:shadow-focus-visible;
        }
    }
}
