.product-achievements {
    @apply max-w-[1152px]
        mx-auto;

    &__header {
        @apply mb-8;
    }

    &__banner {
        @apply mb-8;
    }

    &__content {
        &__section {
            @apply border-t
                border-neutral-30
                mt-8
                pt-8;

            &:first-child {
                @apply border-none
                    mt-0
                    pt-0;
            }

            .columns {
                @apply grid
                    grid-cols-2
                    gap-8;
            }

            h2 {
                @apply mb-4;
            }

            p {
                @apply mb-4;
            }

            p.intro {
                @apply mb-4
                    text-neutral-70
                    text-lg;
            }

            .hint-text {
                @apply italic
                    leading-3
                    mt-2
                    text-xs
                    text-neutral-70;
            }

            .radio-buttons {
                @apply flex
                    gap-12;
            }

            .certificate-duration {
                @apply mt-5;
            }

            .hot-work-label {
                @apply mt-5;
            }
        }
    }

    h5 {
        @apply font-light;
    }
}
