.learner-training-layout {
    @apply h-full
        w-full;

    &__body {
        @apply h-full;
    }

    &__window {
        @apply flex
            flex-row
            grow
            h-full
            w-full;

        &__sidebar {
            @apply bg-neutral-90
                self-stretch
                flex-col
                h-full
                w-1/4;
        }

        &__main {
            @apply flex-col
                overflow-y-scroll
                relative
                h-full
                w-3/4;

            & > #chakra-skip-nav {
                @apply h-full;
            }

            @screen -lg {
                @apply overflow-visible;
            }
        }
    }

    @screen -lg {
        &__body {
            @apply h-auto
                overflow-visible;
        }
        
        &__window {
            @apply flex-col;

            &__sidebar {
                @apply h-auto
                    w-auto;
            }

            &__main {
                @apply w-auto;
            }
        }
    }

    @screen xl {
        &__window {
            &__sidebar {
                @apply w-[320px]/* ... */;
            }

            &__main {
                width: calc(100% - 320px);
            }
        }
    }
}
