.user-contract-oll-detail-enrollment {
    &__header {
        @apply flex
            items-center
            justify-between;

        &__title {
            @apply flex
                items-center
                space-x-2;
        }
    }

    &__table {
        @apply mt-6
            pb-0;
    }
}
