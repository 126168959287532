.form-search-select {
    @apply mt-5;

    &__input-container {
        @apply relative;

        &-input {
            @apply h-10
                rounded-md
                text-neutral-90
                w-full;
        }

        .-button-icon {
            @apply absolute
                flex
                h-12
                inset-y-0
                items-center
                px-2
                right-0
                rounded-r-md;
        }
    }
    &__label {
        @apply font-medium
            text-neutral-90
            text-sm;
    }

    &__dropdown {
        @apply absolute
            border
            border-neutral-90
            max-h-28
            overflow-y-auto
            py-1
            text-sm
            w-full
            bg-white
            mb-10
            z-50;

        &-option {
            @apply min-h-[1.75rem]
                py-1;

            &[data-headlessui-state="active"] {
                @apply bg-neutral-05
                    font-bold;
            }

            &[data-headlessui-state="selected"] {
                @apply font-bold;
            }

            &[aria-selected="true"] {
                @apply font-bold;
            }
        }

        &-icon {
            @apply col-span-1
                justify-self-end;
        }

        &-id {
            @apply col-span-1 ml-2
                text-neutral-70;
        }

        &-item {
            @apply grid
                grid-cols-5;
        }

        &-name {
            @apply col-span-3
                ml-2
                overflow-hidden
                text-ellipsis
                text-neutral-90
                whitespace-nowrap;
        }
    }

    input[type="text"]:disabled {
        @apply bg-neutral-05
            cursor-not-allowed;
    }
}
