.link-trial-card {
    padding: 0;
    &__modal {
        .modal__panel {
            background: var(--Neutral-Neutral-90, #001a39);
            width: 336px;
            height: 191px;
            flex-shrink: 0;
            padding: 0;
        }
        .modal__footer {
            border-top: 0px;
            justify-content: center;
        }
        .paragraph {
            color: var(--Neutral-White, #fff);
            text-align: center;

            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.32px;
        }
        .modal__body__content-wrap {
            padding: 1.5rem;
            padding-right: 2rem;
            padding-left: 2rem;
            overflow: hidden;
        }
    }
    &__token {
        @apply cursor-pointer
            font-bold
            border
            border-dashed
            border-accents-blue-dark
            rounded;

        svg {
            @apply fill-accents-blue-dark
                mt-1
                mr-1;
        }
    }
    &__content {
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem;
        display: flex;
        flex-shrink: 0;
        &__image {
            width: 129px;
            height: 46.209px;
        }
        &__text {
            .heading {
                color: var(--Neutral-Neutral-90, #001a39);
                font-family: Roboto;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.48px;
            }
            .paragraph {
                color: var(--Neutral-Neutral-70, #646a82);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0.28px;
            }
            padding-left: 20px;
            flex-direction: row;
        }

        &__striped__border {
            height: 16px;
            width: 100%;
            background-image: url(/assets/images/stripe-texture.png);
            background-repeat: repeat-x;
        }
    }
}
