.numbered-tab {
    &__badge {
        @apply -mt-[0.15rem]
            bg-accents-blue-dark
            h-6
            justify-center
            pb-[0.15125rem]
            pl-[0.336rem]
            pr-[0.336rem]
            pt-[0.15125rem]
            text-[0.583125rem]
            w-6;
    }

    &__text {
        @apply mr-1.5;
    }
}
