.banner {
    @apply bg-neutral-90
        h-max
        px-3
        py-2
        rounded
        text-white
        text-xs
        w-max
        inline-block;

    svg {
        @apply mr-2
                ml-0;
    }

    &.-error {
        @apply bg-status-error-light
            fill-status-error-dark
            text-status-error-dark;
        * {
            @apply text-status-error-dark;
        }
    }

    &.-dark {
        @apply bg-neutral-70
                fill-white
                text-white;
        * {
            @apply text-white;
        }
    }

    &.-light {
        @apply bg-neutral-05
            fill-neutral-05
            py-2.5
            text-neutral-70
            text-sm
            w-full;
    }

    &.-light-inverted {
        @apply bg-neutral-05
            fill-neutral-70
            text-neutral-70;
        * {
            @apply text-neutral-70;
        }
    }

    &.-neutral {
        @apply bg-accents-blue-light
            fill-accents-blue-dark
            text-accents-blue-dark;
        * {
            @apply text-accents-blue-dark;
        }
    }

    &.-success {
        @apply bg-status-success-light
                fill-status-success-dark
                text-status-success-dark;
        * {
            @apply text-status-success-dark;
        }
    }

    &.-success-inverted {
        @apply bg-status-success-dark
        fill-status-success-light
        text-status-success-light;
        * {
            @apply text-status-success-light;
        }
    }

    &.-warning {
        @apply bg-status-warning-light
            fill-status-warning-dark
            text-status-warning-dark;
        * {
            @apply text-status-warning-dark;
        }
    }

    &.-center {
        @apply flex
            justify-center;
    }

    &.-stacked {
        @apply mb-2;
    }

    &.-standalone {
        @apply my-10;
    }

    &.-box {
        @apply rounded-none;
    }

    &.-standalone,
    &.-stacked {
        @apply rounded-[0.3125rem]
            py-2.5
            items-start;
    }

    &.-standalone,
    &.-stacked,
    &.-box {
        @apply flex
            gap-2
            justify-start
            text-sm
            w-full;

        svg {
            @apply mx-0;
        }

        .content {
            @apply flex
                grow;

            .text {
                @apply grow;
            }

            .view {
                @apply font-medium
                    text-left;

                button {
                    @apply underline;
                }
            }
        }

        @screen -md {
            .content {
                @apply flex-col;
            }
        }
    }

    &.-fixed {
        @apply absolute
            block
            h-[40px]
            items-center
            p-0
            top-0
            z-40;
    }

    .content-wrap {
        @apply flex
            h-full
            items-center
            max-w-[1152px]
            mx-auto
            px-8;
    }
}
