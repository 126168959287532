.modal {
    @apply z-[100]
        fixed;

    &__backdrop:last-of-type {
        @apply fixed
            inline-block
            inset-0
            overflow-y-auto;

        [data-headlessui-portal]:last-of-type & {
            @apply bg-neutral-90/30;
        }
    }

    &__body {
        @apply overflow-y-auto
            grow;

        &-inverted {
            @apply leading-6
                px-7
                pt-7
                pb-4
                tracking-[.02em]
                text-base
                text-center
                text-white;
        }

        &__content-wrap {
            @apply p-8;
        }
    }

    &__container {
        @apply flex
            justify-center
            h-full
            sm:items-center;
    }

    &__footer {
        @apply flex
            border-t
            border-t-neutral-30
            h-[70px]
            items-center
            justify-end
            px-4
            shrink-0;
        
        &-inverted {
            @apply pb-6
                pr-6
                px-4
                text-center;
        }
    }

    &__header {
        @apply h-[70px]
            relative
            bg-neutral-05
            shrink-0;

        &__actions {
            @apply absolute
                right-3.5
                top-4;
        }

        &-close {
            @apply absolute
                pr-6
                pt-6
                right-0
                block
                top-0;

            &-icon {
                @apply text-neutral-70;
            }
        }

        @screen sm {
            @apply rounded-tl-lg
                rounded-tr-lg;
        }
    }

    &__panel {
        @apply flex
            flex-col
            bg-white
            shadow-xl
            text-left
            transform
            w-full;

        &-inverted {
            @apply bg-neutral-90
                h-auto
                min-h-[10rem]
                overflow-hidden
                rounded-[9px]
                shadow-xl
                text-left
                transform
                w-72;
        }

        @screen sm {
            @apply w-[540px]
            rounded-lg
            mt-24
            max-h-[600px]
            mb-4;
        }
    }

    &__title {
        @apply font-normal
            pl-6
            pt-6;
    }

    .transition-child {
        @apply fixed;
    }
}
