.header-banner {
    @apply w-full
        sm:flex
        sm:items-stretch
        sm:justify-between
        py-10
        px-8
        bg-neutral-90;

    &__info {
        &__nav {
            @apply lg:flex
                lg:items-center
                lg:space-x-12;

            h1 {
                @apply text-white;
            }
        }

        &__badges {
            @apply mt-4
                -ml-3;

            > * {
                @apply inline-block
                    ml-3
                    mt-2
                    border-neutral-30
                    text-neutral-30;
            }
        }
    }

    &__nav__items {
        a {
            @apply inline-block
                px-4
                py-2
                ml-[1px]
                border
                border-white
                text-white
                text-sm
                tracking-wide
                font-bold
                no-underline;

            &.active {
                @apply bg-white
                    text-neutral-90;
            }

            &:first-child {
                @apply ml-0
                    rounded-l-md;
            }

            &:last-child {
                @apply rounded-r-md;
            }
        }
    }

    &__actions {
        gap: 1rem;
        @apply flex
            items-start
            mt-1;
    }

    &__view__items {
        @apply w-40;
    }

    &__view__items__select {
        select {
            @apply border-white
                text-white
                w-40;

            & > option:not(:disabled) {
                @apply bg-neutral-90
                text-white;
            }
        }
        .select-wrapper {
            background-image: url(/assets/icons/16px/Chevron-Down-White.svg);
            background-color: #001a39;
        }
    }
}
