.product-evaluation {
    @apply max-w-[1152px]
        mx-auto;

    &__banner {
        @apply mb-8;
    }

    &__header {
        @apply mb-8;
    }

    h5 {
        @apply font-light;
    }
}
