.event-schedule-exception-form {
    @apply grid
        grid-cols-1
        gap-2;

    &__exception-description {
        @apply text-base
            text-neutral-90;
    }
}
