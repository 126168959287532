.admin-layout-header-navigation-panel-content {
    @apply bg-neutral-90
        flex
        flex-col
        h-full
        justify-between
        overflow-y-auto;

    &__nav-item {
        @apply block
            fill-neutral-50
            mb-8
            no-underline
            text-start
            text-white
            w-full
            hover:fill-accents-blue-base
            hover:text-accents-blue-base;

        margin-left: 0 !important;

        & > span {
            @apply m-0
                p-0;
        }

        & > svg {
            @apply m-0
                mr-3
                p-0
                relative
                top-0.5;
        }
    }

    &__nav-items {
        @apply flex
            flex-col
            justify-start
            p-8;
    }
}

html[dir=rtl] .admin-layout-header-navigation-panel-content {
    &__nav-item {
        & > svg {
            @apply mr-0
                ml-3;
        }
    }
}
