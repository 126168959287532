.progress-bar {
    @apply bg-neutral-30
        border
        border-neutral-50
        h-6
        rounded-sm
        w-full;

    &.-thick {
        @apply h-6;

        .progress-bar__bar {
            @apply h-[1.438rem]
                /* Comment to prevent a trailing ] causing a resolve-url-loader issue. */;
        }
    }

    &.-error {
        .progress-bar__bar {
            @apply bg-accents-red-base;
        }
    }

    &.-info {
        .progress-bar__bar {
            @apply bg-accents-yellow-base;
        }
    }

    &__bar {
        @apply h-4
            rounded-tl-sm
            rounded-bl-sm
            bg-accents-red-base;

        /**
         * generate classes -w-0, -w-1, -w-2, etc. up to -w-100
         * to set the width to the progress percentage
         */
        @for $i from 0 through 100 {
            &.-w-#{$i} {
                width: #{$i}#{unquote("%")};

                @if ($i >= 100) {
                    @apply rounded-tr-sm
                        rounded-br-sm;
                }
            }
        }
    }
}
