.event-session-form {
    @apply flex
        gap-5
        mb-4;

    & > div {
        @apply inline-block;
    }

    &__session-start-time,
    &__session-end-time {
        @apply w-full
        inline-block;
    }

    &__session-credit-hours {
        @apply font-semibold
            text-neutral-70
            text-sm
            mt-9
            whitespace-nowrap;
    }

    &__delete-session {
        @apply mt-7
            text-right;

        svg {
            @apply fill-neutral-70;
        }
    }

    .time-picker__text {
        @apply w-full;
    }

    input {
        @apply h-10;
    }
}
