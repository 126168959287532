.learner-assessment-card {
    @apply flex
        justify-center
        align-middle
        items-center
        h-64
        text-center
        relative;

    &__content {
        & > h2 {
            @apply font-light
                leading-8
                text-2xl
                text-center
                text-neutral-90
                max-h-24
                max-w-[34rem]
                mx-auto
                overflow-hidden
                break-words
                mb-4;
        }

        button {
            @apply block
                mx-auto
                mb-1;

            + p {
                @apply mt-2;
            }
        }
    }

    &__assessment-buttons {
        @apply flex;
    }

    &__status {
        @apply w-full
            absolute
            top-3
            left-0;

        &-badge {
            @apply absolute
                left-4;
        }

        > .badge {
            @apply absolute
                right-4;
        }
    }

    &__attempts-used-message {
        @apply font-bold
            text-neutral-70
            text-xs
            tracking-widest
            inline-block;
    }
}
