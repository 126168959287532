.profile-account-page {
    &__details {
        @apply grid
            grid-cols-1
            gap-6
            md:grid-cols-2;

        > .card {
            h2 {
                @apply font-semibold
                    text-lg
                    text-neutral-90;
            }
        }
    }

    &__info {
        @apply grid
            grid-cols-1
            lg:grid-cols-2;

        // > * {
        //     &:last-child {
        //         @apply text-left
        //             lg:text-right;
        //     }
        // }

        &__footer {
            @apply mt-4
                col-span-2;

            p {
                @apply text-left
            }
        }

        h2 {
            @apply mb-4;
        }

        p + p.-label {
            @apply mt-4;
        }

        a {
            @apply
                transition-all
                text-sm
                text-accents-blue-dark
                underline
                hover:opacity-80;
        }
    }

    &__language {
        h2 {
            @apply mb-0;
        }

        .form-field {
            @apply mt-4;
        }
    }

    &__topics {
        h2 {
            @apply mb-0;
        }

        .tag-list {
            @apply mt-6;
        }
    }

    &__header {
        @apply w-full
            md:flex
            md:items-center
            md:justify-between
            mb-8;
    }
}
