.import-enrollment-modal {
    &__section {
        + .import-enrollment-modal__section {
            @apply mt-8;
        }

        > .form-field > label {
            @apply font-bold;
        }

        > .form-field + .form-field {
            @apply mt-4;
        }

        a {
            @apply underline;
        }
    }

    &__summary {
        @apply pb-4;

        p + p {
            @apply mt-14;
        }

        &__grid {
            @apply mt-2
                w-full;

            &__row {
                @apply grid
                    grid-cols-2
                    gap-6
                    py-5;

                box-shadow: 0px 1px 0px rgba(141, 152, 165, 0.5);

                > * {
                    @apply text-xs;

                    &:first-child {
                        @apply font-bold;
                    }

                    &:last-child {
                        @apply text-center;
                    }
                }
            }
        }

        &__download {
            @apply block
                mt-8
                text-accents-blue-dark;
        }
    }

    ul {
        @apply list-disc
            mt-3
            pl-2
            ml-8
            text-sm;
    }
}
