.aen-product-info {
    &__back-to-nav {
        @apply mb-8;
    }

    &__header {
        @apply font-light
            mb-6;
    }

    &__detail-items {
        @apply flex
            gap-4
            mb-6;

        dt,
        dd {
            @apply text-neutral-90
                text-sm;
        }
        dt {
            @apply font-bold
                capitalize
                tracking-widest;
        }
    }

    &__create-event-button {
        @apply mb-6;
    }

    &__description,
    &__course-objectives,
    &__who-will-benefit {
        @apply mb-8;
    }

    &__course-objectives,
    &__who-will-benefit {
        /* Apply styling for RTE lists. */
        ul > li {
            @apply list-disc
                pl-2
                ml-4;
        }

        ol > li {
            @apply list-decimal
                pl-2
                ml-4;
        }
    }

    &__image {
        @apply float-right
            h-80
            mb-10
            ml-12
            rounded-md
            w-60;
    }
}
