.user-info-stack {
    &__account-name {
        @apply text-base
            text-neutral-90;
        .badge {
            @apply mb-1;
        }
    }

    &__account-email,
    &__account-id {
        @apply text-neutral-70
            font-bold
            uppercase;
    }
}
