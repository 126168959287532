.event-activation-provider-section {
    @apply mt-8;

    h3 {
        @apply font-normal
            text-neutral-90
            text-lg
            mb-1;
    }
}
