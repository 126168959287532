.event-assessment-results-header {
    a {
        @apply block
            mb-4;
    }

    &__title {
        @apply flex
            items-center
            my-10;

        h2 {
            @apply font-light
                leading-10
                mb-0
                text-3xl;
        }

        .badge {
            @apply ml-6;
        }
    }
}
