.event-registration-form {
    @apply grid
        grid-cols-2
        gap-8;

    &__column {
        @apply grid
            grid-cols-1
            gap-8;

        & > * {
            @apply h-16;
        }
    }

    textarea {
        height: 8.25rem;
    }
}
