.event-provider-selection-list {
    .provider-name {
        @apply pl-0;
    }

    th,
    td {
        &.action {
            @apply text-right
                w-28;
        }
    }

    td.id {
        width: 1%;
    }
}
