.form-field-readonly {
    > p:first-child {
        @apply mb-1;
    }

    .badge-list {
        @apply -mt-2
            -ml-2;

        .badge {
            @apply inline-block
                mt-2
                ml-2;
        }
    }

    + .form-field-readonly {
        @apply mt-8;
    }

    + .button {
        @apply mt-4;
    }
}
