.course-content-change-history {
    h2 {
        @apply mb-8;
    }
    
    &__changed-by-id {
        @apply font-bold;
    }

    .data-table {
        td {
            @apply align-top;
        }

        .date,
        .changed-by {
            width: 1%;
            @apply whitespace-nowrap;
        }

        .change-log-new {
            @apply italic
                text-neutral-70;
        }

        .created-log {
            @apply italic
                text-neutral-70;
        }
    }
}
