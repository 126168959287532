.admin-layout-header-navigation-panel-footer {
    @apply border-t
        border-t-neutral-70
        flex
        flex-col
        gap-4
        h-[7.5rem]
        justify-between
        p-8
        w-full;

    &__action {
        @apply block
            fill-neutral-50
            font-bold
            no-underline
            text-start
            text-neutral-50
            text-xs
            tracking-[0.25rem]
            w-full
            hover:fill-accents-blue-base
            hover:text-accents-blue-base;

        margin-left: 0 !important;

        & > span {
            @apply m-0
                p-0
                uppercase;
        }

        & > svg {
            @apply m-0
                mr-3
                p-0;
        }
    }
}

html[dir=rtl] .admin-layout-header-navigation-panel-footer {
    &__action {
        & > svg {
            @apply mr-0
                ml-3;
        }
    }
}
