.event-day-attendance-header {
    @apply grid
        gap-4
        mt-8
        mb-8;

    a {
        @apply block
            mb-4;
    }

    &__actions {
        @apply flex
            flex-wrap
            gap-4
            items-baseline;

        .button + .button {
            @apply ml-0;
        }
    }

    @screen md {
        @apply grid-cols-[1fr_min-content]/* comment to keep things from breaking */;

        &__actions {
            @apply flex-nowrap;
        }
    }
}
