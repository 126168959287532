.groups-new-page {
    @apply max-w-[1152px]
        mx-auto
        p-8
        pb-24
        relative;

    h1 {
        @apply mb-6;
    }

    &__roles {
        @apply my-6;

        .tag-list {
            @apply mt-4;
        }
    }

    &__section {
        h2 {
            @apply mb-3;

            + h3 {
                @apply mt-5;
            }
        }

        h3 + .form-field {
            @apply mt-2;
        }

        .form-field + h3 {
            @apply mt-0.5
                italic;
        }

        h3 + .toggle-label {
            @apply mt-6;
        }

        .groups-new-page__section__grid + .groups-new-page__section__grid,
        h3 + .groups-new-page__section__grid {
            @apply mt-4;
        }

        .groups-new-page__section__grid + h3 {
            @apply mt-6;
        }

        &__grid {
            @apply grid
                grid-cols-1
w-1/3
                gap-6;
        }

        &__details {
            @apply w-2/3;
        }

        &__heading {
            @apply flex
                justify-between
                mt-12;
        }
    }

    &__actions {
        @apply fixed
            flex
            flex-1
            justify-end
            bottom-0
            left-0
            w-full
            py-2
            px-6
            border-t
            border-t-neutral-50
            bg-white;
    }
}
