.c-seeded-user {
    &__header {
        @apply mb-5
            text-5xl;
    }

    &__login-buttons {
        @apply flex
            flex-row
            flex-wrap
            gap-4;

        .button {
            & + .button {
                @apply m-0;
            }
        }
    }
}
