.learner-checklist-item {
    @apply flex
        leading-7;
    
    &__icon {
        @apply w-5
            h-5
            shrink-0;
        margin: 3px 7px 0 0;
    }
}
