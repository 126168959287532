.announcement-modal-form {
    &__container {
        @apply border
            border-neutral-50
            rounded-lg;

        &__body {
            @apply p-3;
        }
    }
}
