.instructor-card {
    &__heading {
        @apply mb-4;
    }

    &__section {
        @apply flex
            gap-2
            items-center
            justify-start
            mb-4;

        &__icon {
            @apply h-[50px]
                w-[50px]
                rounded-full
                shrink-0;
        }

        &__info {
            p {
                @apply uppercase;

                &.email {
                    @apply break-all;
                }
            }
        }
    }
}
