.update-progress-modal {
    .form-field,
    .form-field-readonly {
        + .form-field {
            @apply mt-6;
        }
    }

    p + .form-field {
        @apply mt-4;
    }

    // TODO: NFPA-8278 Remove or Implement
    // &__wrapper {
    //     @apply max-h-
    //         overflow-y-auto
    //         px-2;
    // }

    .calendar-date-picker {
        @apply w-full;
    }
}
