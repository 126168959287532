.c-emulation-mode-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 8px 0;
    border: 1px solid red;
    background-color: rgb(251, 243, 219);
    max-height: 40px;
    position: fixed;
    height: 40px;
    width: 100%;
    z-index: 101;

    &__user {
        padding-left: 20px;
        color: var(--status-warning-dark, #8c3b17);
        font-weight: 400;

        &__email {
            font-weight: 500;
        }
    }

    &__end {
        border-radius: 5px;
        border: 1px solid var(--neutral-white, #ff0000);
        margin-right: 20px;

        &.button.-small {
            padding: 4px 16px;
        }
    }

    &__expires {
        display: flex;
        color: var(--status-warning-dark, #8c3b17);
        font-weight: 400;
        &__text {
            padding-right: 4px;
        }
        &__time {
            margin-right: 1rem;
        }
    }

    @screen -xs {
        justify-content: space-around;
        font-size: x-small;
        gap: 10px;
        margin-top: 10px;
    }
}
