.action-link-card {
    > p {
        @apply mt-4;
    }

    > .button {
        @apply mt-8;
    }

    &__header {
        @apply flex
            items-center
            justify-between

            -sm:flex-col
            -sm:gap-4
            -sm:items-start;
    }

    &__topics {
        @apply flex
            flex-wrap
            gap-x-2
            mt-2;
    }
}
