.user-training-detail-oll-progress {
    &__section {
        @apply mb-8;
    }

    &__assessments {
        .unit {
            @apply mb-12;
        }

        .assessment {
            @apply w-full
                flex
                items-center
                justify-between
                mt-3;

            &__details {
                @apply flex
                    gap-8;

                > * {
                    > * {
                        @apply inline-block;
                    }
                }

                > :nth-child(4) {
                    @apply w-12;
                    
                    p {
                        @apply text-neutral-70
                            font-bold;

                        svg {
                            @apply fill-neutral-70
                                mr-1;

                            + * {
                                @apply relative;
                                top: 1px;
                            }
                        }
                    }
                }

                > :last-child {
                    @apply relative
                        text-right;

                    .context-menu {
                        @apply text-left;
                    }
                }
            }
        }
    }
}
