.product-activation-modal {
    &__content {
        @apply overflow-y-auto;

        p.-xlarge {
            font-size: 26px;
            line-height: 36px;
            max-width: 85%;
        }
    }

    .modal__panel {
        @screen sm {
            width: 885px;
        }
    }
}
