.instructor-enh-required-trainings-page {
    @apply max-w-[1152px]
        mx-auto
        pb-6;

    &__section {
        @apply mb-8;

        > p {
            @apply w-3/5;
        }
    }
}
