.admin-reports-setup-page {
    @apply flex
        flex-col
        h-full;

    .side-content-left-layout {
        @apply overflow-hidden
            -lg:overflow-visible;

        & > *:last-child {
            @apply overflow-hidden
                -lg:overflow-visible;
        }
    }

    .form-field {
        .form-radio-card-list__cards {
            @apply mt-4;
        }
    }

    .report-section {
        @apply w-full
            mb-8;

        &.half {
            @apply w-1/2
                -md:w-full;
        }

        > p + .form-field {
            @apply mt-2;
        }

        &__header {
            @apply flex
                justify-between
                items-center
                mb-4;
        }
    }

    .footer {
        @apply flex
            h-20
            items-center
            justify-end
            px-8;
    }
}
