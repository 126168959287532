.public-layout-header {
    @apply border-b
        border-neutral-30
        flex
        h-20
        items-center;
    
    &__logo {
        @apply flex
            items-center
            h-[80px]
            w-[80px]
            justify-center;

        img {
            @apply w-[42px]
                min-w-[36px]
                m-0;
        }
    }

    &__text,
    &__actions {
        @apply flex
            h-full
            items-center
            px-4;
    }

    &__text {
        @apply border-l
            border-neutral-30
            grow;

        p {
            @apply text-2xl
                text-neutral-70;
        }
    }

    @screen -md {
        &__text {
            p {
                @apply hidden;
            }
        }
    }
}
