.manage-live-virtual-event-content-page {
    
    @apply max-w-[1152px]
        mx-auto
        p-8;
    
    &__title {
        @apply flex
            fill-accents-blue-base
            items-center
            mb-8;
        
        h2 {
            @apply mb-0;
        }

        svg {
            @apply mr-1;
        }
    }

    &__content-section {
        @apply gap-8
            grid
            grid-cols-1;
    }

    &__content-section-non-aen {
        @apply gap-8
            grid
            grid-cols-1;
    }

    &__content-item {
        float: left;
    }

    @screen md {
        &__content-section {
            @apply grid-cols-2;
        }
    }
}
