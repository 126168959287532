.event-activation-location-section {
    @apply mt-8;

    h3 {
        @apply font-normal
            text-neutral-90
            text-lg
            mb-2;
    }

    p.-label + p {
        @apply mt-2;
    }

    &__container {
        @apply grid
            grid-cols-2;
    }

    &__container + &__container {
        @apply my-5;
    }

    .banner {
        @apply justify-start;
    }
}
