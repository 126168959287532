.tag {
    @apply h-9
        cursor-pointer
        relative;

    .icon {
        @apply absolute
            left-3
            top-[5px]
            fill-accents-blue-dark;
    }

    input {
        @apply h-0
            w-0
            absolute
            invisible;

        &:checked + label {
            @apply bg-accents-blue-dark
                text-white;
        }

        &[disabled] + label {
            @apply border-neutral-30
                cursor-not-allowed
                text-neutral-30;
        }

        &:checked[disabled] + label {
            @apply bg-neutral-30
                text-white;
        }
    }

    label {
        @apply transition-all
            pl-9
            pr-6
            py-2
            rounded-full
            border
            border-accents-blue-dark
            bg-white
            text-sm
            text-accents-blue-dark
            cursor-pointer
            hover:bg-accents-blue-light;
    }

    &:has(input[disabled]) {
        @apply cursor-not-allowed;

        .icon {
            @apply fill-neutral-30;
        }

        label {
            @apply hover:text-neutral-30;

            &:hover {
                background: transparent;
            }
        }
    }

    &.-selected {
        .icon {
            @apply fill-white;
        }

        label {
            @apply hover:bg-accents-blue-dark
                hover:text-white;
        }

        &:has(input[disabled]) {
            .icon {
                @apply fill-white;
            }
            
            label {
                @apply text-white
                    hover:bg-neutral-30;
            }
        }
    }

    &.-locked {
        input[disabled] + label {
            @apply bg-accents-blue-dark
                border-accents-blue-dark
                text-white;
        }

        &:has(input[disabled]) {
            .icon {
                @apply fill-white;
            }

            label {
                @apply hover:text-white
                    hover:bg-accents-blue-dark;
            }
        }
    }
}
