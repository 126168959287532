.manage-live-virtual-event-details-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__title {
        @apply flex
            fill-accents-blue-base
            items-center
            mb-8;

        h2 {
            @apply mb-0;
        }

        svg {
            @apply mr-1;
        }
    }

    &__notifications {
        @apply mb-8;
    }

    &__cancellation-banner {
        @apply mb-8;

        .banner {
            @apply pt-6
                px-6
                inline-block
                w-full;

            svg,
            h2,
            p {
                @apply mb-2;
            }
        }
    }

    &__contract {
        @apply border-b-[0.06rem]
            border-neutral-30
            mb-14
            mt-8
            pb-16;
    }

    &__heading {
        @apply mb-8;
    }

    &__details {
        @apply grid
            gap-y-8
            grid-cols-1;

        @screen md {
            @apply grid-cols-3
            gap-8;
        }
    }

    &__about {
        @apply col-span-3;
    }

    &__about.-instructor {
        @apply md:col-span-2;
    }

    &__schedule,
    &__location,
    &__contact {
        @apply col-span-3;
    }

    &__contact {
        h2 {
            @apply mb-8;
        }
    }

    &__hr {
        @apply col-span-3
            border-b-[0.84px]
            border-neutral-30
            mt-6
            mb-2;
    }
}
