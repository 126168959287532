p {
    @apply text-base
        text-neutral-90;

    &.-xxsmall {
        @apply text-xxs;
    }

    &.-xsmall {
        @apply text-xs;
    }

    &.-small {
        @apply text-sm;
    }

    &.-medium {
        @apply text-base;
    }

    &.-large {
        @apply text-lg;
    }

    &.-xlarge {
        @apply text-2xl
            font-light;
    }

    &.-faded {
        @apply text-neutral-70;
    }

    &.-inverted {
        @apply text-white;
    }

    &.-label {
        @apply text-neutral-70
            text-xs
            font-bold
            tracking-widest
            uppercase;
    }

    &.-dark-label {
        @apply text-neutral-90
            text-xs
            font-bold
            tracking-widest;
    }

    &.-light {
        @apply text-neutral-70;
    }

    &.-bold {
        @apply font-bold;
    }

    &.-semi-bold {
        @apply font-semibold;
    }

    &.-italic {
        @apply italic;
    }
}
