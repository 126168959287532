.host-card {
    &__heading {
        @apply mb-4;
    }

    &__section {
        p {
            @apply uppercase;
        }
    }
}
