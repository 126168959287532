.add-bulk-enrollment-summary {
    &__summary-description {
        @apply mb-5
            italic
            text-xs
            text-neutral-70;
    }

    &__summary-item-list {
        @apply mb-6;
    }

    &__summary-item {
        @apply border-[#8D98A580]
            border-b
            border-opacity-50
            grid grid-cols-2;
    }

    &__summary-name,
    &__summary-value {
        @apply mt-6
            text-xs;
    }

    &__summary-name {
        @apply font-bold;
    }

    &__summary-title {
        @apply mb-3;
    }

    &__summary-value {
        @apply flex
            justify-center;
    }

    &__summary-download-link {
        @apply text-accents-blue-dark;
    }
}
