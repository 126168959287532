.event-physical-content-display {
    @apply mb-11
        mt-8;

    &:last-of-type {
        @apply mb-0;
    }

    &__content-section {
        @apply gap-x-8
            gap-y-8
            grid
            grid-cols-1
            mb-4;
    }
    &__material-card {
        @apply flex
        flex-col
        items-center
        pb-0;
    }
    &__message {
        @apply font-normal
            mt-4;

        &-link {
            @apply font-normal
                text-base;
        }
    }

    &__catalog-button {
        @apply mb-8
            mt-4;
    }
    &__image {
        max-width: 400px;
        width: 100%;
    }
    &__image-wrapper {
        @apply mb-0;
    }

    &__title {
        padding-top: 21px;
        @apply text-2xl
        font-light;
    }
}
.catalog-img {
    max-width: 400px;
}
