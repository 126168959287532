.aen-resources-list-page {
    @apply px-8
        max-w-[1152px]
        mx-auto;

    &__header {
        @apply w-full
            md:flex
            md:items-center
            md:justify-between;

        > * {
            &:first-child {
                @apply w-full
                    md:w-5/12;
            }

            &:last-child {
                @apply mt-4;
            }
        }

        .search-text-input {
            @apply mb-0;
        }
    }
}
