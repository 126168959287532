.user-contracts-ill-list-page {
    @apply flex
        flex-col
        h-full;

    .side-content-left-layout {
        @apply overflow-hidden;

        & > *:last-child {
            @apply overflow-hidden;
        }
    }

    &__header {
        @apply flex
            items-center
            justify-between
            mb-8;

        h2 {
            @apply mb-0;
        }

        &__actions {
            @apply flex
                items-center
                space-x-2;

            &__search {
                @apply relative;

                svg {
                    @apply absolute
                        left-1
                        top-3
                        fill-neutral-50;
                }

                input {
                    @apply pl-8
                        w-64;
                }
            }
        }
    }

    &__table {
        .contract-number,
        .lms-id,
        .enrollment,
        .expiration-date {
            width: 1%;
            @apply whitespace-nowrap;
        }
    }
}
