.event-activation-instructor-section {
    @apply mt-8;

    h2 {
        @apply mb-2;
    }

    h3 {
        @apply font-normal
            text-neutral-90
            text-lg
            mb-2;
    }

    td.-name,
    th.-name {
        @apply w-3/6;
    }

    .banner {
        @apply justify-start;
    }
}
