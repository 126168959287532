.user-contracts-ill-detail-page {
    @apply flex
        flex-col
        h-full;

    &__header {
        @apply flex
            justify-between;

        &__details {
            .button {
                @apply mb-8;
            }
        }
    }
}
