.user-contracts-oll-detail-page {
    @apply flex
        flex-col
        h-full;

    &__header {
        .button {
            @apply mb-8;
        }

        &-buttons {
            @apply flex
                justify-between;
        }
    }

    &__information {
        @apply grid
            grid-cols-1
            md:grid-cols-2
            lg:grid-cols-3
            gap-8;
    }
}
