.evaluation-form {
    &__header {
        @apply mb-8;

        h1 {
            @apply font-light;
        }

        p {
            @apply max-w-[62%]
                text-neutral-70;
        }
    }

    .question {
        @apply w-full
            flex
            flex-col;

        &__text {
            @apply mb-4;
        }

        &__fields {
            @apply flex
                flex-col;

            &.freeform {
                @apply block;
            }

            &.multi-select {
                @apply flex-col
                    space-x-0
                    space-y-6;
            }
        }

        @screen md {
            @apply flex-row
                items-center
                space-x-8;

            &__text {
                @apply mb-0
                    w-2/5;
            }

            &__fields {
                @apply flex-row
                    gap-4
                    justify-evenly
                    w-3/5;
            }
        }

        @screen -md {
            .radio-button.-label-bottom {
                @apply max-w-none
                    text-left;

                label {
                    @apply align-top
                        mt-0
                        ml-1
                        inline-block;
                }
            }
        }
    }

    .nps-container {
        @apply block
            w-full;
    }

    .nps-buttons {
        @apply grid
            grid-cols-10
            text-center;

        .radio-button.-label-bottom {
            label {
                @apply ml-0;
            }
        }

        @screen -md {
            .radio-button.-label-bottom {
                @apply text-center;

                .radio {
                    @apply w-[16px]
                        h-[16px]/* comment to keep things from breaking */;

                    &::before {
                        @apply w-[16px]
                            h-[16px]/* comment to keep things from breaking */;
                    }
                }
                
                label {
                    @apply align-baseline
                        block
                        ml-0
                        mt-1;
                }
            }
        }
    }

    .nps-labels {
        @apply grid
            grid-cols-2
            text-center;

        .nps-unlikely {
            @apply justify-self-start
                max-w-[4rem]
                text-neutral-70;
        }
        .nps-likely {
            @apply justify-self-end
                max-w-[4rem]
                text-neutral-70;
        }
    }
}
