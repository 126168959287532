.learner-online-learning-assessment-results-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;
    
    &__title {
        @apply flex
            items-center
            gap-4
            my-8;
    }

    @screen -md {
        &__title {
            @apply flex-col
            items-start;
        }
    }
}
