.user-basic-info-page {
    @apply flex
        flex-col
        h-full;

    h2 {
        @apply mb-8;
    }

    &__information {
        @apply grid
            grid-cols-1
            md:grid-cols-2
            lg:grid-cols-3
            gap-8;
    }

    .email {
        @apply break-all;
    }
}
