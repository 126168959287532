.contract-card {
    &__form {
        @apply grid grid-cols-4;
    }

    .no-limit {
        @apply text-neutral-70
            italic;
    }
}
