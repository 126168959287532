.user-providers-list-page {
    @apply flex
        flex-col
        h-full;
    
    .side-content-left-layout {
        @apply overflow-hidden;

        & > *:last-child {
            @apply overflow-hidden;
        }
    }

    &__sidebar {
        @apply p-8;
        
        > p {
            @apply mb-2;
        }

        .form-field + .form-field {
            @apply mt-4;
        }

        .form-field + button {
            @apply mt-4;
        }

        &__search {
            @apply relative
                mb-4;

            svg {
                @apply absolute
                    left-1
                    top-3
                    fill-neutral-50;
            }

            input {
                @apply pl-8;
            }
        }
    }

    table {
        td.lms-id,
        td.status {
            width: 1%;
        }
        
        .user-roles {
            @apply relative
                -mt-4
                -ml-3;

            > * {
                @apply inline-block
                    ml-3
                    mt-4
                    font-bold
                    text-neutral-70;
            }
        }
    }
}
