.attempt-responses-list {
    @apply mt-8;

    h3 {
        @apply font-bold
            text-base;
    }

    li {
        @apply border-t
            border-neutral-30
            py-6;

        &:last-child {
            @apply border-b
                border-neutral-30;
        }
    }
}
