.search-text-input {
    @apply flex
        mb-8
        relative;

    & button {
        @apply -ml-10
            rounded-bl-none
            rounded-tl-none
            w-10
            mt-1;
    }

    &::before {
        @apply absolute
            block
            bg-[url('assets/icons/24px/Search.svg')]
            bg-no-repeat
            h-[24px]
            w-[24px]
            mt-[-12px]
            left-2
            top-1/2
            opacity-50;
        content: '';
    }

    input {
        @apply mt-0
            pl-9;
    }
}
