.accordion {
    @apply rounded-md;
    margin-bottom: 0.625rem;

    &.-card {
        @apply shadow-card;

        .accordion__button {
            @apply border-0
                py-6;

            &-open {
                @apply border-0
                    py-6;
            }
        }

        .accordion__details {
            @apply border-0
                rounded-none
                pt-0
                px-5;
        }
    }

    &__button {
        @apply border
            border-neutral-30
            font-normal
            flex
            justify-between
            leading-6
            px-5
            py-4
            relative
            rounded-[0.3rem]
            text-base
            text-left
            text-neutral-90
            transition
            w-full;
    }

    &__button-open {
        @apply border
            border-b-0
            border-neutral-30
            font-normal
            flex
            items-center
            justify-between
            leading-6
            px-5
            py-4
            relative
            rounded-[0.3rem]
            rounded-b-none
            text-left
            text-neutral-90
            transition
            w-full;
    }

    &__button-right {
        @apply flex
            justify-end;
    }

    &__chevron svg {
        @apply fill-neutral-70;
    }

    &__details {
        @apply border
            py-7 pl-[1.375rem]
            pr-[5.85rem]
            rounded-b-[0.3rem]
            border-neutral-30
            text-left
            cursor-default
            w-full;
    }

    &__heading {
        @apply mb-0;
    }

    &__subtitle {
        @apply font-bold
            mr-12
            text-neutral-70
            text-xs
            tracking-widest;
    }

    &__title {
        @apply font-normal
            leading-6
            text-lg
            text-neutral-90;

        .-number {
            @apply text-neutral-90
                text-base
                font-semibold;
        }
    }
}
