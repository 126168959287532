.product-description-card {
    h3 {
        @apply mb-2;
    }

    p {
        @apply mb-6;
    }

    &__topic-bubble {
        @apply flex
            flex-wrap
            gap-2
            justify-start;
    }
}
