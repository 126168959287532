.calendar-month-view {
    @apply flex
        gap-8
        w-full
        
        -lg:flex-col;

    &.-four-week {
        @apply aspect-[13/6.5]/* */;
    }

    &.-five-week {
        @apply aspect-[13/7.5]/* */;
    }

    &.-six-week {
        @apply aspect-[13/8.5]/* */;
    }

    &.-four-week,
    &.-five-week,
    &.-six-week {
        @apply -lg:aspect-auto;
    }

    &__calendar {
        @apply shadow-card
            w-2/3
            rounded-md

            -lg:w-full;

        &.-four-week {
            @apply -lg:aspect-[8/5]/* */;
        }

        &.-five-week {
            @apply -lg:aspect-[4/3]/* */;
        }

        &.-six-week {
            @apply -lg:aspect-[8/7]/* */;
        }

        &.-four-week,
        &.-five-week,
        &.-six-week {
            @apply -md:aspect-auto;
        }

        &__header {
            @apply flex
                justify-between
                items-center
                border-b
                border-b-neutral-30
                min-h-[50px]
                p-1.5

                -sm:min-h-[45px]/* */;

            &.-four-week {
                @apply h-[10%]/* */;
            }

            &.-five-week {
                @apply h-[8.333%]/* */;
            }

            &.-six-week {
                @apply h-[7.142%]/* */;
            }

            p {
                @apply font-bold
                    text-neutral-70

                    -sm:text-sm;
            }

            .button {
                @apply p-0;
            }

            &__days {
                @apply flex
                    items-stretch
                    border-b
                    border-b-neutral-30
                    min-h-[50px]
                    
                    -sm:min-h-[45px]/* */;

                &.-four-week {
                    @apply h-[10%]/* */;
                }

                &.-five-week {
                    @apply h-[8.333%]/* */;
                }

                &.-six-week {
                    @apply h-[7.142%]/* */;
                }

                > * {
                    @apply flex
                        border-r
                        border-r-neutral-30
                        w-[14.29%]
                        items-center
                        p-1.5;

                    &:last-child {
                        @apply border-r-0;
                    }

                    p {
                        @apply -sm:text-xxs;
                    }
                }
            }
        }

        &__weeks {
            &.-four-week {
                @apply h-[80%]
                    min-h-[400px]
                    
                    -sm:min-h-[360px]/* */;
            }

            &.-five-week {
                @apply h-[83.333%]
                    min-h-[500px]
                    
                    -sm:min-h-[450px]/* */;
            }

            &.-six-week {
                @apply h-[85.714%]
                    min-h-[600px]
                    
                    -sm:min-h-[540px]/* */;
            }

            &__week {
                @apply flex
                    items-stretch
                    border-b
                    border-b-neutral-30
                    min-h-[100px]
                    
                    -sm:min-h-[90px]/* */;

                &.-four-week {
                    @apply h-[25%]/* */;
                }

                &.-five-week {
                    @apply h-[20%]/* */;
                }

                &.-six-week {
                    @apply h-[16.667%]/* */;
                }

                &__day {
                    @apply flex
                        flex-col
                        border-r
                        border-r-neutral-30
                        w-[14.29%]
                        p-1.5;

                    &:last-child {
                        @apply border-r-0;
                    }

                    &.-selected {
                        @apply border-2
                            border-accents-blue-base;
                    }

                    &__header {
                        @apply flex
                            items-center
                            justify-between
                            w-full;

                        p {
                            @apply -sm:text-sm;
                        }

                        ul {
                            @apply flex
                                space-x-1
                                m-0
                                p-0
                                list-none;

                            li {
                                @apply h-2
                                    w-2
                                    rounded-full
                                    bg-neutral-50;

                                &.-status-error {
                                    @apply bg-status-error-base;
                                }

                                &.-status-success {
                                    @apply bg-status-success-base;
                                }

                                &.-status-warning {
                                    @apply bg-status-warning-base;
                                }
                            }
                        }

                        &__notifications {
                            @apply flex
                                flex-row
                                space-x-1;

                            span {
                                @apply inline-block
                                    h-2
                                    w-2
                                    rounded-full;
                            }

                            .-cancelled {
                                @apply bg-status-error-base;
                            }
                            .-new {
                                @apply bg-status-success-base;
                            }
                            .-changed {
                                @apply bg-status-warning-base;
                            }
                        }
                    }

                    &__events {
                        @apply mt-auto
                            w-full;
                    }
                }

                &:last-child {
                    @apply border-b-0;
                }
            }
        }

        &__event {
            @apply flex
                items-center
                justify-between
                w-full
                rounded-md
                p-1;

            .icon {
                @apply -sm:w-3
                    -sm:h-3;

                @screen -sm {
                    min-width: 0.75em;
                    min-height: 0.75em;
                }
            }

            .icon + * {
                @apply flex
                    space-x-1
                    items-center
                    text-xs
                    font-bold
                    uppercase
                    tracking-widest

                    -sm:text-xxs;

                > * {
                    &:first-child {
                        @apply text-center
                            w-4

                            -xs:hidden;
                    }

                    &:last-child {
                        @apply text-right
                            w-6

                            -sm:hidden;
                    }
                }
            }

            + .calendar-month-view__calendar__event {
                @apply mt-1;
            }

            &.-style-dark {
                @apply bg-accents-blue-dark
                    text-accents-blue-light;

                svg {
                    @apply fill-accents-blue-light;
                }
            }

            &.-style-light {
                @apply bg-accents-blue-light
                    text-accents-blue-dark;

                svg {
                    @apply fill-accents-blue-dark;
                }
            }
        }
    }

    &__selected {
        @apply flex
            flex-col
            shadow-card
            w-1/3
            rounded-md

            -lg:w-full;

        &__header {
            @apply flex
                items-center
                w-full
                h-[8.333%]
                p-4
                border-b
                border-b-neutral-30;

            p {
                @apply font-bold
                    text-neutral-70;
            }
        }

        &__content {
            @apply h-full;
        }

        &__body {
            @apply p-4
                h-[91.667%]
                overflow-y-scroll;

            .empty-text {
                @apply flex
                    items-center
                    h-full
                    text-base
                    w-2/3
                    mx-auto
                    p-0;
            }

            &__events {
                @apply overflow-auto;
            }
        }

        &__event {
            @apply p-5
                rounded-md;

            + .calendar-month-view__selected__event {
                @apply mt-2;
            }

            &.-status-error {
                @apply bg-status-error-light
                    text-status-error-dark;

                p {
                    @apply text-status-error-dark;
                }

                svg {
                    @apply fill-status-error-dark;
                }

                button {
                    @apply bg-status-error-dark
                        text-status-error-light
                        border-status-error-dark
                        hover:bg-status-error-dark
                        focus:bg-status-error-dark;
                }
            }

            &.-status-success {
                @apply bg-status-success-light
                    text-status-success-dark;

                p {
                    @apply text-status-success-dark;
                }

                svg {
                    @apply fill-status-success-dark;
                }

                button {
                    @apply bg-status-success-dark
                        text-status-success-light
                        border-status-success-dark
                        hover:bg-status-success-dark
                        focus:bg-status-success-dark;
                }
            }

            &.-status-warning {
                @apply bg-status-warning-light
                    text-status-warning-dark;

                p {
                    @apply text-status-warning-dark;
                }

                svg {
                    @apply fill-status-warning-dark;
                }

                button {
                    @apply bg-status-warning-dark
                        text-status-warning-light
                        border-status-warning-dark
                        hover:bg-status-warning-dark
                        focus:bg-status-warning-dark;
                }
            }

            &.-style-dark {
                @apply bg-accents-blue-dark
                    text-accents-blue-light;

                p {
                    @apply text-accents-blue-light;
                }

                svg {
                    @apply fill-accents-blue-light;
                }

                button {
                    @apply bg-accents-blue-light
                        text-accents-blue-dark
                        border-accents-blue-light
                        hover:bg-accents-blue-light
                        focus:bg-accents-blue-light;
                }
            }

            &.-style-light {
                @apply bg-accents-blue-light
                    text-accents-blue-dark;

                p {
                    @apply text-accents-blue-dark;
                }

                svg {
                    @apply fill-accents-blue-dark;
                }

                button {
                    @apply bg-accents-blue-dark
                        text-accents-blue-light
                        border-accents-blue-dark
                        hover:bg-accents-blue-dark
                        focus:bg-accents-blue-dark;
                }
            }

            &__header {
                @apply flex
                    items-center
                    space-x-2
                    mb-2;
            }

            &__actions {
                @apply mt-4;

                button {
                    @apply hover:opacity-70;
                }
            }
        }
    }
}
