.selected-instructor-manager {
    &__header {
        @apply h-10
            w-full
            text-neutral-90
            border-b-[1px]
            border-neutral-30
            whitespace-nowrap;
    }

    &__instructor-card {
        @apply mb-6;

        .event-instructor-card {
            @apply m-0
                w-auto;
        }
    }

    &__no-instructor-selected-container {
        @apply rounded
            border-neutral-50
            border;
    }

    &__no-instructor-selected {
        @apply flex
            items-center
            justify-center
            text-center
            w-full
            p-4
            h-[128px]
            text-neutral-70
            text-sm;
    }
}
