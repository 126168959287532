.c-toast {
    /* Don't show toast's icon? */
    .Toastify__toast-icon {
        @apply hidden;
    }

    padding-top: 0;
    padding-bottom: 0;

    .Toastify__toast-body {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .Toastify__close-button {
        @apply mt-2;
        color: currentColor;
    }

    &.Toastify__toast {
        @apply m-0
            mt-4
            rounded;

        border-width: 1px;
        border-style: solid;
        box-shadow: none;
        min-height: 1px;

        .c-toast-content {
            display: flex;

            &__icon-container {
                &__icon {
                    vertical-align: middle;
                    margin-right: 8px;
                }
            }

            &__body {
                padding-top: 3px;
            }
        }

        // .Toastify__progress-bar {
        //     filter: alpha(0.5);
        // }

        &--success {
            @apply bg-status-success-light
                text-status-success-dark
                border-status-success-base;

            .Toastify__progress-bar {
                @apply bg-status-success-base;
            }

            .c-toast-content {
                &__icon-container {
                    &__icon {
                        path {
                            @apply fill-status-success-base;
                        }
                    }
                }
            }
        }

        &--error {
            @apply bg-status-error-light
            text-status-error-dark
            border-status-error-base;

            .Toastify__progress-bar {
                @apply bg-status-error-base;
            }

            .c-toast-content {
                &__icon-container {
                    &__icon {
                        path {
                            @apply fill-status-error-dark;
                        }
                    }
                }
            }
        }

        &--warning {
            @apply bg-status-warning-light
            text-status-warning-dark
            border-status-warning-base;

            .Toastify__progress-bar {
                @apply bg-status-warning-base;
            }

            .c-toast-content {
                &__icon-container {
                    &__icon {
                        path {
                            @apply fill-status-warning-dark;
                        }
                    }
                }
            }
        }

        &--info {
            @apply bg-status-info-light
                text-status-info-dark
                border-status-info-base;

            .Toastify__progress-bar {
                @apply bg-status-info-base;
            }

            .c-toast-content {
                &__icon-container {
                    &__icon {
                        path {
                            @apply fill-status-info-base;
                        }
                    }
                }
            }
        }
    }

    @screen -xs {
        &.Toastify__toast {
            @apply m-4;
        }
    }
}

.Toastify__toast-container {
    @apply p-0;
}
