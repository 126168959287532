.admin-layout-header-navigation {
    @apply border-l
        border-l-border
        flex
        items-center
        h-full
        relative
        w-20;

    &__close {
        @apply bg-neutral-90
            fill-white;
    }

    &__open {
        @apply fill-neutral-90;
    }

    &__close,
    &__open {
        @apply h-full
            rounded-none
            w-full;
    }
}

html[dir=rtl] .admin-layout-header-navigation {
    @apply border-l-0
        border-r
        border-r-border;
}
