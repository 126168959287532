.support-page {
    @apply flex
        gap-8
        max-w-[1152px]
        mx-auto
        p-8;

    &__content {
        @apply w-2/3;

        h2 {
            @apply mb-4;
        }

        &__accordion {
            p {
                @apply w-11/12;

                a {
                    @apply underline
                        transition-colors
                        text-accents-blue-base
                        hover:text-accents-blue-dark;
                }
            }

            ul {
                @apply mt-2
                    ml-2;

                li {
                    @apply list-disc
                        ml-8;
                }
            }

            + h2 {
                @apply mt-10;
            }
        }
    }

    &__sidebar {
        @apply w-1/3;

        &__section {
            h2 {
                @apply mb-2;
            }

            p + a {
                @apply mt-4;
            }

            + .support-page__sidebar__section {
                @apply mt-16;
            }
        }
    }

    &__video {
        @apply flex justify-center;

        &-iframe {
            @apply w-2/3 h-72;
        }
    }

    .card {
        @apply mb-3;
    }

    @screen -lg {
        @apply flex-col;

        &__content {
            @apply w-full;
        }

        &__sidebar {
            @apply w-full;
        }
    }
}
