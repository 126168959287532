.form-calendar-date-range-picker {
    & > label {
        @apply font-medium
            text-neutral-90
            text-sm;
    }
    .react-datepicker-wrapper {
        @apply block
            mt-1
            rounded-md;
        background-image: url(/assets/icons/16px/Calendar.svg);
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: right 10px center;

        input[type=text] {
            @apply mt-0;
            background: transparent;
        }
    }
    .react-datepicker__close-icon {
        background: white;
        height: auto;
        top: 3px;
        right: 3px;
        bottom: 3px;

        &::after {
            @apply bg-accents-blue-dark;
        }
    }
}
