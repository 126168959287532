.user-training-assessment-page {
    @apply flex
        flex-col
        h-full;

    &__header {
        @apply flex
            items-center
            justify-between;
    }

    &__details {
        @apply my-8
            lg:max-w-[80%]
            max-w-full;
    }

    &__overview {
        &__grid {
            @apply grid
                grid-cols-3
                gap-6
                mt-6;

            > * {
                @apply flex
                    items-center
                    space-x-6;

                p:first-of-type {
                    @apply font-bold;
                }
            }
        }
    }
}
