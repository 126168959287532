.event-product-summary {
    &__header {
        @apply h-10
            w-full
            text-neutral-90
            border-b-[1px]
            border-neutral-30
            whitespace-nowrap;
    }

    &__no-product-selected-container {
        @apply aspect-[10/6]
            border
            border-neutral-30
            rounded;
    }

    &__no-product-selected {
        @apply flex
            h-full
            items-center
            justify-center
            p-4
            w-full;

        p {
            @apply text-neutral-70;
        }
    }
}
