.event-attendance-summary {
    &__event-day-name {
        @apply cursor-pointer
            underline;
    }
    .date,
    .status,
    .summary {
        width: 1%;
        @apply whitespace-nowrap;
    }
    td.action {
        @apply text-neutral-70;
    }
}
