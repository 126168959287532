.aen-product-card {
    @apply flex
        h-48
        rounded
        shadow-neutral-50
        shadow-card
        relative;

    &__detail {
        @apply bg-white
            grow
            p-6
            rounded-l;

        p {
            @apply px-4
            line-clamp-2;
            word-break: break-word;
        }
    }

    &__name {
        @apply mb-3;
    }

    &__select-action {
        @apply absolute
            bottom-4
            left-4
            mt-auto
            pb-4
            pl-6;
    }

    &__image-container {
        @apply w-[11.375rem]
            shrink-0;

        @apply rounded-r
            overflow-hidden;
    }
}
