.instructor-default-dashboard {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    h2 {
        @apply mb-6;
    }

    &__section {
        @apply mb-10;
    }

    &__heading {
        @apply flex
            items-center
            justify-between
            w-full
            mb-6;

        @apply -md:flex-col
            -md:gap-4
            -md:items-start;

        h2 {
            @apply mb-0;
        }
    }

    &__notifications {
        @apply mb-10;
    }
}
