.manage-live-virtual-event-evaluation-page{@apply max-w-[1152px]
        mx-auto
        p-8}.manage-live-virtual-event-evaluation-page__header{@apply flex
            items-center
            justify-between
            mb-8}.manage-live-virtual-event-evaluation-page__title{@apply flex
            fill-accents-blue-base
            items-center}.manage-live-virtual-event-evaluation-page__title h2{@apply mb-0}.manage-live-virtual-event-evaluation-page__title svg{@apply mr-1}.manage-live-virtual-event-evaluation-page__intro{@apply mb-10}.manage-live-virtual-event-evaluation-page__view-selection button.-selected{@apply bg-white
                    text-neutral-90}.manage-live-virtual-event-evaluation-page__view-selection button:first-child{@apply mr-0
                    rounded-r-none}.manage-live-virtual-event-evaluation-page__view-selection button:last-child{@apply ml-0
                    rounded-l-none}.manage-live-virtual-event-evaluation-page__summary,.manage-live-virtual-event-evaluation-page .evaluation-manager{@apply mt-8}.manage-live-virtual-event-evaluation-page .summary-question{@apply clear-both}.manage-live-virtual-event-evaluation-page .summary-question.chart{@apply flex
                flex-col
                lg:flex-row
                gap-8
                lg:items-center}.manage-live-virtual-event-evaluation-page .summary-question__question{@apply w-full
                lg:w-2/5}.manage-live-virtual-event-evaluation-page .summary-question__chart{@apply overflow-x-auto
                md:overflow-visible
                w-full
                lg:w-3/5}.manage-live-virtual-event-evaluation-page .summary-question__chart:before,.manage-live-virtual-event-evaluation-page .summary-question__chart:after{@apply block
                left-0
                mx-auto
                    my-4
                    italic
                    sticky
                    text-center
                    text-neutral-70
                    text-xs;content:"≪  scroll to see more  ≫"}.manage-live-virtual-event-evaluation-page .summary-question__chart .bar-chart{@apply pt-6
                    lg:float-right
                    w-full
                    min-w-min}@screen md{.manage-live-virtual-event-evaluation-page .summary-question__chart:before,.manage-live-virtual-event-evaluation-page .summary-question__chart:after{@apply hidden}}.manage-live-virtual-event-evaluation-page .summary-question__accordion{@apply flex
                flex-col
                gap-4
                w-full
                items-center
                justify-between}.manage-live-virtual-event-evaluation-page .summary-question__accordion__title{max-width:80%}.manage-live-virtual-event-evaluation-page .summary-question__accordion__responses button{@apply flex
                        items-center
                        space-x-2}.manage-live-virtual-event-evaluation-page .summary-question__accordion__responses button .icon{@apply fill-neutral-70}@screen sm{.manage-live-virtual-event-evaluation-page .summary-question__accordion{@apply flex-row
                    gap-0}}.manage-live-virtual-event-evaluation-page .summary-question__responses{@apply mt-6}.manage-live-virtual-event-evaluation-page .summary-question__responses table tbody tr td{@apply align-top}.manage-live-virtual-event-evaluation-page .summary-question__responses table tbody tr td:first-child{@apply w-24}