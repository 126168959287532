.report-section {
    @apply w-full
        mb-8;

    &.half {
        @apply w-1/2
            -md:w-full;
    }

    > p + .form-field {
        @apply mt-2;
    }

    &__header {
        @apply flex
            justify-between
            items-center
            mb-4;
    }
}
