.record-summary-entry {
    @apply flex
        gap-4
        items-center;

    > * {
        @apply text-sm;

        &:first-child {
            @apply font-bold;
        }

        p + p {
            @apply mt-0;
        }
    }

    @screen -md {
        @apply flex-col
        items-start;
    }
}
