.radio-button-group {
    @apply flex;

    &__radio {
        @apply fixed
        opacity-0
        w-0;
    }

    &__text {
        @apply border-[0.0625rem]
            inline-block;
    }

    &.-first {
        label {
            @apply rounded-l-[0.3rem] /* Comment to prevent a trailing ] causing a resolve-url-loader issue. */;
        }
    }

    &.-last {
        label {
            @apply rounded-r-[0.3rem] /* Comment to prevent a trailing ] causing a resolve-url-loader issue. */;
        }
    }

    input {
        &:checked + label {
            @apply bg-neutral-90
            border-neutral-70
            text-white;
        }
    }

    label {
        @apply border-neutral-70
            font-bold
            leading-4
            min-w-[8.125rem]
            px-6
            py-3
            text-neutral-90
            text-sm;
    }
}
