.event-product-list {
    td.id,
    td.type,
    td.language,
    td.topic {
        width: 1%;
    }
    td.name {
        @apply min-w-[200px]
        /* Comment to prevent a trailing ] causing a resolve-url-loader issue. */;
    }

    .type {
        @apply whitespace-nowrap;
    }
}
