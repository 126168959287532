.send-an-email-form {
    @apply w-full;

    h5 {
        @apply mb-10;
    }

    &__container {
        @apply mb-12;

        &__message {
            @apply grid gap-y-6;
        }
    }
}
