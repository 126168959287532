.course-activation-modal {
    &__content {
        p.-xlarge {
            font-size: 26px;
            line-height: 36px;
            max-width: 85%;
        }
    }

    &__section {
        @apply mt-8;

        h2 {
            @apply mb-0;
        }

        p + p {
            @apply mt-1;
        }

        .empty-text {
            padding-top: calc(38.5px + 0.75rem);
            padding-bottom: 0.75rem;
        }
    }

    &__details {
        &__header {
            @apply flex
                items-center
                space-x-6
                mb-4;
        }

        &__content {
            @apply grid
                grid-cols-2
                gap-4;
        }

        &__metadata {
            @apply grid
                grid-cols-3
                gap-4
                mt-6;
        }
    }
    .modal__panel {
        @screen sm {
            width: 885px;
        }
    }
}
