.event-assessment-results-scorm-package-card {
    @apply mb-8;

    h3 {
        @apply font-light
            leading-[2.8125rem]
            mb-4
            text-2xl
            text-neutral-90;
    }

    @screen sm {
        h3 {
            @apply mb-0;
        }
        
        &__container {
            @apply flex
                justify-between;
        }
    }
}
