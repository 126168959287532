.bar-chart {
    @apply inline-block;

    &__columns {
        @apply flex
            space-x-8
            px-4
            h-28
            border-b
            border-b-wireframes;

        &__column {
            @apply w-[100px]
                relative;

            &:nth-child(1) {
                .bar {
                    @apply bg-neutral-30;
                }
            }

            &:nth-child(2) {
                .bar {
                    @apply bg-neutral-50;
                }
            }

            &:nth-child(3) {
                .bar {
                    @apply bg-neutral-70;
                }
            }

            &:nth-child(4) {
                .bar {
                    @apply bg-accents-blue-base;
                }
            }

            &:nth-child(5) {
                .bar {
                    @apply bg-neutral-90;
                }
            }

            .bar {
                @apply absolute
                    left-1/2
                    bottom-0
                    w-[60px]
                    -translate-x-1/2
                    bg-neutral-30;

                p {
                    @apply absolute
                        left-1/2
                        -top-6
                        text-center
                        -translate-x-1/2;
                }
            }
        }
    }

    &__column__labels {
        @apply flex
            space-x-8
            mt-4
            px-4;

        &__column {
            @apply w-[100px]
                text-center;
        }
    }
    &__column__description {
        @apply flex
            text-center;

        .pg-desc {
            @apply text-neutral-70;
        }
    }

    &__rows {
        @apply mb-8;

        &__row {
            @apply grid
                grid-cols-2
                h-12;

            &__label {
                @apply space-y-8
                    py-6
                    border-r
                    border-r-wireframes;
            }

            &__bar-container {
                @apply w-[80%]
                    relative
                    py-6;

                .bar {
                    @apply h-[40px]
                        top-[1rem]
                        absolute;

                    p {
                        @apply top-[.75rem]
                            -right-10
                            absolute;
                    }
                }
            }
        }
    }
}
