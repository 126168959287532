.form-calendar-date-picker {
    & > label {
        @apply font-medium
            text-neutral-90
            text-sm;
    }

    .react-datepicker-wrapper {
        @apply mt-1;
    }
}
