.user-new-page {
    @apply max-w-[1152px]
        mx-auto
        p-8
        pb-20
        relative;

    h2 {
        @apply mb-1;
    }

    &__roles {
        @apply my-6;

        .tag-list {
            @apply mt-4;
        }
    }

    &__section {
        h3 {
            @apply mb-3;
        }

        p + .form-field {
            @apply mt-2;
        }

        p.-light + .form-field {
            @apply mt-4;
        }

        .form-field + p {
            @apply mt-0.5
                italic;
        }

        p + .toggle-label {
            @apply mt-6;
        }

        &__grid {
            @apply grid
                grid-cols-3
                gap-6;
        }

        &__details {
            @apply w-2/3;
        }

        &__heading {
            @apply flex
                justify-between
                mt-12;
        }
    }

    &__actions {
        @apply fixed
            flex
            flex-1
            justify-end
            bottom-0
            left-0
            w-full
            py-2
            px-6
            border-t
            border-t-neutral-50
            bg-white;
    }
}
