.instructor-enh-approved-to-teach-page {
    @apply max-w-[1152px]
        mx-auto
        pb-6;

    &__section {
        @apply mb-8;

        > p {
            @apply w-3/5;
        }

        table {
            thead {
                tr {
                    th {
                        &:first-child {
                            @apply w-3/5;
                        }

                        &:nth-child(2) {
                            @apply w-1/5;
                        }

                        &:last-child {
                            @apply w-1/5;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &:last-child {
                            @apply text-right;
                        }
                    }
                }
            }
        }
    }
}
