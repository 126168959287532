.product-details-form {

    h2 {
        @apply mb-4;
    }

    &__section {
        @apply pb-8
            grid
            gap-8;
        
        &.general-info,
        &.ids {
            @apply grid-cols-2;
        }

        &.catalog-info {
            @apply grid-cols-1;
        }

        &:last-child {
            @apply border-none
                mb-0;
        }

        &__column {
            @apply grid
                grid-cols-1
                gap-8
                h-fit;
        }
    }

    &__divider {
        @apply bg-neutral-30
            mb-8;

        height: 1px;
    }

    &__input-description {
        @apply italic
            leading-3
            mt-2
            text-xs
            text-neutral-70;
    }

    &__product_image-upload {
        @apply flex
            gap-6;

        .product-image {
            @apply w-[80px]
                border
                border-neutral-70
                rounded-md;
        }

        .form-field {
            @apply grow;

            .file-input-with-buttons {
                @apply w-auto
                    border;
            }
        }
    }
}
