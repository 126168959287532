.admin-reports-downloads-page {
    @apply flex
        flex-col
        h-full;

    table {
        .date-ran {
            @apply whitespace-nowrap;
        }
        td.date-ran,
        td.status {
            width: 1%;
        }
    }
}
