.public-layout-footer {
    @apply flex
        flex-wrap 
        gap-4
        text-sm;

    &__links {
        @apply flex
        gap-4;
    }

    &__link {
        @apply text-accents-blue-dark
        underline;
    }
}
