.radio-button {
    @apply flex
        items-center;

    &.-label-bottom {
        @apply flex-col
            max-w-[105px]
            text-center;

        .radio,
        label {
            @apply block
                pl-0;
        }

        label {
            @apply mt-1;
        }
    }

    input {
        &:checked,
        &:not(:checked) {
            @apply left-[-9999px]
                absolute;
        }

        &:checked + .radio {
            @apply text-accents-blue-dark;
        }

        &:checked + .radio + label {
            @apply text-accents-blue-dark;
        }

        &:not(:checked) + .radio {
            @apply text-neutral-90;
        }

        &:checked + .radio,
        &:not(:checked) + .radio {
            @apply relative
                inline-block
                cursor-pointer;

            &:before {
                content: "";
                @apply absolute
                    left-0
                    top-[0px]
                    w-[20px]
                    h-[20px]
                    bg-white
                    border-solid
                    border-[1px]
                    rounded-full
                    border-accents-blue-base;
            }
            &:after {
                content: "";

                @apply absolute
                    top-[5px]
                    left-[5px]
                    w-[10px]
                    h-[10px]
                    rounded-full
                    bg-accents-blue-base
                    transition-all;
            }
        }

        &:focus + .radio {
            &:before {
                @apply border-accents-blue-dark
                    border-[1px]
                    border-solid
                    #{!important};
            }
            &::after {
                @apply bg-accents-blue-dark #{!important};
            }
        }

        &:not(:checked) + .radio:before {
            @apply border-neutral-50;
        }

        &:not(:checked) + .radio:after {
            @apply opacity-0
                scale-0;
        }

        &:checked + .radio:after {
            @apply opacity-100
            scale-100;
        }
    }

    .radio {
        @apply w-[20px]
            h-[20px]
            /* Comment to prevent a trailing ] causing a resolve-url-loader issue. */;
    }

    label {
        @apply pl-4
            cursor-pointer
            text-sm
            tracking-tight
            break-words;
    }
}
