.event-location-card {
    &__address {
        @apply font-normal
            leading-7
            text-lg
            text-neutral-90;
    }

    &__address-block {
        @apply grid row-span-3;
    }

    &__container {
        @apply grid
            gap-4;

        @screen lg {
            @apply grid-cols-2;
        }
    }

    &__details {
        @apply leading-6
            whitespace-pre-wrap;

        &__label {
            @apply mb-2;
        }
    }

    &__heading {
        @apply mb-4;
    }
}
