.course-list {
    .data-table.course-list {
        td.id,
        td.type,
        td.version,
        td.last-change,
        td.changed-by,
        td.status {
            width: 1%;
        }
        .type,
        .last-change,
        .changed-by {
            @apply whitespace-nowrap;
        }
    }
}
