.learner-take-a-training-contact-card {
    @apply mb-6;

    &__heading {
        @apply font-light
            leading-[2.125rem]
            mb-5
            text-2xl
            text-neutral-90;
    }

    &__sections {
        @apply grid
            gap-8
            col-span-1;
    }

    &__card-heading {
        @apply mb-4;
    }

    &__instructor-icon {
        @apply border-2
            border-neutral-90
            h-[3.125rem]
            mr-3
            w-[3.125rem]
            rounded-full;
    }

    &__instructor-section {
        @apply flex
            justify-start;
    }

    &__button {
        @apply mt-10;
    }
}
