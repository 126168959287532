.form-time-picker {
    & > label {
        @apply font-normal
            leading-5
            text-neutral-90
            text-sm;
    }
    .react-datepicker-wrapper {
        @apply mt-1;
    }
}
