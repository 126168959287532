.training-card {
    .badge + .form-field-readonly {
        @apply mt-6;
    }

    &__stats {
        @apply grid
            grid-cols-1
            mt-4
            mb-8
            gap-4;

        .form-field-readonly + .form-field-readonly {
            @apply mt-0;
        }
    }
}
