.form-radio-card-list {
    &.disabled {
        .form-radio-card-list__cards {
            .radio-card {
                &:hover {
                    @apply cursor-not-allowed;
                }

                input {
                    &:checked + .radio-card__details {
                        @apply border-2
                            border-accents-blue-base
                            bg-white
                            text-neutral-90;

                        svg path {
                            @apply fill-accents-blue-base;
                        }
                    }
                }

                &__details {
                    @apply bg-neutral-05
                        text-neutral-70;

                    svg path {
                        @apply fill-neutral-70;
                    }
                }
            }
        }
    }

    &.horizontal {
        .form-radio-card-list__cards {
            .radio-card {
                &__details {
                    @apply flex-row
                        items-center
                        justify-start
                        space-x-3
                        py-4
                        text-left
                        text-base;

                    svg {
                        @apply inline-block
                            mx-0
                            my-0;
                    }
                }
            }
        }
    }

    &__cards {
        @apply grid
            gap-6
            mt-2;

        &.two-columns {
            @apply grid-cols-2
                -md:grid-cols-1;
        }

        &.three-columns {
            @apply grid-cols-3;
        }

        .radio-card {
            &:hover {
                @apply cursor-pointer;
            }

            input {
                @apply hidden;

                &:checked + .radio-card__details {
                    @apply relative;
                }
                &:checked + .radio-card__details::before {
                    @apply absolute
                        -top-px
                        -right-px
                        -bottom-px
                        -left-px
                        border-2
                        border-accents-blue-base
                        content-['']
                        rounded-md;
                }
            }

            &__details {
                @apply transition-all
                    p-4
                    rounded-md
                    border
                    border-neutral-30
                    bg-white
                    text-center
                    text-neutral-90
                    text-lg
                    h-full
                    flex
                    flex-col
                    justify-center;

                svg {
                    @apply block
                        mx-auto
                        mb-1
                        fill-accents-blue-base;
                }

                .-disabled {
                    @apply fill-neutral-50
                        text-neutral-50;
                }
            }
        }

        .tool-tip {
            @apply absolute
                h-full
                left-0
                top-0
                w-full;

            & > div {
                @apply h-full;
            }
        }
    }
    .layered {
        @apply float-left
            relative;

        button {
            @apply w-full;
        }
    }

    .layered > * {
        @apply col-start-1
            row-start-1;
    }
}
