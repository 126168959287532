.online-product-content-list {
    td {
        @apply align-top;
    }
    td.description {
        p {
            @apply max-w-[336px]
                text-xs;
        }
    }
}
