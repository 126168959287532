.event-contract-form {
    &__header {
        @apply flex justify-between;

        h3 {
            @apply font-light
                mb-4
                text-2xl;
        }
    }

    &__grid {
        @apply grid
            grid-cols-3
            gap-8
            mb-4;
    }

    &__form-field-container {
        @apply relative;
    }
}

.calendar-date-picker__text {
    @apply w-full;
}
