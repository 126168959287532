.group-detail-sidebar {
    @apply p-8;

    > * {
        @apply block
            transition-colors
            no-underline
            text-neutral-70
            text-sm
            hover:text-accents-blue-dark;

        &.-selected {
            @apply font-bold
                text-accents-blue-dark;
        }

        + * {
            @apply mt-4;
        }
    }
}
