.c-sync-user-data-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 49px;
    flex-shrink: 0;
    border-radius: 5px;
    margin-left: 20px;
    margin-top: 20%;
    border: 1px solid var(--neutral-neutral-30, #d4d6de);

    &.-instructorProfile {
        margin-left: 0;
        margin-top: 4%;
    }

    &__rightSide {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 11px;
    }
    &__text {
        margin-left: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.24px;
        color: var(--neutral-neutral-70, #646a82);
        &.-instructorProfile {
            font-size: 12px;
            margin-left: 10px;
        }
    }
    &__lastSync {
        color: var(--neutral-neutral-70, #646a82);
        text-align: right;
        /* Label / All Caps - Small (10) */
        font-family: Roboto;
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px; /* 140% */
        letter-spacing: 1.1px;
        text-transform: uppercase;
        margin-right: 20px;
    }
}
