.learner-online-learning-unit-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;
    
    &__heading {
        @apply mb-8;
    }

    &__details {
        @apply flex
            flex-col;

        > :first-child {
            @apply pb-8;
        }

        > :last-child {
            @apply border-t
                border-t-neutral-30
                pt-8;
        }

        .badge {
            @apply mt-2
                mb-10;
        }

        p.-label + p {
            @apply mt-2;
        }

        p + p.-label {
            @apply mt-6;
        }

        @screen sm {
            @apply flex-row
                border-b
                border-b-neutral-30;

            > :first-child {
                @apply w-3/4
                    p-6
                    border-r
                    border-r-neutral-30;

                p {
                    max-width: 70%;
                }
            }

            > :last-child {
                @apply p-6
                    w-1/4
                    border-t-0;
            }
        }
    }

    &__topics {
        @apply mb-4;

        p {
            @apply mb-2;
        }
    }

    &__topic-bubbles {
        @apply inline;

        .bubble {
            @apply inline-block
                mr-2 mb-2;
        }
    }

    &__included {
        @apply mt-8
            mb-4;
    }
}
