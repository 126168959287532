.add-course-modal-course-list {
    th,
    td {
        &.action {
            @apply text-right
                w-28;
        }
    }
    
    td.id {
        width: 1%;
    }

    .course-name {
        @apply pl-0;
    }
}
