.course-version-create-modal {
    &__bullet {
        @apply mr-2.5;

        &-text {
            @apply pl-2.5
                 text-base
                text-neutral-70;
        }
    }

    &__header,
    &__section {
        @apply mb-4;
    }

    li {
        @apply flex
            justify-start;
    }
}
