.course-details-changes-modal {
    .modal__panel {
        @screen sm {
            width: 885px;
        }
    }

    &__change-log {
        @apply mb-3;
    }

    &__details {
        &__header {
            @apply flex
                items-center
                space-x-6
                mb-4;
        }

        &__content {
            @apply grid
                grid-cols-2
                gap-4;
        }

        &__metadata {
            @apply grid
                grid-cols-3
                gap-4
                mt-6;
        }
    }

    &__modal-heading {
        @apply text-[1.625rem]
            leading-9;

        &-description {
            @apply mb-8;
        }
    }

    &__section {
        @apply mt-8;

        h2 {
            @apply mb-0;
        }

        p + p {
            @apply mt-1;
        }
    }

    &__text-area {
        @apply mt-4;
    }
}
