.event-day-form {
    @apply grid
        gap-8
        mb-8;

    & > div {
        @apply inline-block;
    }

    &__event-day-name,
    &__event-day-date {
        @apply inline-block;
    }

    .calendar-date-picker__text {
        @apply w-full;
    }

    input {
        @apply h-10;
    }
}
