.button {
    @apply rounded-[0.3125rem]
        flex-row
        font-bold
        h-min
        inline-flex
        items-center
        justify-center
        w-max
        transition-all
        whitespace-nowrap;

    &.-disabled {
        @apply cursor-not-allowed;
    }

    & + & {
        @apply ml-3;
    }

    &.anchor {
        @apply align-top
            no-underline;

        &.-primary {
            @apply fill-white
                text-white;
        }

        &.-secondary {
            @apply fill-neutral-70
                text-neutral-90;
        }

        &.-tertiary {
            @apply fill-neutral-70
                text-neutral-90;
        }
    }

    &.-primary {
        @apply bg-neutral-90
            border
            border-neutral-90
            fill-white
            text-white
            focus-visible:bg-neutral-70
            focus-visible:outline-[0.1875rem]
            focus-visible:outline-accents-blue-base/40
            focus-visible:opacity-80
            hover:bg-neutral-70
            hover:border-neutral-70;

        &.-disabled {
            @apply bg-neutral-30
                border-neutral-30
                text-white;
        }
    }

    &.-secondary {
        @apply bg-white
            border
            border-neutral-70
            fill-neutral-70
            text-neutral-90
            focus-visible:bg-neutral-05
            focus-visible:outline-[0.1875rem]
            focus-visible:outline-offset-1
            focus-visible:outline-accents-blue-base/40
            focus-visible:opacity-80
            hover:bg-neutral-05;

        &.-small {
            @apply fill-neutral-90;
        }

        &.-disabled {
            @apply bg-white
                border-neutral-30
                fill-neutral-50
                text-neutral-50;
        }
    }

    &.-tertiary {
        @apply bg-neutral-05
            border
            border-neutral-05
            fill-neutral-70
            text-neutral-90
            focus-visible:bg-neutral-30
            focus-visible:outline-[0.1875rem]
            focus-visible:outline-accents-blue-base/40
            focus-visible:opacity-80
            hover:bg-neutral-30;

        &.-disabled {
            @apply bg-neutral-05
                fill-neutral-50
                text-neutral-50;
        }
    }

    &.-tertiary-alt {
        @apply bg-white
            border
            border-white
            fill-neutral-70
            text-neutral-90
            focus-visible:fill-neutral-90
            focus-visible:outline-[0.1875rem]
            focus-visible:outline-accents-blue-base/40
            focus-visible:opacity-70
            hover:fill-neutral-90
            hover:opacity-70;

        &.-disabled {
            @apply fill-neutral-50
                opacity-70
                text-neutral-50;
        }
    }

    &.-transparent {
        @apply border
            border-white
            text-white
            hover:fill-neutral-90
            hover:opacity-70;

        background-color: transparent;

        &.-disabled {
            @apply fill-neutral-50
                opacity-70
                text-neutral-50;
        }
    }

    &.-quaternary {
        @apply bg-neutral-70
            border
            border-neutral-70
            text-white
            hover:bg-neutral-90;

        &.-disabled {
            @apply bg-neutral-05
                fill-neutral-50
                text-neutral-50;
        }
    }

    &.-inverted {
        @apply bg-white
            border
            border-white
            fill-neutral-70
            text-neutral-90
            hover:bg-accents-blue-base
            hover:text-neutral-90
            hover:fill-white;

        &:hover {
            @apply border-accents-blue-base
                text-white;
            svg {
                @apply fill-white;
            }
        }

        &.-disabled {
            @apply bg-neutral-50
                border-neutral-50
                fill-neutral-90
                text-neutral-90;
        }
    }

    &.-link-alt {
        @apply bg-white
            fill-neutral-70
            text-neutral-70
            uppercase
            underline
            tracking-widest
            focus-visible:fill-neutral-90
            focus-visible:outline-[0.1875rem]
            focus-visible:outline-accents-blue-base/40
            focus-visible:opacity-70
            hover:fill-neutral-90
            hover:opacity-70;

        &.-disabled {
            @apply fill-neutral-50
                opacity-70
                text-neutral-50;
        }
    }

    &.-link {
        @apply bg-white
            fill-accents-blue-base
            text-accents-blue-base
            underline
            focus-visible:fill-accents-blue-dark
            focus-visible:outline-[0.1875rem]
            focus-visible:outline-accents-blue-dark
            focus-visible:opacity-70
            hover:fill-accents-blue-dark
            hover:opacity-70
            px-0
            py-0;

        &.-disabled {
            @apply fill-neutral-50
                opacity-70
                text-neutral-50;
        }
    }

    &.-destructive {
        @apply bg-status-error-light
            fill-status-error-base
            text-status-error-dark
            focus-visible:bg-status-error-base
            focus-visible:fill-white
            focus-visible:outline-[0.1875rem]
            focus-visible:outline-status-error-base/40
            focus-visible:text-white
            hover:bg-status-error-base
            hover:fill-white
            hover:text-white;

        &.-disabled {
            @apply bg-neutral-30
                fill-white
                text-white;
        }
    }

    &.-success {
        @apply bg-status-success-dark
            fill-white
            text-white
            focus-visible:bg-status-success-base
            focus-visible:fill-white
            focus-visible:outline-[0.1875rem]
            focus-visible:outline-status-success-base/40
            focus-visible:text-white
            hover:bg-status-success-base
            hover:fill-white
            hover:text-white;

        &.-disabled {
            @apply bg-neutral-30
                fill-white
                text-white;
        }
    }

    &.-medium {
        @apply px-6
            py-2
            text-sm;

        &.-link-alt {
            @apply py-0
                px-2
                text-xs;
        }
    }

    &.-small {
        @apply text-[0.625rem]
            leading-3
            px-4
            py-2;
    }
}
