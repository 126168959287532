.tab {
    @apply bg-none
        fill-neutral-70
        h-9
        leading-5
        px-2.5
        py-2
        rounded-b-none
        text-neutral-70
        text-xs
        focus-visible:fill-neutral-90
        focus-visible:opacity-70
        focus-visible:outline-[0.1875rem]
        focus-visible:outline-accents-blue-base/40
        hover:fill-neutral-90
        hover:opacity-70
        whitespace-nowrap;

    margin-left: 0 !important;

    &.-active {
        @apply -mb-1
            border-accents-blue-base
            border-b-4
            fill-accents-blue-dark
            h-10
            text-accents-blue-dark;
    }

    &.-disabled {
        @apply fill-neutral-50
            opacity-70
            text-neutral-50;
    }

    .icon + .tab__label {
        @apply pl-1;
    }
}
