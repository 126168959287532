.unit-accordion {
    &__course {
        @apply w-full
            flex
            items-center
            justify-between
            mt-3;

        padding-right: 3.8rem;

        &__name {
            width: 60%;
        }

        &__details {
            @apply grid
                grid-cols-3
                items-center;

            width: 40%;

            > * {
                @apply text-right;

                > * {
                    @apply inline-block;
                }
            }

            > :nth-child(3) {
                p {
                    @apply flex
                        items-center
                        justify-end
                        text-neutral-70
                        font-bold;

                    svg {
                        @apply fill-neutral-70
                            mr-1;
                    }
                }
            }
        }
    }
}
