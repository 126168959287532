.event-schedule-page-header {
    @apply flex
        gap-8
        items-center;

    &__heading {
        @apply grow;

        h2 {
            @apply mb-0;
        }
    }
    &__class-time {
        @apply text-neutral-70
            text-right;
        
        &__icon-wrapper {
            @apply pl-1;
        }
        &__tooltip {
            @apply mb-3
                w-3
                h-3;
        }
    }
}
