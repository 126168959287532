.manage-event-layout-sidebar {
    @apply grid
        grid-rows-[max-content_1fr_max-content]
        h-full;

    &__details {
        @apply px-6
            py-8
            border-b
            border-b-neutral-70;

        .donut {
            @apply justify-center
                mb-6;
        }

        p {
            @apply text-center;

            word-break: break-word;
        }

        &__tags {
            @apply flex
                flex-wrap
                justify-center
                items-center
                space-x-2
                mt-4
                text-center;

            > * {
                @apply mt-2;
            }
        }
    }

    &__training-type {
        @apply mb-4;

        p {
            @apply text-neutral-50
                uppercase;

            svg {
                @apply inline
                    fill-neutral-50
                    mr-2;
            }
        }
    }

    &__nav {
        @apply overflow-y-auto
            p-6;

        > * {
            @apply flex
                items-center
                space-x-2
                transition-colors
                w-full
                p-2
                text-lg
                text-white
                no-underline
                border
                rounded-md;

            border-color: transparent;

            &.-active {
                @apply border-accents-blue-base
                    text-accents-blue-base;
            }

            &.-disabled {
                @apply text-neutral-70;
                svg {
                    @apply fill-neutral-70;
                }
            }

            &.-completed {
                svg {
                    @apply fill-white;
                }
            }

            .text {
                @apply pl-1;
            }

            svg {
                @apply fill-neutral-50;
            }

            + * {
                @apply mt-2;
            }
        }
    }

    &__footer {
        @apply bg-neutral-90
            border-t
            border-t-neutral-70
            grid
            h-20
            items-center
            px-6;

        > a {
            @apply w-full;

            svg {
                @apply fill-accents-blue-base #{!important};
            }
        }

        > a:hover {
            svg {
                @apply fill-white #{!important};
            }
        }
    }
}
