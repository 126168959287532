.-arabic {
    @apply font-arabicBody;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-arabicHeading;
    }
}
