.edit-user-modal {
    &__grid {
        @apply grid
            grid-cols-2
            gap-6
            mb-6;
    }

    &__section {
        @apply mt-6;

        > p:first-of-type {
            @apply mb-3;
        }

        p.-light {
            @apply mb-0.5
                italic;
        }
    }
}
