.catalog-enrollment-log-page {
    @apply flex
        flex-col
        h-full;

    .side-content-left-layout {
        @apply overflow-hidden;

        & > *:last-child {
            @apply overflow-hidden;
        }
    }

    &__radio {
        @apply flex space-x-6;
    }

    &__sidebar {
        @apply p-8;

        .-label {
            @apply mb-2;
        }

        .form-field + .form-field {
            @apply mt-4;
        }

        .form-field + .button {
            @apply mt-4;
        }
    }

    &__account-name {
        @apply text-neutral-90;
    }

    &__account-email {
        @apply text-neutral-70
                font-bold;
        text-transform: uppercase;
    }
}
