.event-activation-registration-section {
    @apply mt-8;

    h3 {
        @apply font-normal
            text-neutral-90
            text-lg
            mb-2;
    }

    p {
        max-width: 450px;
    }

    &__header {
        @apply flex
            justify-between
            mb-3;

        h2 {
            @apply mb-2;
        }

        .toggle-label {
            @apply mb-2;
        }
    }

    &__catalog-container {
        @apply grid
            grid-cols-2;
    }

    &__toggle-container {
        @apply flex
            mt-3
            space-x-10;
    }

    &__details {
        @apply mt-6;

        &-row {
            @apply grid
                grid-cols-2
                mt-6;
        }

        &__container-item {
            @apply mt-0;
        }
    }

    .banner {
        @apply justify-start;
    }
}
