.process-navigation-sidebar {
    > * {
        @apply flex
            items-center
            space-x-2
            transition-colors
            w-full
            p-2
            pr-4
            text-lg
            text-white
            no-underline
            border
            rounded-md;

        border-color: transparent;

        &.-active {
            @apply border-accents-blue-base
                text-accents-blue-base;
        }

        &.-completed {
            svg {
                @apply fill-white;
            }
        }

        .text {
            @apply pl-1;
        }

        svg {
            @apply fill-neutral-50;
        }

        + * {
            @apply mt-2;
        }
    }
}
