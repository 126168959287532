.header-actions {
    @apply flex
        items-center
        justify-between;

    > *:last-child {
        @apply flex
            items-center
            space-x-4;
    }
}
