.learner-instructor-assessment-details-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    h2 {
        @apply mb-8;
    }

    &__title {
        @apply flex
            fill-accents-blue-base
            items-center
            mb-4;

        svg {
            @apply mr-1;
        }
    }

    &__heading {
        @apply mb-4;
    }

    &__edit-button {
        @apply flex;

        @screen md {
            @apply justify-end;
        }
    }

    &__about {
        @apply grid
            gap-y-8
            grid-cols-1
            md:grid-cols-3
            md:gap-8;

        .card {
            @apply h-full;
        }
    }

    &__hr {
        @apply border-b-[0.84px]
            border-neutral-30
            mb-12
            mt-16;
    }

    &__contact {
        &__cards {
            @apply grid
                grid-cols-3
                gap-8;
        }
    }

    @screen -lg {
        &__contact {
            &__cards {
                @apply grid-cols-2;
            }
        }
    }
}
