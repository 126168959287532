.user-training-detail-expiration {
    &__section {
        @apply mb-8;

        .form-field-readonly {
            @apply mt-4;
        }

        .empty-text {
            @apply py-8;
        }
    }
}
