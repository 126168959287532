.manage-event-layout {
    @apply h-full
        w-full;

    &__body {
        @apply h-full;
    }

    &__window {
        @apply flex
            flex-row
            grow
            w-full
            h-full;

        &__sidebar {
            @apply bg-neutral-90
                self-stretch
                flex-col
                h-full
                w-1/4;
        }

        &__main {
            @apply flex-col
                relative
                overflow-y-scroll
                h-full
                w-3/4;

            & > #chakra-skip-nav {
                @apply h-full;
            }

            &.-has-fixed-banner {
                @apply pt-[40px] /* comment to keep things from breaking */;
            }
        }
    }

    @screen xl {
        &__window {
            &__sidebar {
                width: 320px;
            }
            &__main {
                width: calc(100% - 320px);
            }
        }
    }
}
