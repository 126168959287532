.event-content-list {
    @apply mb-8;

    &:last-of-type {
        @apply mb-0;
    }

    &__content-section {
        @apply gap-8
            grid;
    }

    & > h2 {
        @apply font-light
            leading-8
            mb-5
            text-2xl;
    }

    @screen -md {
        &__content-section {
            @apply grid-cols-1;
        }
    }
}
