.event-day-attendance-list {
    .attendance,
    .last-change,
    .by {
        @apply whitespace-nowrap;
    }
    td.last-change,
    td.by {
        width: 1%;
    }
    .attendance {
        @apply flex gap-6 w-full;
    }
}
