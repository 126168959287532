.instructor-directory-card {
    @apply flex
        h-[9.438rem]
        rounded
        shadow-neutral-50
        shadow-card
        relative;

    &__detail {
        @apply bg-white
            grow
            my-auto
            p-6
            rounded-l;

        .-user {
            @apply break-words
                line-clamp-2
                px-4;
        }
    }

    &__image-container {
        @apply shrink-0
            w-28;

        @apply overflow-hidden
            rounded-r;
    }

    &__info-button {
        @apply absolute
            opacity-75
            right-4
            top-4
            z-20;
    }
}
