.event-invoice-form {
    &__header {
        @apply flex
            justify-between
            mb-2;

        h3 {
            @apply font-light
                text-2xl;
        }
    }

    &__grid {
        @apply grid
            grid-cols-2
            gap-8;
    }

    textarea {
        height: 8.25rem;
    }
}
