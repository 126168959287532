.groups-associated-users-page {
    @apply flex
        flex-col
        h-full;

    table {
        .name {
            @apply whitespace-nowrap;
        }
        td.id,
        td.name {
            width: 1%;
        }
    }
    &__header {
        @apply flex
            items-center
            justify-between
            mb-8;

        h2 {
            @apply mb-0;
        }

        &__actions {
            @apply flex
                items-center
                space-x-2;

            &__search {
                @apply flex;
                .search-text-input {
                    @apply mb-0
                        w-72
                        pr-4;
                }
            }
        }
    }
}
