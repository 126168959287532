.aen-application-layout {
    @apply flex
        flex-col
        h-full;

    .side-content-left-layout {
        @apply overflow-hidden h-full;

        & > *:last-child {
            @apply overflow-hidden;
        }
    }
    .content-wrap {
        @apply h-full;
    }
}
