.course-version-changes-modal {
    &__content {
        @apply overflow-y-auto;

        p.-xlarge {
            font-size: 26px;
            line-height: 36px;
            max-width: 85%;
        }
    }

    &__heading {
        @apply text-[1.625rem] font-light;
    }

    &__section {
        @apply mt-8;

        h2 {
            @apply mb-0;
        }

        p + p {
            @apply mt-1;
        }
    }

    &__details {
        .banner {
            @apply mb-4
                mt-2;
        }

        textarea {
            @apply h-28;
        }
    }
    .modal__panel {
        @screen sm {
            width: 885px;
        }
    }
}
