.admin-product-enrollment-page {
    @apply max-w-[1152px]
        mx-auto
        mt-8
        px-8;

    &__header {
        @apply flex
                items-center
                justify-between
                mb-8;

        &__title {
            @apply flex
                    fill-accents-blue-base
                    items-center
                    space-x-2;
        }

        &__actions {
            @apply flex
                gap-4;

            .search-text-input {
                @apply inline-block
                        mb-0;
            }

            .button {
                & + .button {
                    @apply m-0;
                }
            }
        }
    }

    &__list {
        &__view {
            @apply flex
                flex-row
                grow
                w-full
                h-full;
        }
    }

    @screen xl {
        &__window {
            &__sidebar {
                @apply w-[320px]/* ... */;
            }
            &__main {
                width: calc(100% - 320px);
            }
        }
    }
}
