.manage-live-virtual-event-attendance-summary-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__title {
        @apply flex
            fill-accents-blue-base
            items-center
            mb-8;

        h2 {
            @apply mb-0;
        }

        svg {
            @apply mr-1;
        }
    }
}
