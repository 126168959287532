.admin-layout-header-announcements-panel-content {
    @apply bg-neutral-90
        flex
        flex-col
        h-full
        overflow-y-scroll
        p-8;

    color: #fff;
    
    &__title {
        @apply flex
            gap-2;
        
        font-size: 16px;
        font-weight: 700;
        line-height: 24px; /* 150% */
        letter-spacing: 0.32px;
        
        &__icon {
            fill: #aeb3c5;
        }
    }
    
    &__announcement {
        @apply py-4;
        
        &__time {
            color: #aeb3c5;
            font-size: 12px;
            font-weight: 700;
            line-height: 18px; /* 150% */
            letter-spacing: 2.4px;
            text-transform: uppercase;
        }
        
        &__details {
            /* Paragraph / Base (16) */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.32px;
            // width: 292px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__button {
            color: var(--accents-blue-base, #00b0ca);

            /* Label / All Caps - Base (12) */
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px; /* 150% */
            letter-spacing: 2.4px;
            text-transform: uppercase;
        }
    }

    &__nav-item {
        @apply block
            fill-neutral-50
            mb-8
            no-underline
            text-left
            text-white
            w-full
            hover:fill-accents-blue-base
            hover:text-accents-blue-base;

        margin-left: 0 !important;

        & > span {
            @apply m-0
                p-0;
        }

        & > svg {
            @apply m-0
                mr-3
                p-0
                relative
                top-0.5;
        }
    }

    &__nav-items {
        @apply flex
            flex-col
            justify-start
            p-8;
    }
}
