.pager {
    @apply flex
        flex-col
        gap-4
        justify-center
        w-full
        left-0
        bottom-0
        py-8;

    .-footer {
        @apply fixed
            bg-white
            border-t
            border-t-neutral-50;
    }

    &__wrapper {
        @apply flex
            gap-2
            items-stretch
            justify-center;

        button {
            @apply transition-colors
                px-3
                py-1.5
                bg-neutral-05
                text-neutral-90
                rounded-md
                text-xs
                font-bold
                flex
                items-center;

            &[disabled] {
                @apply cursor-not-allowed;

                svg.icon {
                    @apply fill-neutral-50;
                }
            }

            &:hover {
                @apply bg-neutral-30;
            }
        }

        > :first-child,
        > :last-child {
            @apply px-2;
        }
    }

    &__pages {
        @apply flex
            gap-0.5;

        button {
            @apply rounded-none;

            &:first-child {
                @apply rounded-s-md;
            }

            &:last-child {
                @apply rounded-e-md;
            }

            &.selected {
                @apply bg-neutral-90
                    text-white;
            }
        }
    }

    .counter {
        @apply text-center;
    }
}
