.achievement-modal {
    h2 {
        @apply mb-4;
    }

    &__cards {
        @apply flex
            flex-col
            gap-8;
    }

    &__modal {
        @apply flex
            gap-4
            items-center
            -sm:flex-col
            -sm:items-start;
    }

    &__modal-description {
        @apply flex
            flex-col
            gap-4;
    }

    &__modal-icon {
        @apply fill-accents-blue-base
            flex-col
            gap-4;
    }
}
