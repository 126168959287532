.file-input-with-buttons {
    @apply border-[0.06rem]
        border-neutral-70
        rounded-md
        flex
        items-center
        justify-between
        mt-1
        w-96;

    & > input[type="file"] {
        @apply hidden;
    }

    p {
        @apply px-4
            py-2
            text-sm;
    }

    &__delete {
        @apply mr-1;
    }

    &__file-name {
        @apply text-neutral-70
            truncate
            w-56;
    }

    &__instruction {
        @apply mt-1
            text-xs
            text-neutral-70;
    }

    &__select-file {
        @apply text-accents-blue-dark
            underline;
    }

    .button + .button {
        @apply ml-2;
    }

    button {
        @apply whitespace-nowrap;
    }
}
