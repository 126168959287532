.admin-layout {
    $headerHeight: 5rem;

    @apply h-full
        w-full;
    
    &.-emulation-mode {
        height: calc(100% - #{40px});
        position: relative;
        top: 40px;
    }

    &:has(.admin-event-list-page__list__view) {
        @apply overflow-hidden;
    }

    &__body {
        @apply relative
            top-20
            w-full;
        height: calc(100% - $headerHeight);
    }

    &__header {
        @apply fixed
            z-[100]
            w-full;
        height: $headerHeight;
    }

    main {
        @apply h-full;

        & > #chakra-skip-nav {
            @apply h-full;
        }

        &:has(.admin-course-list-page, .admin-product-list-page, .admin-announcements-list-page) {
            & > #chakra-skip-nav {
                @apply h-auto;
            }
        }
    }
}
