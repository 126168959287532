.admin-layout-header-navigation-panel {
    $headerHeight: 5rem;

    @apply bottom-0
        fixed
        right-0
        w-[320px]
        z-50;

    &__overlay {
        @apply bg-neutral-90
            bg-opacity-75
            bottom-0
            fixed
            left-0
            w-full;
    }

    &,
    &__overlay {
        height: calc(100% - $headerHeight);
    }

    & > .translate-x-0,
    & > .translate-x-full,
    & > .translate-x-\[-100\%\] {
        @apply h-full;
    }

    @screen -xs {
        @apply w-full;
    }
}

html[dir=rtl] .admin-layout-header-navigation-panel {
    @apply right-auto
        left-0;
}
