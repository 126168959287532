.user-instructor-history {
    table {
        td.type,
        td.start-date,
        td.status {
            width: 1%;
        }

        .icon-text {
            @apply flex
                items-center
                space-x-2;
        }
    }
}
