.icon {
    $xSmallSize: 0.625rem;
    $smallSize: 0.875rem;
    $baseSize: 1rem;
    $mediumSize: 1.25rem;
    $largeSize: 1.5rem;
    $xLargeSize: 2.75rem;

    @apply align-top
        inline-block;
    height: $baseSize;
    min-height: $baseSize;
    min-width: $baseSize;
    width: $baseSize;

    &.-large {
        height: $largeSize;
        min-height: $largeSize;
        min-width: $largeSize;
        width: $largeSize;
    }

    &.-medium {
        height: $mediumSize;
        min-height: $mediumSize;
        min-width: $mediumSize;
        width: $mediumSize;
    }

    &.-small {
        height: $smallSize;
        min-height: $smallSize;
        min-width: $smallSize;
        width: $smallSize;
    }

    &.-xlarge {
        height: $xLargeSize;
        min-height: $xLargeSize;
        min-width: $xLargeSize;
        width: $xLargeSize;
    }

    &.-xsmall {
        height: $xSmallSize;
        min-height: $xSmallSize;
        min-width: $xSmallSize;
        width: $xSmallSize;
    }
}
