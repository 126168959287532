.learner-take-a-training-details-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__about {
        @apply col-span-2
                -md:col-span-3;
    }

    &__banners {
        @apply mb-10;
    }

    &__link-trial {
        @apply mb-10;
    }

    &__cancellation-banner {
        @apply mb-8;

        .banner {
            @apply pt-6
                px-6
                inline-block
                w-full;

            svg,
            h2,
            p {
                @apply mb-2;
            }
        }
    }

    &__checklist {
        @apply -md:col-span-3;
    }

    &__contact {
        @apply col-span-1;
    }

    &__contract-banner {
        @apply mb-2;

        .banner {
            @apply justify-start;
        }
    }

    &__details {
        @apply grid
            gap-8
            grid-cols-3;
    }

    &__heading {
        @apply mb-4;
    }

    &__hr {
        @apply col-span-3
            border-b-[0.84px]
            border-neutral-30
            mt-6
            mb-2;
    }

    &__schedule,
    &__location,
    &__contact {
        @apply col-span-3;
    }

    &__title {
        @apply flex
            fill-accents-blue-base
            items-center
            mb-4;

        svg {
            @apply mr-1;
        }
    }
}
