.enrollment-summary-list {
    &__enrollment-id {
        @apply underline;
    }

    &__account-name {
        @apply text-base
            text-neutral-90;
        .badge {
            @apply mb-1;
        }
    }
    &__account-email {
        @apply text-neutral-70
        font-bold;
        text-transform: uppercase;
    }

    td.enrollment,
    td.assessment,
    td.evaluation,
    td.attendance {
        width: 1%;
    }

    .attendance {
        &-status {
            @apply grid
                grid-rows-3;

            &-badge {
                @apply m-auto
                    row-start-2;
            }
        }

        &-ratio {
            @apply m-auto
                row-start-3;
        }
    }
}
