.product-activation-details-section {
    h2 {
        @apply mb-0;
    }

    ol {
        @apply list-decimal
            pl-8;
    }

    ul {
        @apply list-disc
            pl-8;
    }

    &__aen {
        &-details {
            @apply grid
                grid-cols-1
                gap-4;
        }
    }

    &__available {
        @apply mt-4;
    }

    &__header {
        @apply flex
            items-center
            space-x-6
            mb-4
            mt-8;
    }

    &__catalog {
        &-details {
            @apply grid
                grid-cols-1
                gap-4;

            &-section {
                @apply min-h-[1.6875rem] /* Comment to prevent build from failing*/;
            }
        }
    }

    &__content {
        @apply grid
            grid-cols-2
            gap-4
            break-words;
    }

    &__image {
        @apply rounded-md;

        &-container {
            @apply border-[0.063rem]
                border-neutral-70
                rounded-md
                h-[6.563rem]
                w-20;
        }

        &-label {
            @apply mb-2;
        }
    }

    &__lock {
        @apply fill-neutral-70
            mr-2;
    }

    &__metadata {
        @apply grid
            grid-cols-4
            gap-4
            mt-6;
    }

    &__metadata-trainer {
        @apply grid
            grid-cols-2
            gap-4
            mt-6;
    }
    &__metadata-trainer-info {
        @apply grid
            grid-cols-1
            gap-4;
    }

    &__badge {
        @apply mr-1;
    }

    .modal__panel {
        @screen sm {
            width: 885px;
        }
    }
}
