.edit-learner-modal {
    &__grid {
        @apply grid
            grid-cols-2
            gap-6;

        + .form-field,
        + .edit-learner-modal__grid {
            @apply mt-4;
        }
    }
}
