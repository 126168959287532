.user-contract-ill-detail-enrollment {
    &__header {
        @apply flex
            items-center
            justify-between;

        &__title {
            @apply flex
                items-center
                space-x-2;
        }
    }

    &__table {
        @apply mt-6
            pb-8;

        p + p.-label {
            @apply mt-1;
        }
        td {
            @apply align-top;
        }
        td.enrollment,
        td.assessments,
        td.evaluation,
        td.attendance {
            width: 1%;
        }
        td.attendance {
            span {
                @apply inline-block
                    px-3
                    py-1.5;
            }
        }
    }
}
