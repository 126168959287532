input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"] {
    @apply border
        border-neutral-70
        inline-block
        mt-1
        p-2
        rounded-md
        text-neutral-90
        w-full
        text-sm
        disabled:bg-neutral-05
        disabled:border-neutral-50
        disabled:text-neutral-70
        focus-visible:border-accents-blue-dark
        focus-visible:outline-none
        focus-visible:shadow-accents-blue-base/50
        focus-visible:shadow-focus-visible;
}
