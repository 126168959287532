.authenticated-layout {
    $breadcrumbsHeight: 2.0625rem;
    $headerHeight: 5rem;

    @apply h-full
        w-full;

    &__body {
        @apply flex
            flex-col
            w-full;

        height: calc(100% - $headerHeight);
    }

    &__breadcrumbs {
        height: $breadcrumbsHeight;
    }

    &__header {
        @apply w-full;

        height: $headerHeight;
    }

    main {
        height: calc(100% - $breadcrumbsHeight);
    }
}
