.my-learning-assignments-page {
    &__quick__links {
        .heading {
            @apply mb-6;
        }

        @apply mb-16;
    }

    &__assignments {
        @apply grid
            grid-cols-3
            gap-8
            mb-16;

        @screen -lg {
            @apply grid-cols-2;
        }

        @screen -md {
            @apply grid-cols-1;
        }
    }

    &__calendar {
        @apply mb-16;
    }

    &__calendar__toggle {
        @apply flex
            gap-x-5
            items-center;
    }

    &__empty {
        @apply flex
            flex-col;
        align-items: center;
        &__link {
            @apply text-accents-blue-dark
        underline;
            width: fit-content;
        }
    }

    &__assignment-filter {
        .button {
            @apply m-0;
            
            &.-selected {
                @apply bg-white
                    text-neutral-90;
            }

            &:first-child {
                @apply me-0
                    rounded-e-none;
            }

            &:last-child {
                @apply ms-0
                    rounded-s-none;
            }
        }
    }

    &__header {
        @apply flex
            gap-4
            items-center
            justify-between
            mb-4;

        @screen -md {
            @apply flex-col
                items-start;
        }

        .form-field {
            @apply w-40;
        }

        &__text {
            @apply flex;
            svg {
                @apply fill-neutral-90
                    ml-1;
            }
        }
    }

    &__notifications {
        @apply mb-8;
    }

    &__section {
        @apply my-8
            px-8
            pb-8
            max-w-[1152px]
            mx-auto;

        h2 {
            @apply text-neutral-90
                font-light
                mb-0;
        }
    }
}

html[dir=rtl] .my-learning-assignments-page {
    &__assignment-filter {

    }
}
