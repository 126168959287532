.attempt-modal {
    .form-field,
    .form-field-readonly {
        + .form-field {
            @apply mt-6;
        }
    }

    p + .form-field {
        @apply mt-4;
    }

    &__completed {
        @apply mt-8;
    }

    .calendar-date-picker {
        @apply w-full;
    }
}
