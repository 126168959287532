.user-providers-new-page {
    @apply max-w-[1152px]
        mx-auto
        p-8
        pb-24
        relative;

    h1 {
        @apply mb-6;
    }

    &__section {
        h2 {
            @apply mb-3;

            + h3 {
                @apply mt-5;
            }
        }

        &__grid {
            @apply grid
                grid-cols-3
                gap-6;
        }

        &__details {
            @apply w-2/3;
        }

        &__heading {
            @apply flex
                justify-between
                mt-12;
        }
    }

    &__actions {
        @apply fixed
            flex
            flex-1
            justify-end
            bottom-0
            left-0
            w-full
            py-2
            px-6
            border-t
            border-t-neutral-50
            bg-white;
    }
}
