.profile-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__organization-name {
        @apply border-neutral-50
            border;
    }
}
