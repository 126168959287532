.product-selection-card {
    &__title {
        @apply grid
            grid-cols-[1fr_max-content]
            mb-4;

        h3 {
            @apply font-light
                text-2xl;
        }
    }
}
