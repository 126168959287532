.learner-training-summary-ilt-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    h1 {
        @apply mb-8;
    }

    &__contract-banner {
        @apply mb-10
            -mt-4;

        .banner {
            @apply justify-start;
        }
    }

    &__divider {
        @apply bg-neutral-30
            mt-14
            mb-12;

        height: 1px;
    }
}
