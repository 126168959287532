.event-contact-card {
    &__header {
        @apply flex 
            justify-between
            mb-2;

        h3 {
            @apply font-light
                text-2xl;
        }
    }

    &__subtext {
        @apply font-normal
            text-lg
            text-neutral-70;
    }
}
