.my-teaching-assignments-page {
    &__header {
        @apply flex
            items-center
            space-x-4
            mb-4;

        h2 {
            @apply mb-0;
        }

        .form-field {
            @apply w-40;
        }
    }

    &__section {
        @apply my-8
            px-8
            max-w-[1152px]
            mx-auto;
    }

    &__notifications {
        @apply mb-10;
    }

    &__upcoming-teachings {
        @apply mb-10;
    }

    &__past-teachings {
        &__header {
            @apply flex
                justify-between;
        }

        &__search {
            @apply w-80;
        }

        h2 {
            @apply inline-block;
        }
    }

    &__tooltip {
        @apply h-[.75rem]
            w-[.75rem]
            mb-6;
    }

    /* tables */
    &__section {
        .teaching-calendar {
            td.type,
            td.start-date {
                width: 1%;
            }
            .location {
                max-width: 200px;
            }
            .start-date {
                @apply whitespace-nowrap;

                svg {
                    @apply align-top;
                }
            }
        }
        .past-teachings {
            td.type,
            td.start-date,
            td.attendance {
                width: 1%;
            }
            .location {
                max-width: 200px;
            }
            .start-date {
                @apply whitespace-nowrap;

                svg {
                    @apply align-top;
                }
            }
        }
    }

    @screen -md {
        &__past-teachings {
            &__header {
                @apply flex-col;
            }
            &__search {
                @apply w-full;
            }
        }
    }
}
