.instructor-enh-profile-page {
    @apply max-w-[1152px]
        mx-auto
        pb-6;

    &__section {
        @apply mb-8;

        > p {
            @apply w-3/5;
        }
    }

    &__instructor {
        @apply flex
            space-x-4;

        > * {
            @apply p-6
                border
                border-neutral-30
                rounded-md;

            &:first-child {
                @apply w-2/3;
            }

            &:last-child {
                @apply w-1/3;
            }
        }

        &__about {
            .form-field > label {
                @apply font-bold;
            }

            &__section {
                @apply mt-8;

                > a {
                    + .toggle-label {
                        @apply mt-6;
                    }
                }
            }

            &__row {
                @apply mt-4;
            }

            &__grid {
                @apply break-words
                    grid
                    grid-cols-3
                    gap-7;

                .form-field-readonly + .form-field-readonly {
                    @apply mt-0;
                }

                + a {
                    @apply inline-block
                        mt-4;
                }
            }
        }

        &__preferences {
            &__section {
                @apply mt-8;

                &.-small-spacing {
                    @apply mt-4;
                }
            }

            &__selections {
                @apply mt-3.5;

                .checkbox {
                    + .checkbox {
                        @apply mt-4;
                    }
                }
            }
        }

        &__resume {
            &__section {
                @apply mt-8;
            }
        }
    }

    &__avatar {
        @apply flex
            items-center
            space-x-4
            mt-4;

        &__image-container {
            @apply h-24
                w-24
                overflow-hidden;
        }

        .form-field label {
            @apply font-normal;
        }
    }
}
