.provider-enh-layout-header {
    @apply mb-8
        overflow-x-auto
        text-center;

    &__tabs {
        @apply mb-1
            px-8
            w-max;

        .-active {
            @apply order-first;
        }
    }

    @screen md {
        @apply overflow-x-visible
            mx-0
            px-0;

        &__tabs {
            @apply w-full;

            .-active {
                @apply order-none;
            }
        }
    }
}
