.event-details-form {
    @apply mb-6;

    &__type {
        h2 {
            @apply mb-4;
        }
    }

    .form-radio-card-list-with-description__cards {
        @apply gap-8;
    }
}
