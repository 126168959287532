.admin-reports-list-page {
    @apply flex
        flex-col
        h-full;

    h2 {
        @apply mb-8;
    }

    &__reports {
        @apply grid
            grid-cols-1
            lg:grid-cols-2
            gap-6;

        .card {
            @apply h-full;
            
            svg {
                @apply fill-accents-blue-base;
            }

            p {
                &:first-of-type {
                    @apply my-2
                        text-lg
                        text-neutral-90;
                }
            }
        }
    }
}
