.enrollment-report-download-button {
    &__report-ready-toast {
        .c-toast-content__body {
            @apply flex-grow;
        }
    }

    &__report-download {
        @apply flex
            flex-row
            justify-between;
    }
}
