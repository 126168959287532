.event-registration-form-en {
    h5 {
        @apply font-light
            mb-4
            text-2xl
            text-neutral-90;
    }
    &__header {
        @apply flex
            justify-start
            mb-10
            space-x-10;
    }

    &__form {
        @apply grid
            grid-cols-2
            gap-x-8
            mt-5;

        &-column {
            @apply space-y-10;
        }

        & > * {
            @apply h-16;
        }
    }

    &__radio-button {
        @apply fill-accents-green-dark;
    }

    textarea {
        height: 8.25rem;
    }
}
