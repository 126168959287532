.event-product-card {
    @apply flex
        h-[191px]
        rounded
        shadow-neutral-50
        shadow-card
        relative;

    &__detail {
        @apply grow
            bg-white
            rounded-l;
    }

    &__name {
        @apply p-4
            line-clamp-5;
        height: 8.5rem;
        word-break: break-word;
    }

    &__topic {
        @apply text-neutral-70
            text-sm;
    }

    &__select-action {
        @apply mt-auto
            absolute
            bottom-4
            left-4;
    }

    &__image-container {
        @apply w-[134px]
            shrink-0;

        img {
            @apply rounded-r
                h-full;
        }
    }

    &__info-button {
        @apply absolute
                top-4
                right-4
                z-20;
    }
}
