.unit-edit-modal {
    h4 {
        @apply mb-3
            font-bold
            text-neutral-90
            text-base;

        &.-section {
            @apply mt-8;
        }
    }

    .form-field + .form-field {
        @apply mt-4;
    }
}
