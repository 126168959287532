.cancel-changes-modal {
    .modal__body-inverted {
        @apply px-4;
    }

    .modal__body__content-wrap {
        @apply px-2
                py-2;
    }

    .modal__panel-inverted {
        @screen sm {
            width: 336px;
        }
    }
}
