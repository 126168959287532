.reports-sidebar {
    @apply flex
        flex-col
        gap-4
        py-6
        px-8
        overflow-x-auto;
    
    > * {
        @apply block
            transition-colors
            no-underline
            text-neutral-70
            text-sm
            hover:text-accents-blue-dark;

        &.-selected {
            @apply font-bold
                text-accents-blue-dark;
        }
    }

    @screen -lg {
        @apply flex-row
            gap-8;

        > * {
            @apply whitespace-nowrap;
        }
    }
}
