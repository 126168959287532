.event-content-list-item {
    @apply flex
        gap-4
        justify-between;

    &__description {
        @apply text-neutral-70;
    }

    &__download-icon {
        @apply flex
            items-center;
    }

    &__name {
        @apply text-neutral-90;
    }
}
