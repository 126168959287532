.user-role-card {
    @apply bg-white
        flex
        flex-col
        items-center
        mb-8
        pb-8
        pt-10
        px-14
        rounded;

    &__content {
        @apply flex
            flex-col
            items-center
            mb-6;
    }

    &__icon {
        @apply fill-accents-blue-base
            mb-4;
    }

    &__name {
        @apply font-bold
            text-center
            tracking-[0.25rem]
            text-neutral-90;
    }

    &__selected {
        @apply font-bold
            italic
            text-accents-blue-dark;
    }
}
