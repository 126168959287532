.course-card {
    @apply flex
        flex-col
        gap-4
        mt-4;

    @screen sm {
        @apply flex-row
            gap-0
            space-x-8
            items-center;
    }

    &__donut {
        @apply inline-flex;
    }

    @screen sm {
        @apply space-x-8;

        > * {
            &:nth-child(1) {
                @apply text-left;
                width: calc(100% - (255px));
            }

            &:nth-child(2) {
                @apply text-left;
                width: 75px;
            }

            &:nth-child(3) {
                @apply text-left;
                width: 75px;
            }

            &:nth-child(4) {
                @apply text-right;
                width: 130px;
            }
        }
    }

    &__lock {
        @apply align-text-top
            mr-4
            fill-neutral-70;
    }
}
