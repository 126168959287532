.anchor-icon {
    @apply inline-flex
        items-center;

    .text {
        line-height: 0;
    }

    &.-button {
        @apply bg-neutral-05
            inline-block
            p-2
            rounded-[5px]
            hover:bg-neutral-30;

        & > svg {
            @apply
            ml-0;
        }
    }
}
