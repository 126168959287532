.my-certificates-page {
    &__empty {
        @apply flex
            flex-col;

        align-items: center;

        &__link {
            @apply text-accents-blue-dark
                underline;

            width: fit-content;
        }
    }

    &__header {
        @apply flex
            gap-4
            justify-between
            mb-4;

        @screen -md {
            @apply flex-col;
        }

        &__text {
            svg {
                @apply fill-neutral-90
                    ml-1;
            }
        }

        &__search {
            @apply w-80;

            .search-text-input {
                @apply mb-0;
            }

            @screen -sm {
                @apply w-auto;
            }
        }
    }

    table {
        td.type,
        td.enrollment,
        td.completion {
            width: 1%;
        }
    }

    &__section {
        @apply my-8
            px-8
            pb-8
            max-w-[1152px]
            mx-auto;

        h2 {
            @apply text-neutral-90
                font-light
                mb-0;
        }
    }
}
