.learner-breakdown-card {
    @apply flex-1;
    & > h3 {
        @apply font-light
            leading-8
            text-2xl;
    }

    &__details-link {
        @apply text-xs;
    }

    &__disabled {
        @apply fill-neutral-50;
    }

    td.status {
        width: 1%;
    }
}
