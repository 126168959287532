.process-navigation-footer {
    @apply bg-white
        flex
        justify-between;

    &__sidebar-actions,
    &__footer-actions,
    &__nav {
        @apply flex
            items-center;
    }

    &__sidebar-actions {
        @apply bg-neutral-90
            border-t
            border-t-neutral-70
            px-6
            py-2.5
            w-1/4;

        .button-icon {
            @apply w-full;
        }
        
        .button-icon.-inverted {
            svg {
                @apply fill-accents-blue-base;
            }

            &:hover {
                svg {
                    @apply fill-white;
                }
            }
        }
        .button-icon.-inverted.-disabled {
            svg {
                @apply fill-neutral-90;
            }
        }
    }

    &__nav {
        @apply bg-white
            border-t
            border-t-neutral-30
            px-6
            py-2.5
            w-3/4;

        &__actions {
            @apply flex
                grow
                justify-end;
        }
    }
    
    @screen xl {
        &__sidebar-actions {
            @apply w-[320px]/* ... */;
        }
        &__nav {
            width: calc(100% - 320px);
        }
    }
}
