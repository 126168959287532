.attempt-summary {
    &__manual-entry {
        @apply border-t-[0.84px]
                border-neutral-30
                mt-10
                mb-2;
        p {
            @apply mt-10
                italic;
        }
    }

    &__change-history {
        @apply mt-4
            pt-8;

        &__notes {
            @apply whitespace-pre-wrap;
        }
    }
}
