.user-contract-oll-detail-info {
    h3 {
        @apply mb-8;
    }

    &__section {
        @apply w-full;

        + .user-contract-oll-detail-info__section {
            @apply mt-10;

            .topic {
                & > div > div {
                    @apply flex
                        flex-wrap
                        gap-3;
                }
            }
        }
    }

    &__grid {
        @apply grid
            grid-cols-1
            md:grid-cols-2
            lg:grid-cols-3
            gap-8;
    }
}
