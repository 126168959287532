.calendar-date-picker {
    @apply text-left;
    
    $calendarIconheight: 0.875rem;
    $calendarIconWidth: 0.765625rem;

    &__calendar {
        height: $calendarIconheight;
        width: $calendarIconWidth;
        @apply fill-neutral-70
            flex-initial;
    }

    &__chevron {
        @apply fill-neutral-70
            flex-none
            mt-1;
    }

    &__label {
        @apply text-neutral-70
            text-sm
            flex-auto
            pl-2.5;
    }

    &__text {
        @apply border
            flex
            h-10
            items-center
            p-2
            rounded-md
            w-60
            border-neutral-70
            focus-visible:border-accents-blue-dark
            focus-visible:outline-none
            focus-visible:shadow-accents-blue-base/50
            focus-visible:shadow-focus-visible;

        &.-disabled {
            @apply bg-neutral-05
                    border-neutral-50
                    text-neutral-70;
        }
    }
}

// NFPA-6690 - Override for datepicker caret positioning bug
.react-datepicker__triangle {
    left: -20px !important;
}
