.toggle-label {
    @apply flex
        items-center
        space-x-2;

    + .toggle-label {
        @apply mt-4;
    }

    .-label {
        @apply whitespace-nowrap;
    }
}