.event-session-card {
    @apply bg-neutral-05
        rounded;

    &__session-details {
        @apply p-2
            mb-2
            xl:flex;

        p {
            @apply text-xs
            mb-0
            text-neutral-90;
        }
    }

    &__session-time {
        @apply font-bold
            ml-auto;
    }
}
