.content-list-table {
    td {
        @apply align-top;
    }
    td.description {
        p {
            @apply max-w-[336px]
                text-xs;
        }
    } 
}
