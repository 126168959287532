.selected-group-user-modal-user-list {
    td.id {
        width: 1%;
    }

    th,
    td {
        &.action {
            @apply text-right
                w-28;
        }
    }

    td.user-name {
        @apply block pl-0 w-72;
    }

    &__user-email {
        @apply block
            text-xs truncate;
    }
}
