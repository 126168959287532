.instructor-directory-page {
    @apply flex
        flex-col
        h-full;

    .side-content-left-layout {
        @apply overflow-hidden;

        & > *:last-child {
            @apply overflow-hidden;
        }
    }

    &__cards {
        @apply grid
            gap-8
            grid-cols-3
            -xl:grid-cols-1;
    }

    &__sidebar {
        @apply p-8;

        .-label {
            @apply mb-2;
        }

        .form-field + .form-field {
            @apply mt-4;
        }

        .form-field + .-clear {
            @apply mt-4;
        }

        &__search {
            input {
                @apply pl-8;
            }

            .search-text-input {
                @apply mb-4;
            }
        }
    }
}
