.user-contracts-info-page {
    @apply flex
        flex-col
        h-full;
    
    h2 {
        @apply mb-8;
    }

    &__section {
        @apply grid
            grid-cols-1
            md:grid-cols-2
            lg:grid-cols-3
            gap-8
            mt-4;

        + h3 {
            @apply mt-8;
        }
    }
}
