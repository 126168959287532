.edit-organization-modal {
    &__overflow {
        @apply h-[399px]
            px-2;
    }

    &__section {
        @apply pb-6;

        > p:first-of-type {
            + p {
                @apply mt-4;
            }
        }

        p.-light {
            @apply mb-0.5
                italic;
        }

        .form-field + .form-field {
            @apply mt-4;
        }

        p + .form-field {
            @apply mt-4;
        }

        .form-field + p {
            @apply mt-6;
        }
    }
}
