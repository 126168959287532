.event-name-and-type-form {
    &__faux-label {
        @apply font-normal
            mb-1.5
            text-lg;
    }

    &__name {
        @apply mb-7;

        p {
            @apply mb-5
            text-neutral-70
                text-sm;
        }
    }
}

label[for="eventType"] {
    @apply font-normal
    mb-1.5
    text-lg;
}
