.course-activation-modal {
    &__assignment {
        @apply bg-neutral-05
            grid
            gap-4
            grid-cols-2
            mt-[0.6rem]
            p-3
            rounded
            text-neutral-70
            text-xs;
    }

    &__change-log {
        @apply mb-3;
    }

    &__content {
        p.-xlarge {
            font-size: 26px;
            line-height: 36px;
            max-width: 85%;
        }
    }

    &__modal-heading {
        @apply text-[1.625rem]
            leading-9;

        &-description {
            @apply font-light
                mb-8
                text-2xl
                w-3/4;
        }
    }

    &__section {
        @apply mt-8;

        h2 {
            @apply mb-0;
        }

        p + p {
            @apply mt-1;
        }
    }

    &__text-area {
        @apply mt-4;
    }

    .modal__panel {
        @screen sm {
            width: 885px;
        }
    }
}
