.public-layout {
    @apply flex
        flex-col
        h-full
        w-full
        
        -lg:h-auto;

    &__body {
        height: calc(100% - 5rem);
        overflow: hidden;

        @apply flex

            -lg:flex-col
            -lg:h-auto


            -lg:flex-col-reverse;

        main {
            @apply flex
                flex-col
                gap-8
                p-8
                w-1/2

                -lg:w-full;
        }
    }
    /*
    &:has(.public-layout-video-container) {
        @apply h-auto;
    }
    */
}
