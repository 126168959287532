.learner-assessment-summary-details {
    &__columns-admin,
    &__columns-learner {
        @apply flex
            justify-between;
    }

    &__heading {
        @apply font-light
            leading-[2.125rem]
            mb-5
            text-2xl
            text-neutral-90;
    }

    dd {
        @apply font-normal
            leading-5
            text-neutral-90
            text-sm;
    }

    dl {
        @apply gap-y-5;
    }

    dt {
        @apply font-bold
            text-neutral-90
            text-sm;
    }

    @screen lg {
        &__columns {
            @apply grid-cols-3
                gap-0;
        }
    }

    @screen -lg {
        &__columns-admin,
        &__columns-learner {
            @apply flex-col
                gap-4;
        }
    }
}
