.product-info {
    &__description {
        @apply mb-auto
            col-span-2;
    }

    &__detail-items {
        @apply flex
            gap-4
            mb-6;
    }

    &__header {
        @apply font-light
            mb-6;
    }

    &__image {
        @apply float-right
            h-60
            mb-2
            ml-12
            w-[11.375rem]
            rounded-md;
    }

    dt,
    dd {
        @apply text-neutral-90
                text-sm;
    }
    dt {
        @apply font-bold
                capitalize
                tracking-widest;
    }
}
