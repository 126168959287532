.event-change-summary-modal {
    h3 {
        @apply font-normal
            text-neutral-90
            text-lg
            mb-4;
    }

    &__content {
        p.-xlarge {
            font-size: 26px;
            line-height: 36px;
            max-width: 85%;
        }
    }

    .modal__panel {
        @screen sm {
            width: 885px;
        }
    }

    &__name {
        @apply mt-6;
    }

    .banner {
        @apply justify-start;
    }
}
