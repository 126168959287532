.event-activation-schedule-section {
    @apply mt-8;

    h3 {
        @apply font-normal
            text-neutral-90
            text-lg
            mb-2;
    }

    &__header {
        @apply flex justify-between;

        h2 {
            @apply mb-2;
        }

        .toggle-label {
            @apply mb-2;
        }
    }

    &__first-row {
        border-bottom: 0;
        @apply border-t-[0.84px]
            border-t-neutral-30;

        .-day,
        .-day-name,
        .-session,
        .-time,
        .-ceus {
            @apply align-top
            whitespace-nowrap;
        }

        &:last-of-type {
            @apply border-b-[0.84px]
            border-b-neutral-30;
        }
    }

    &__additional-rows {
        @apply border-none;
    }

    &__total-row {
        @apply border-b-0
        border-t-[0.84px]
        border-t-neutral-30
            font-bold
            text-accents-green-dark
            #{!important};

        &__exception-granted {
            svg {
                @apply align-text-bottom
                    fill-accents-green-dark
                    mr-1;
            }
        }
    }

    .banner {
        @apply justify-start;
    }
}
