.event-registration-form-contract {
    @apply mt-44
        text-center;

    &__text {
        @apply font-light
            mb-6
            text-[1.625rem]
            text-neutral-70;
    }
}
