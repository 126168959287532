.avatar {
    @apply bg-cover
        bg-center
        h-full
        w-full;

    &.-circle {
        @apply rounded-full;
    }

    &.-portrait {
        @apply aspect-[16/9] /* comment to keep things from breaking */;
    }

    &.-square {
        @apply aspect-[1/1]
            rounded;
    }
}
