.user-contracts-oll-list-page {
    @apply flex
        flex-col
        h-full;

    .side-content-left-layout {
        @apply overflow-hidden;

        & > *:last-child {
            @apply overflow-hidden;
        }
    }

    &__header {
        @apply flex
            items-center
            justify-between
            mb-8;

        h2 {
            @apply mb-0;
        }

        &__actions {
            @apply flex
                items-center
                space-x-2;

            &__search {
                .search-text-input {
                    @apply mb-0
                        w-72;
                }
            }
        }
    }

    table {
        .contract-number,
        .lms-id,
        .enrollment,
        .expiration-date {
            @apply whitespace-nowrap;
        }
        td.contract-number,
        td.lms-id,
        td.enrollment,
        td.expiration-date {
            width: 1%;
        }
    }
}
