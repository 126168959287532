.product-assessment-manager {
    &__create-new-version-link {
        @apply text-neutral-70
            text-xs
            underline;
    }

    &__header {
        @apply w-full
            grid
            grid-cols-2
            items-center
            justify-between
            mb-8;

        h5 {
            @apply font-light;
        }

        &__actions {
            @apply flex
            gap-5
            items-center
            justify-end;
        }
    }

    .banner {
        @apply mb-6 -mt-4;

        .paragraph {
            @apply mx-2;
        }
    }
}
