.event-list {
    td.type,
    td.start-date,
    td.status {
        width: 1%;
    }
    .start-date {
        @apply whitespace-nowrap;

        svg {
            @apply align-top;
        }
    }
}
