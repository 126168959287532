.instructor-application-status-bar-card {
    $b: &;

    @apply flex flex-col col-span-2 row-start-2 pt-4 md:pt-0;

    &__title,
    &__description {
        @apply text-center;
    }

    &__title {
        @apply text-2xl leading-9 mb-2;
    }
    &__description {
        @apply leading-6;
    }

    &__icon {
        @apply self-center md:-translate-y-4 bg-white border-4 border-status-success-base rounded-full flex items-center justify-center;
    }

    &__svg {
        @apply fill-status-success-base text-status-success-base;
    }

    &.-disabled {
        #{$b}__title,
        #{$b}__description {
            @apply text-neutral-50;
        }
    }
}
