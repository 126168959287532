.extend-expiration-modal {
    p + .form-field {
        @apply mt-6;
    }

    .calendar-date-picker__text {
        @apply w-full;
    }

    .react-datepicker-popper {
        z-index: 100;
    }
}
