.summary-header {
    @apply flex
        gap-8    
        items-center
        mb-6;

    &__title {
        @apply flex
            items-center
            space-x-2;

        h2 {
            @apply mb-0;
        }

        .icon {
            @apply fill-accents-blue-base;
        }
    }

    &__badges {
        @apply flex
            flex-wrap
            gap-2    
            items-center;
    }

    @screen -lg {
        @apply flex-col
            gap-4
            items-start;
    }
}
