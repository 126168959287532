select {
    background: transparent; /* because tailwind bg-transparent isn't working ¯\_(ツ)_/¯ */

    @apply appearance-none
        border
        border-neutral-70
        inline-block
        p-2
        rounded-md
        text-neutral-90
        w-full
        text-sm
        focus-visible:border-accents-blue-dark
        focus-visible:outline-none
        focus-visible:shadow-accents-blue-base/50
        focus-visible:shadow-focus-visible
        disabled:bg-neutral-05
        disabled:border-neutral-50
        disabled:opacity-100
        disabled:text-neutral-70;

    &.-no-value {
        @apply text-placeholder;
    }

    & > option:not(:disabled) {
        @apply text-neutral-90;
    }
}
