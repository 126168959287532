.learner-online-learning-unit-course-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__heading {
        @apply mb-8;
    }

    &__details {
        @apply flex
            flex-row
            border-b
            border-b-neutral-30;

        > :first-child {
            @apply w-3/4
                pb-8
                pr-8
                border-r
                border-r-neutral-30;

            .badge {
                @apply mt-2
                        mb-12;
            }
        }

        > :last-child {
            @apply pb-8
                pl-8
                w-1/4
                border-t-0;

            button {
                @apply mt-12;
            }
        }

        p.-label + p {
            @apply mt-2;
        }

        p + p.-label {
            @apply mt-6;
        }

        p.version {
            @apply my-2;
        }

        @screen -sm {
            @apply flex-col;

            > :first-child {
                @apply border-r-0
                w-full;
            }

            > :last-child {
                @apply border-t
                    border-t-neutral-30
                    pt-8
                    pl-0
                    w-full;
            }
        }
    }

    &__resources {
        @apply mt-8
            pb-12
            border-b
            border-b-neutral-30;

        &__list {
            @apply grid
                grid-cols-3
                gap-8;
        }

        @screen -lg {
            &__list {
                @apply grid-cols-2;
            }
        }

        @screen -md {
            &__list {
                @apply grid-cols-1;
            }
        }
    }

    &__assessments {
        @apply mt-8
            mb-4;

        table thead tr th {
            &:nth-child(1) {
                width: 10%;
            }

            &:nth-child(2) {
                width: 10%;
            }

            &:nth-child(3) {
                width: 10%;
            }

            &:nth-child(4) {
                width: 60%;
            }

            &:last-child {
                width: 10%;
            }
        }

        table tbody tr td {
            &:last-child {
                @apply text-right;
            }
        }
    }

    h3 {
        @apply text-2xl
            text-neutral-90
            font-light
            mb-4;
    }
}
