.checked {
    @apply fill-status-success-base; 
}

.unchecked {
    @apply fill-neutral-50;
}

.event-details-checklist-item {
    @apply flex
        leading-7;
    
    &__icon {
        @apply w-5
            h-5
            shrink-0;
        margin: 3px 7px 0 0;
    }
}
