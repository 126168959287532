.toggle {
    &__input {
        @apply h-0
            w-0
            absolute
            invisible;

        &:checked + .toggle__label .toggle__label__slider {
            @apply -translate-x-[100%];
            left: calc(100% - 4px);
        }

        &:checked + .toggle__label {
            @apply bg-accents-blue-base;
        }

        &:disabled + .toggle__label {
            @apply bg-neutral-30;
        }
    }

    &__label {
        @apply transition-all
            flex
            items-center
            justify-between
            cursor-pointer
            w-[36px]
            h-[22px]
            rounded-full
            relative
            bg-neutral-90
            focus:ring-1
            focus:ring-accents-blue-base
            focus:outline-none;

        &.-disabled {
            @apply cursor-default;
        }

        &__slider {
            @apply transition-all
                content-['']
                absolute
                left-1
                top-1
                w-[14px]
                h-[14px]
                rounded-full
                bg-white;

            &.-true {
                @apply bg-neutral-50;
            }
        }
    }
}
