.product-activation-evaluation-section {
    &__section {
        @apply mt-8;

        h2 {
            @apply mb-0;
        }

        h3 {
            @apply font-light
                text-xl
                mb-0;
        }

        &__header-with-badge {
            @apply flex justify-start;
        }

        p + p {
            @apply mt-1;
        }
    }
}
