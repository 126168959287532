.event-enrollment-manager {
    &__header {
        @apply flex
            items-center
            justify-between
            mb-8;

        &__title {
            @apply flex
                fill-accents-blue-base
                items-center
                space-x-2;
        }
    }

    &__enrollment-list {
        @apply pb-0;
    }
}
