.process-sidebar {
    @apply flex
        flex-col;
    height: calc(100% - 5rem);
    
    &__header {
        @apply flex
            justify-between
            items-center
            px-6;

        &__actions {
            @apply flex
                items-center
                space-x-3;

            [disabled] {
                @apply cursor-not-allowed;

                svg {
                    @apply fill-neutral-70;
                }
            }
        }

        svg {
            @apply fill-neutral-50
                w-6
                h-6;
        }
    }

    &__details {
        @apply mt-4
            px-6
            pb-6
            border-b
            border-b-neutral-70;

        .donut {
            @apply justify-center
                mb-6;
        }

        p {
            @apply text-center;
        }

        &__tags {
            @apply flex
                flex-wrap
                justify-center
                items-center
                space-x-2
                mt-4
                text-center;

            > * {
                @apply mt-2;
            }
        }
    }

    &__nav {
        @apply grow
            overflow-y-auto
            p-6;
    }
}
