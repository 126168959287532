.send-an-email-page {
    @apply flex
    flex-col
    h-full;

    .side-content-left-layout {
        @apply overflow-hidden
        -lg:overflow-visible;

        & > *:last-child {
            @apply overflow-hidden
            -lg:overflow-visible;
        }
    }

    &__content-wrap {
        @apply pb-20
            pt-8
            px-8;
    }

    &__content {
        @apply max-w-[1152px]
            mx-auto
            p-8;
    }

    &__footer {
        @apply fixed
            bg-white
            border-t
            border-t-neutral-50
            bottom-0
            flex
            h-20
            items-center
            justify-end
            px-8
            right-0;
        width: inherit;
    }
}
