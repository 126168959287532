.context-menu-file-preview {
    @apply block
         px-4
         py-2
         text-xs
         hover:bg-neutral-05
         hover:text-neutral-90
         no-underline;

    &.-active {
        @apply bg-neutral-05
                text-neutral-90;
    }

    &:not(:first-child) {
        @apply border-t-[1px]
        border-neutral-30;
    }
}
