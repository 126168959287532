.course-zip-package-form {
    .banner {
        @apply mb-8;

        .notification-banner__banner__message {
            @apply mx-2;
        }
    }

    &__header {
        @apply mb-8;
    }

    td.requirePassed {
        width: 1%;
    }
}
