.achievement-card {
    @apply flex
    flex-col
    gap-4
    items-center;

    &__icon {
        @apply fill-accents-blue-base;

        .icon {
            width: 72px;
            height: 72px;
        }
    }

    &__generating {
        @apply text-center;
        p {
            @apply text-accents-blue-dark;
        }
    }
}
