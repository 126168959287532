.third-party-dashboard-online-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__section {
        @apply mb-20;
    }

    h2 {
        @apply mb-5;
    }

    &__current {
        @apply grid
            grid-cols-1
            sm:grid-cols-2
            lg:grid-cols-3
            gap-7;
    }

    &__notifications {
        @apply mb-7;
    }

    table {
        .contract-number,
        .enrollment,
        .completion {
            width: 1%;
            @apply whitespace-nowrap;
        }
    }
}
