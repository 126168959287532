.admin-edit-event-product-page {
    @apply max-w-[1152px]
        mx-auto
        flex
        gap-8
        px-8
        h-full;

    &__main {
        @apply w-2/3
            -xl:w-1/2
            h-full
            flex
            flex-col;
    }

    &__content {
        @apply h-full
            ml-[-10px]
            mr-[-25px]
            mb-[-13px]
            pl-[10px]
            pr-[25px]
            overflow-y-auto
            py-8;

        h1 {
            @apply mb-8;
        }
    }

    &__sidebar {
        @apply w-1/3
            -xl:w-1/2
            py-8;

        .card {
            @apply flex
                flex-col
                justify-between
                h-full;
        }
        &__summary {
            @apply h-full;
        }
    }
}
