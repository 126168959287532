.user-en-instructor-page {
    @apply flex
        flex-col
        h-full;

    h2 {
        @apply mb-8;
    }

    .required-trainings,
    .completed-trainings {
        .type {
            @apply whitespace-nowrap;
        }
        td.type,
        td.progress {
            width: 1%;
        }
    }

    &__banner-container {
        @apply w-full
            mb-8;
    }
}

.side-content-left-layout > *:last-child .user-en-instructor-page .footer {
    @apply border-0;
}
