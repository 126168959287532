.no-access-page {
    @apply flex
        flex-col
        h-full
        items-center
        justify-center
        text-center;

    .button {
        @apply mb-12
            mt-8;
    }
    .icon {
        @apply mb-4
            h-20
            w-20;
    }

    &__contact {
        @apply text-neutral-70;

        a {
            @apply underline;
        }
    }

    &__heading {
        @apply mb-2
            text-neutral-90;
    }
}
