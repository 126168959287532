.learner-layout-header {
    @apply flex
        items-center
        bg-white
        border-b
        border-b-border;

    & > section {
        @apply flex
            h-full
            items-center;

        &:first-child {
            @apply justify-start
                flex-1;
        }

        &:last-child {
            @apply justify-end;
        }
    }

    &__actions {
        @apply flex
            items-center
            h-full
            w-20
            space-x-4;

        .button {
            @apply h-full
                w-full;

            svg {
                @apply fill-neutral-70;
            }
            &.-unread {                
                @apply relative;
                   
                &::before {
                    @apply content-['']
                        absolute
                        bg-accents-blue-base
                        top-7
                        right-5
                        z-50
                        w-2
                        h-2
                        rounded-md;
                }
            }
        }
    }
}
