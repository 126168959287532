.report-enrollment {
    h2 {
        @apply mb-8;
    }

    table {
        td.id {
            width: 1%;
        }
    }

    &__banner {
        @apply w-full mb-6;
    }
}
