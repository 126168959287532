.public-layout-video-container {
    @apply flex
        items-center
        justify-center
        bg-cover
        h-full
        w-1/2
        
        -lg:aspect-[5/3]
        -lg:h-auto
        -lg:w-full;

    .laptop-container {
        @apply flex
            items-center
            justify-center
            relative
            right-[-15%]
            top-[-6%]
            w-full

            -lg:right-0
            -lg:top-[-3%]
            -lg:w-3/4;
    }

    &__laptopImage {
        @apply absolute
            max-w-none
            w-full
            z-10;
    }

    &__video {
        @apply w-[78%]
            rounded-[1.5%]
            absolute
            max-w-none
            z-20;
    }
}
