.edit-event-layout {
    @apply h-full
        w-full
        overflow-hidden;

    &__window {
        @apply flex
            flex-row
            grow
            w-full
            h-full;

        &__sidebar {
            @apply bg-neutral-90
                self-stretch
                pt-6
                flex-col
                w-1/4
                h-full;
        }

        &__main {
            @apply flex-col
                relative
                h-full
                w-3/4;

            & > #chakra-skip-nav {
                @apply h-full;
            }

            &.-has-fixed-banner {
                @apply pt-[40px] /* comment to keep things from breaking */;
            }
        }
    }

    &__body {
        @apply overflow-y-auto;
        height: calc(100% - 5rem);
    }

    &__cancel-edit-mode-modal {
        .modal__panel-inverted {
            @apply w-96;
        }
    }

    &__content {
        @apply h-full;
    }

    &__footer {
        @apply fixed
            bottom-0
            w-full
            h-20;

        &-edit-buttons {
            @apply flex w-full;

            &-cancel {
                width: fit-content !important;
                padding: 8px !important;
            }

            &-save {
                width: inherit !important;
            }
        }
    }

    &__bannerButton {
        &[disabled] {
            @apply opacity-50 cursor-not-allowed;
        }
    }

    @screen xl {
        &__window {
            &__sidebar {
                @apply w-[320px]/* ... */;
            }

            &__main {
                width: calc(100% - 320px);
            }
        }
    }
}
