.event-day-manager {
    .card {
        @apply grid
            grid-rows-[1fr_min-content]
            h-full;
    }

    &__day {
        @apply mb-8;
    }

    &__credit-hours-message {
        @apply mb-8
            text-neutral-90
            inline-flex
            text-base;

        a {
            @apply font-bold
                text-base;
        }
    }

    &__day-form {
        @apply mb-6;
    }

    h3 {
        @apply text-2xl
            mb-4
            text-neutral-90;
    }

    &__day-heading {
        @apply text-sm;
    }

    &__session-heading {
        @apply text-xs;
    }

    &__session:nth-child(n + 2) {
        @apply border-t-[1px]
            border-neutral-30;
    }
}
