.product-version-list {
    &__changed-by-id {
        @apply font-bold;
    }

    &__header {
        @apply mb-8;
    }
    .data-table {
        .date,
        .changed-by {
            @apply whitespace-nowrap;
            width: 1%;
        }
    }
}
