.announcement-list {
    &__table {
        td.status {
            width: 1%;
        }
    }

    &__changed-by-id {
        @apply font-bold
            text-neutral-70
            text-xxs;
    }
}
