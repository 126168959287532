.event-assessment-list {
    &__card {
        @apply flex
            items-center
            justify-between;

        &__actions {
            @apply flex
                gap-8;
            
            .button {
                & + .button {
                    @apply m-0;
                }
            }
        }
    }

    .-no-results-row {
        @apply text-center
            italic;
    }

    @screen -md {
        &__card {
            @apply flex-col
                gap-4
                items-start;

            &__actions {
                @apply gap-4;
            }
        }
    }

    @screen -sm {
        &__card {
            &__actions {
                @apply flex-col;
            }
        }
    }
}
