.edit-contract-modal {
    &__heading {
        @apply font-medium
            mb-4
            text-lg
            text-neutral-90;
    }

    .form-field {
        @apply mt-4;
    }

    &__product {
        @apply mt-6;

        &-info {
            @apply italic
                mt-3
                text-neutral-70
                text-sm;
        }
    }
}
