.event-activation-contact-information {
    @apply mt-8;

    &__header {
        @apply flex justify-between;

        h3 {
            @apply font-normal
                text-neutral-90
                text-lg
                mb-2;
        }

        .toggle-label {
            @apply mb-2;
        }
    }

    &__details {
        &-contact {
            @apply font-normal
                leading-5
                text-sm
                text-neutral-70;
        }

        &-sub {
            @apply font-normal
                leading-5
                mb-5
                text-sm
                text-neutral-70;
        }

        &-title {
            @apply font-normal
                leading-6
                mt-5
                text-base
                text-neutral-70;
        }
    }

    &__container {
        @apply grid
            grid-cols-2;
    }

    .banner {
        @apply justify-start;
    }
}
