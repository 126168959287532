.event-in-person-location-form {
    @apply grid
        grid-cols-2
        gap-8;

    &__column {
        @apply grid
            grid-cols-1
            gap-8
            h-fit;
    }

    textarea {
        height: 8.25rem;
    }
}
