.learner-take-a-training-achievements-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__title {
        @apply flex
        fill-accents-blue-base
        items-center
        mb-4;

        svg {
            @apply mr-1;
        }
    }

    &__achievement_cards {
        @apply grid
            grid-cols-2
            gap-7
            -md:grid-cols-1;

        a {
            @apply text-accents-blue-dark underline;
        }
    }
}
