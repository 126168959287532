.context-menu-anchor {
    @apply block
         px-4
         py-2
         text-xs
         text-neutral-90
         hover:bg-neutral-05
         hover:text-neutral-90
         no-underline
         whitespace-nowrap;

    &.-active {
        @apply bg-neutral-05
                text-neutral-90;
    }

    &.-disabled {
        @apply text-neutral-50
            cursor-not-allowed;
    }

    &:not(:first-child) {
        @apply border-t-[1px]
        border-neutral-30;
    }
}
