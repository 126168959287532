.data-table{@apply w-full
        border-collapse}.data-table .arrows,.data-table .action,.data-table .-ellipsis{@apply px-2}.data-table .action{@apply pr-0}.data-table td.arrows,.data-table td.order,.data-table td.action,.data-table td.-ellipsis{width:1%}.data-table td.action,.data-table td.-ellipsis{@apply relative}.data-table td.arrows{@apply whitespace-nowrap}.data-table td.arrows .arrow-down .icon,.data-table td.arrows .arrow-up .icon{@apply h-5
                    w-5}.data-table td.arrows .arrow-down path,.data-table td.arrows .arrow-up path{@apply fill-neutral-70
                    focus-visible:fill-neutral-90
                    focus-visible:outline-[0.1875rem]
                    focus-visible:outline-accents-blue-base/40
                    focus-visible:opacity-70
                    hover:fill-neutral-90
                    hover:opacity-70}.data-table td.arrows .arrow-down.-disabled path,.data-table td.arrows .arrow-up.-disabled path{@apply fill-neutral-50
                    opacity-70
                    text-neutral-50}.data-table td.arrows .button{@apply p-0}.data-table td.arrows .button+.button{@apply ml-1}.data-table .-no-results-row{@apply text-center
            italic}.data-table thead tr,.data-table tbody tr{@apply border-b
                border-b-neutral-30}.data-table thead tr th,.data-table thead tr td,.data-table tbody tr th,.data-table tbody tr td{@apply p-6
                    text-xs
                    text-start}.data-table thead tr th .checkbox.-medium,.data-table thead tr th p.-small,.data-table thead tr td .checkbox.-medium,.data-table thead tr td p.-small,.data-table tbody tr th .checkbox.-medium,.data-table tbody tr th p.-small,.data-table tbody tr td .checkbox.-medium,.data-table tbody tr td p.-small{@apply text-xs}.data-table thead tr th .checkbox__checkmark,.data-table thead tr td .checkbox__checkmark,.data-table tbody tr th .checkbox__checkmark,.data-table tbody tr td .checkbox__checkmark{@apply w-4
                        h-4}.data-table thead tr th .checkbox__checkmark:after,.data-table thead tr td .checkbox__checkmark:after,.data-table tbody tr th .checkbox__checkmark:after,.data-table tbody tr td .checkbox__checkmark:after{left:5px;top:1px}.data-table thead tr th{@apply pt-4
                    pb-1
                    whitespace-nowrap}.data-table.-tight thead tr th{@apply p-3}.data-table.-tight tbody tr td{@apply p-3}.data-table tbody tr td{@apply py-4}@screen -lg{.data-table thead tr th,.data-table thead tr td,.data-table tbody tr th,.data-table tbody tr td{@apply p-4
                        text-xxs}.data-table thead tr th .checkbox.-medium,.data-table thead tr th p.-small,.data-table thead tr td .checkbox.-medium,.data-table thead tr td p.-small,.data-table tbody tr th .checkbox.-medium,.data-table tbody tr th p.-small,.data-table tbody tr td .checkbox.-medium,.data-table tbody tr td p.-small{@apply text-xxs}.data-table thead tr th{@apply pt-2
                        pb-1}.data-table tbody tr td{@apply py-2}.data-table tbody tr td .badge{@apply text-xxs}}@screen -lg{.table-wrap{@apply relative}.table-wrap .data-table tr:has(td.-ellipsis)::after{@apply bg-white/90
                        content-[""]
                        min-w-[40px]
                        p-4
                        table-cell
                        sticky
                        right-0
                        z-[1]
                        w-10}.table-wrap .data-table td.-ellipsis{@apply absolute
                    right-0
                    z-[2]
                    w-auto}}.table-wrap .table-scroll.-overflow{@apply overflow-x-auto}.table-wrap .table-scroll.-overflow::before,.table-wrap .table-scroll.-overflow::after{@apply block
                    left-0
                    mx-auto
                    my-4
                    italic
                    sticky
                    text-center
                    text-neutral-70
                    text-xs;content:"≪  scroll to see more  ≫"}.table-wrap .table-scroll.-overflow:before{@apply mb-0}