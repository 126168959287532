.assessment-attempts-details {
    @apply mt-7;

    &__heading {
        @apply flex
            items-center
            justify-between
            mb-6;

        &__details {
            @apply flex
                items-center;

            h5 {
                @apply mr-2;
            }

            select {
                @apply w-14;
            }
        }
    }

    .attempt-details {
        .record-summary {
            @apply flex
                flex-col
                gap-4;
        }
    }
}
