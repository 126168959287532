.third-party-online-learning-details-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__heading {
        @apply mb-8;
    }

    h2 {
        @apply mb-5;
    }
    
    &__title {
        @apply flex
            fill-accents-blue-base
            items-center
            mb-4;

        svg {
            @apply mr-1;
        }
    }

    &__contract {
        @apply mb-8;
    }

    &__details {
        @apply col-span-2;
    }

    &__edit-button {
        @apply flex;

        @screen md {
            @apply justify-end;
        }
    }
}
