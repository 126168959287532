.third-party-dashboard-instructor-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__header {
        @apply flex
            items-center
            space-x-4
            mb-4;

        h2 {
            @apply mb-0;
        }

        .form-field {
            @apply w-40;
        }
    }

    &__section {
        @apply mb-20;
    }

    &__current {
        @apply grid
            grid-cols-1
            sm:grid-cols-2
            lg:grid-cols-3
            gap-7;
    }

    &__notifications {
        @apply mb-7;
    }

    &__banner {
        @apply flex
            items-center
            justify-between
            w-full
            py-2.5;

        &__message {
            @apply flex
                items-center
                space-x-1;
        }

        &__actions {
            @apply flex
                space-x-2;

            > a {
                @apply text-xs;
            }
        }

        + .third-party-dashboard-instructor-page__banner {
            @apply mt-2;
        }
    }

    table {
        .contract-number,
        .type,
        .enrollment,
        .assessment,
        .evaluation,
        .attendance {
            width: 1%;
            @apply whitespace-nowrap;
        }
    }
}
