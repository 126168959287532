.instructor-application-status-bar {
    @apply block
        md:grid
        grid-cols-8
        gap-x-8
        pt-6
        before:content-none
        md:before:content-['']
        before:col-span-6
        before:col-start-2
        before:bg-status-success-base
        before:h-1
        before:justify-center
        before:translate-y-1;
}
