.learner-assessment-summary {
    &__attempts-card {
        @apply mt-7;
    }

    &__heading {
        @apply font-light
            leading-[2.125rem]
            mb-5
            text-2xl
            text-neutral-90;
    }
}
