h1,
h2,
h3,
h4,
h5,
h6 {
    @apply leading-[1.4em]
        font-light;
    
    &.-xxsmall {
        @apply font-normal
            text-lg;
    }

    &.-xsmall {
        @apply text-2xl;
    }

    &.-small {
        @apply text-3xl;
    }

    &.-default {
        @apply text-4xl;
    }

    &.-large {
        @apply text-5xl;
    }

    &.-xlarge {
        @apply text-6xl;
    }
}
