.edit-course-materials-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__archived-banner {
        @apply pl-[3.5rem]
            rounded-none
            w-full;
    }

    &__back-button {
        @apply mb-10;
    }

    &__cancel-edit-mode-modal {
        .modal__panel-inverted {
            @apply w-96;
        }
    }

    &__card {
        @apply grid
            grid-cols-4
            my-5;
    }

    &__header {
        @apply mb-8;

        .button-icon {
            @apply px-6;
        }
    }
}
