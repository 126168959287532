.rich-text-editor {
    & > label {
        @apply font-medium
            text-neutral-90
            text-sm;
    }

    .quill {
        @apply mt-1
            border
            border-neutral-70
            rounded-md;
    }

    .ql-toolbar.ql-snow {
        @apply border-t-0
            border-r-0
            border-b-neutral-70
            border-l-0
            font-body;
    }

    .ql-container.ql-snow {
        @apply border-none
            font-body;

        p,
        ul,
        ol,
        blockquote {
            font-size: 0.875rem;
        }
    }

    .ql-editor {
        @apply min-h-[144px]
            max-h-[144px]/* */;
    }

    &.-disabled {
        @apply pointer-events-none;

        .quill {
            @apply bg-neutral-05;
        }
        
        .ql-snow {
            .ql-stroke {
                @apply stroke-neutral-30;
            }

            .ql-fill,
            .ql-stroke.ql-fill {
                @apply fill-neutral-30;
            }

            .ql-picker {
                @apply text-neutral-30;
            }
        }
        
        .quill,
        .ql-toolbar.ql-snow {
            @apply border-neutral-30;

            p,
            ul,
            ol,
            blockquote {
                @apply text-neutral-70;
            }
        }
    }
}
