.course-details-change-history {
    @apply mb-10;
    .data-table {
        td {
            @apply align-top;
        }

        .date,
        .changed-by {
            width: 1%;
            @apply whitespace-nowrap;
        }

        .change-log-new {
            @apply italic
                text-neutral-70;
        }

        .created-log {
            @apply italic
                text-neutral-70;
        }
    }
}
