.learner-checklist {
    @apply overflow-x-auto;

    h6 {
        @apply font-bold
            leading-4
            mb-2
            text-base
            text-neutral-90;
    }

    &__description {
        @apply leading-6
            mb-2
            text-base
            text-neutral-70;
    }

    &__heading {
        @apply font-light
            leading-[2.125rem]
            mb-5
            text-2xl
            text-neutral-90;
    }

    &__info {
        @apply font-bold
            leading-[1.125rem]
            text-neutral-70
            tracking-[0.15rem]
            text-xs;
    }

    &__instructor-icon {
        @apply border-2
            border-neutral-90
            h-[3.125rem]
            mr-3
            w-[3.125rem]
            rounded-full;
    }

    &__instructor-section {
        @apply flex
            justify-start;
    }

    &__name {
        @apply leading-7
            text-lg
            text-neutral-90;
    }

    &__sections {
        @apply gap-8
            grid
            grid-rows-3;
    }
    &__summary {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 100% */

        letter-spacing: 0.02em;

        /* Neutral/ Neutral-90 */

        color: #001a39;
    }
}
