.user-training-detail-page {
    @apply flex
        flex-col
        h-full;

    h2 {
        @apply mb-8;
    }

    &__header {
        @apply flex
            items-center
            justify-between;
    }

    &__details {
        @apply my-8
            lg:max-w-[80%]
            max-w-full;
    }

    &__badges {
        @apply mt-4
            -ml-3;

        > * {
            @apply inline-block
                ml-3
                mt-2
                border-neutral-30
                text-neutral-30;
        }
    }
}
