.edit-product-course-learning-path-page {
    .arrow-up,
    .arrow-down {
        padding: 0 !important;

        .icon {
            @apply h-5
                w-5;
        }

        path {
            @apply fill-neutral-70
                focus-visible:fill-neutral-90
                focus-visible:outline-[0.1875rem]
                focus-visible:outline-accents-blue-base/40
                focus-visible:opacity-70
                hover:fill-neutral-90
                hover:opacity-70;
        }

        &.-disabled path {
            @apply fill-neutral-50
                opacity-70
                text-neutral-50;
        }
    }
    .arrow-down {
        margin-left: 0.25rem !important;
    }

    .banner {
        @apply mb-6 -mt-4;

        .paragraph {
            @apply mx-2;
        }
    }

    &__create-new-version-link {
        @apply text-neutral-70
            text-xs
            underline;
    }

    &__banner-container {
        @apply w-full;
    }

    &__header,
    &__content {
        @apply max-w-[1152px]
            mx-auto;
    }

    &__header {
        @apply grid
            grid-cols-2
            items-center
            mb-8
            pt-4
            justify-between;

        &__actions {
            @apply flex
                items-center
                justify-end
                space-x-12;
        }
    }

    &__content {
        @apply pb-8;
    }

    h5 {
        @apply font-light mb-0;
    }

    &__content {
        .course {
            &.-empty {
                @apply text-center;

                .course__wrapper {
                    h2 {
                        @apply text-[1.125rem]
                            mb-2;
                    }

                    > p {
                        @apply mb-6;
                    }
                }
            }
        }

        .unit {
            @apply border
                border-neutral-50
                rounded-md
                pb-8;

            + .unit {
                @apply mt-6;
            }

            &.-empty {
                @apply text-center;

                .unit__wrapper {
                    h2 {
                        @apply font-normal
                            text-neutral-70
                            mb-2;
                    }

                    > p {
                        @apply mb-6;
                    }
                }
            }

            &__header {
                @apply flex
                    items-center
                    justify-between
                    h-[50px]
                    bg-neutral-05
                    rounded-t-md
                    mb-6
                    px-2;

                &__details {
                    @apply flex
                        items-center
                        space-x-2;

                    &__sort {
                        @apply leading-none;
                    }
                }

                &__actions {
                    @apply flex
                        space-x-2
                        items-center
                        whitespace-nowrap
                        relative;

                    &__badges {
                        @apply flex
                            space-x-2
                            pr-6;
                    }
                }

                h3 {
                    @apply text-lg
                        text-heading
                        font-normal
                        tracking-wide;
                }
            }

            &__wrapper {
                @apply px-6;
            }
        }
    }
}
