.activation-modal-unit-course-list-table {
    td.topic,
    td.id,
    td.optional {
        width: 1%;
    }
    .topic {
        @apply whitespace-nowrap;
    }
}
