.course-version-list {
    &__changed-by-id {
        @apply font-bold;
    }

    .data-table {
        .change-log,
        .date,
        .changed-by {
            @apply whitespace-nowrap;
            width: 1%;
        }
    }
}
