.form-text-input {
    & > label {
        @apply font-medium
            text-neutral-90
            text-sm;
    }

    &.-error {
        & > .form-text-input__input {
            @apply border-accents-red-base
            outline-none
            shadow-accents-red-base/50
            shadow-focus-visible;
        }
    }
}
