.instructor-enh-layout {
    @apply h-full
        pb-10
        overflow-y-auto
        w-full;

    &__main {
        @apply px-8
            pb-8
            max-w-[1440px]
            mx-auto;
    }
}
