.edit-course-version-list-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__archived-banner {
        @apply pl-[3.5rem]
            rounded-none
            w-full;
    }

    &__header {
        @apply mb-8;
    }
}
