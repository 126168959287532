.admin-edit-event-contact-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__content {
        &-header {
            @apply flex justify-between;
        }
        &-title {
            @apply font-normal
                text-neutral-90
                text-lg;
        }
    }

    &__header {
        @apply mb-8;

        h2 {
            @apply mb-0
                text-3xl;
        }
    }

    &__subtitle {
        @apply font-normal
            text-lg
            text-neutral-70;
    }
}
