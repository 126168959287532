.aen-application-log-list {
    @apply mt-5;

    tr td:nth-child(1) {
        width: 15%;
    }

    tr td:nth-child(2) {
        @apply italic
            whitespace-pre-line;

        width: 30%;
    }

    tr td:nth-child(3) {
        width: 12%;
    }

    tr td:nth-child(4) {
        @apply break-words
            whitespace-pre-line;
    }

    &__message__sent {
        @apply italic;
    }
}
