.provider-status-card {
    &__columns {
        @apply grid
                grid-cols-2
                gap-7
                mt-1;

        &__fields {
            @apply grid
                    grid-cols-2
                    gap-7;

            .form-field-readonly {
                + .form-field-readonly {
                    @apply mt-0;
                }

                .paragraph.-label {
                    @apply text-neutral-90;
                }
            }
        }
    }
}
