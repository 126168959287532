.create-instructor-product-version-materials {
    &__banner {
        @apply mb-5;

        .text {
            @apply px-2;
        }
    }

    &__header {
        @apply mb-8;
    }

    &__header-buttons {
        .button {
            @apply align-middle;
        }

        .button-icon {
            @apply px-6;
        }
    }
}
