.learner-past-trainings {
    h2 {
        @apply inline-block;
    }

    .table-wrap {
        @apply mb-6;
    }
    table {
        .type,
        .expiration-date,
        .status,
        .completion-date,
        .ceus,
        .action {
            width: 1%;
            @apply whitespace-nowrap;
        }
    }

    &__header {
        @apply flex
            gap-4
            justify-between
            mb-4;

        @screen -md {
            @apply flex-col;
        }

        &__text {
            svg {
                @apply fill-neutral-90
                    ms-1;
            }
        }
    }

    &__incomplete-icon {
        @apply fill-neutral-70;
    }

    &__search {
        @apply w-80;

        .search-text-input {
            @apply mb-0;
        }

        @screen -sm {
            @apply w-auto;
        }
    }

    &__withdrawn-icon {
        @apply fill-accents-red-base;
    }
}
