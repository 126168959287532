.admin-edit-event-details-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__header {
        @apply mb-8;
    }

    .card + .card {
        @apply mt-7;
    }
}
