.user-contracts-new-page {
    @apply max-w-[1152px]
        mx-auto
        p-8
        pb-32
        relative;

    h1 {
        @apply mb-6;
    }

    &__roles {
        @apply my-6;

        .tag-list {
            @apply mt-4;
        }
    }

    &__section {
        h2 {
            @apply mb-3;

            + h3 {
                @apply mt-5;
            }
        }

        p + h3 {
            @apply mt-5;
        }

        h3 + .form-field {
            @apply mt-2;
        }

        .form-field + h3 {
            @apply mt-0.5
                italic;
        }

        h3 + .toggle-label {
            @apply mt-6;
        }

        .user-contracts-new-page__section__grid + .user-contracts-new-page__section__grid,
        h3 + .user-contracts-new-page__section__grid {
            @apply mt-4;
        }

        .user-contracts-new-page__section__grid + h3 {
            @apply mt-6;
        }

        &__grid {
            @apply grid
                grid-cols-3
                gap-6;
        }

        &__details {
            @apply w-2/3;
        }

        &__heading {
            @apply flex
                justify-between
                mt-12;
        }
    }

    &__actions {
        @apply fixed
            flex
            flex-1
            h-20
            items-center
            justify-end
            bottom-0
            left-0
            w-full
            px-8
            border-t
            border-t-neutral-50
            bg-white;
    }
}
