.provider-dashboard-page {
    @apply max-w-[1152px]
        mx-auto
        py-6;

    &__banner {
        @apply flex
            items-center
            justify-between
            w-full
            py-2.5;

        &__message {
            @apply flex
                items-center
                space-x-1;
        }

        &__actions {
            @apply flex
                space-x-2;

            > a {
                @apply text-xs;
            }
        }

        + .provider-dashboard-page__banner {
            @apply mt-2;
        }
    }

    h2 {
        @apply mb-5;
    }

    &__section {
        @apply mb-20;
    }

    &__notifications {
        @apply mb-10;
    }

    &__details-card {
        &__columns {
            @apply grid
                grid-cols-2
                gap-7
                mt-1;

            &__fields {
                @apply grid
                    grid-cols-2
                    gap-7;

                .form-field-readonly {
                    + .form-field-readonly {
                        @apply mt-0;
                    }

                    .paragraph.-label {
                        @apply text-neutral-90;
                    }
                }
            }
        }
    }
}
