.donut {
    @apply flex
        gap-2
        items-center;

    font-size: 1.5em;

    &.-large {
        font-size: 6.75em;

        .donut__chart {
            &__side {
                border-width: 0.1em;
            }
        }

        .donut__shadow {
            border-width: 0.1em;
        }
    }

    &.-inverted {
        .donut__shadow {
            @apply border-accents-blue-light;
        }

        .donut__chart__side {
            @apply border-accents-blue-base;
        }
    }

    &__wrapper {
        @apply relative
            m-0;

        height: 1em;
        width: 1em;

        p {
            @apply absolute
                left-1/2
                top-9
                -translate-x-1/2
                text-center
                text-2xl
                leading-4
                text-white;

            .sublabel {
                @apply uppercase;

                font-size: 10px;
            }
        }
    }

    &__chart {
        @apply absolute
            left-0
            top-0
            h-full
            w-full;

        clip: rect(0, 1em, 1em, 0.5em);

        &.-over-half {
            clip: rect(auto, auto, auto, auto);

            > *:last-child {
                @apply rotate-180;
            }
        }

        &.-under-half {
            clip: rect(0, 1em, 1em, 0.5em);

            > *:last-child {
                @apply rotate-0;
            }
        }

        &__side {
            @apply absolute
                left-0
                top-0
                h-full
                w-full
                border-neutral-70;

            border-radius: 50%;
            border-width: 0.2em;
            clip: rect(0em, 0.5em, 1em, 0em);

            &:first-child {
            }

            &:last-child {
                transform: rotate(0deg);
            }
        }
    }

    &__shadow {
        @apply h-full
            w-full
            border-neutral-30;

        border-radius: 50%;
        border-width: 0.2em;
    }
}
