.about-this-training {
    &__header {
        @apply flex
            items-center
            space-x-6
            mb-6;

        &__title {
            @apply flex
                items-center
                space-x-1;

            h2 {
                @apply mb-0;
            }

            .icon {
                @apply fill-accents-blue-base;
            }
        }

        &__badges {
            @apply flex
                items-center
                space-x-2;
        }
    }

    &__card {
        &__details {
            @apply w-3/4;

            p + p {
                @apply mt-2;
            }
        }

        &__tags {
            @apply mt-6;

            span {
                @apply mr-2;
            }
        }
    }
}
