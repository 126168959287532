.admin-edit-event-location-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    &__header {
        @apply flex
            justify-between
            mb-8;
    }
}
