.event-contact-form {
    @apply grid
        grid-cols-2
        gap-8
        mt-5;

    &__default,
    &__custom {
        @apply flex
            items-baseline;
        
            .radio-button {
                @apply relative
                    top-1;
            }
        
            &-details {
            @apply ml-4;

            &-contact {
                @apply font-normal
                    leading-5
                    text-sm
                    text-neutral-70;
            }

            &-sub {
                @apply font-normal
                    leading-5
                    mb-4
                    text-sm
                    text-neutral-70;
            }

            &-title {
                @apply font-normal
                    text-base
                    text-neutral-70;
            }
        }

        .radio-button {
            label {
                @apply hidden;
            }
        }
    }

    &__input {
        @apply -ml-8
            mb-5;
    }
}
