.application-title {
    @apply flex
        items-center;

    .breadcrumbs {
        @apply border-s
            border-neutral-30
            h-20
            ps-4;
    }

    & > p {
        @apply hidden
            text-heading
            text-2xl;
    }

    &__logo {
        a {
            @apply flex
                w-[80px]
                h-[80px]
                items-center
                justify-center;

            img {
                @apply w-[42px]
                    min-w-[36px]/* comment to keep stuff from breaking */;
            }

            & > * {
                @apply mr-0;
            }
        }
    }

    @screen sm {
        & > p {
            @apply block;
        }
    }

    @screen -md {
        &__logo {
            @apply border-r
                border-neutral-30;
        }
        
        .breadcrumbs {
            @apply hidden;
        }
    }
}
