.admin-dashboard-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    h2 {
        @apply mb-8;
    }
    
        &__section {
        @apply mb-20;
    }

    &__new {
        @apply grid
            grid-cols-3
            gap-7;

        button:hover {
            .card {
                @apply border-2
                    border-accents-blue-base;
            }
        }

        .card {
            @apply text-center
                py-20
                border-2
                transition-colors;

            border-color: transparent;

            svg {
                @apply fill-accents-blue-base;
            }

            p {
                &:first-of-type {
                    @apply my-2
                        text-lg
                        text-neutral-90;
                }
            }
        }
    }
}
