.button-icon {
    svg {
        @apply transition-colors;
    }

    &__text {
        @apply align-middle
            px-1.5;
    }

    &.-medium {
        @apply px-2.5
            py-2;
    }

    &.-small {
        @apply p-2;
    }

    &.-tertiary-alt,
    &.-inverted {
        svg {
            @apply fill-neutral-70;
        }

        &.-disabled {
            svg {
                @apply fill-neutral-50;
            }
        }
    }
    &.-transparent {
        svg {
            @apply fill-white
            hover:fill-neutral-05;
        }
    }
}
