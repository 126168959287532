.alert-notice {
    .-error {
        @apply grid
            grid-rows-1
            justify-items-center
            space-y-10;
    }

    p {
        @apply text-accents-red-dark;
    }

    svg path {
        @apply fill-accents-red-dark;
    }
}
