.anchor {
    @apply fill-neutral-90
        text-accents-blue-dark
        relative
        text-sm
        transition-all
        underline
        hover:fill-accents-blue-base
        hover:text-accents-blue-base;

    & > * {
        @apply me-1.5;

        &:last-child {
            @apply me-0;
        }
    }

    &.-active {
        @apply text-accents-blue-dark;
    }

    &.-wide {
        @apply text-neutral-70
            text-xs
            font-bold
            tracking-widest
            uppercase
            hover:text-accents-blue-base;
    }
}
