.edit-group-modal {
    &__section {
        @apply mb-6;

        > p:first-of-type {
            + p {
                @apply mt-4;
            }
        }

        p.-light {
            @apply mb-0.5
                italic;
        }

        .form-field + .form-field {
            @apply mt-4;
        }

        p + .form-field {
            @apply mt-4;
        }

        .form-field + p {
            @apply mt-6;
        }
    }
}
