.event-day-card {
    @apply border        
            border-neutral-30
            rounded
            my-3
            p-3
            w-full
            relative;

    &__event-day-options-menu {
        @apply absolute
            top-2
            right-2
            z-10;

            svg {
                @apply fill-neutral-70;
            }
    }

    &__date {
        @apply text-xs
            text-neutral-70
            font-bold
            mb-3;
        letter-spacing: 0.15em;
    }

    &__name {
        @apply my-3
            text-sm
            truncate
            w-full;
    }

    &__credit-hours {
        @apply font-bold
            mt-3
            text-accents-blue-dark
            text-right
            text-xs
            uppercase;
        letter-spacing: 0.15em;
    }
}
