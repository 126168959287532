.user-providers-associated-users-page {
    @apply flex
        flex-col
        h-full;

    table {
        .name {
            @apply whitespace-nowrap;
        }
        td.id,
        td.name {
            width: 1%;
        }
    }
}
