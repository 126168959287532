.context-menu-button {
    @apply block
        px-4
        py-2
        fill-neutral-50
        w-full
        text-left
        text-xs
        whitespace-nowrap
        hover:bg-neutral-05
        hover:text-neutral-90;

    &:disabled {
        @apply text-neutral-50
            cursor-not-allowed;
    }

    svg {
        @apply -ml-2
            mr-2;
    }

    &.-active {
        @apply bg-neutral-05
            text-neutral-90;
    }

    &:not(:first-child) {
        @apply border-t-[1px]
        border-neutral-30;
    }
}
