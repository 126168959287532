.course-content-card {
    p + p {
        @apply mt-2;
    }

    a {
        @apply block
            mt-2
            fill-neutral-70;
    }
}
