.provider-enh-resources-page {
    @apply max-w-[1152px]
        mx-auto
        pb-6;

    &__cards {
        @apply grid
            grid-cols-2
            gap-8
            mb-8;
    }

    &__heading {
        @apply text-neutral-90;
    }

    &__search {
        @apply mt-9 w-64;
    }

    &__section {
        @apply mb-8;
    }
}
