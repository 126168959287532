.event-description-card {

    &__description {
        @apply leading-6
            mb-7
            text-base
            text-neutral-70;
    }

    &__heading {
        @apply mb-4;
    }
}
