.user-login-page {
    @apply grow
        flex
        flex-col
        justify-center;
    
    p.-label {
        letter-spacing: 0.25em;
    }
    
        h1 {
        @apply mt-2
            mb-5;
    }

    &__actions {
        @apply flex
            flex-wrap
            gap-6
            items-center;

        .button.-medium.-link {
            @apply m-0
                p-0
                text-neutral-90;
        }
    }
}
