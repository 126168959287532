.submitted-evaluation {
    &__header {
        @apply mb-8
            text-neutral-90
            tracking-wide;

        h1 {
            @apply font-light;
        }

        p {
            @apply max-w-[62%]
                text-neutral-70;
        }
    }

    &__question {
        @apply w-[66%]
            max-w-[66%]
            break-words;
    }

    &__answer {
        @apply w-[34%]
            max-w-[34%]
            break-words;
    }
}
