.user-training-detail-overview {
    h4 {
        @apply font-normal;
    }
    
    &__section {
        @apply grid
            grid-cols-1
            md:grid-cols-2
            lg:grid-cols-3
            gap-8
            mb-12
            mt-4;
    }

    .badge-list {
        @apply mt-2;
    }
}
