.instructor-pending-dashboard {
    @apply max-w-[1152px]
        mx-auto
        p-8;

    h2 {
        @apply mb-6;
    }

    &__section {
        @apply mb-10;
    }
}
