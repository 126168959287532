.learner-take-a-training-event-content-page {
    @apply max-w-[1152px]
        mx-auto
        p-8;
    
    &__title {
        @apply flex
            fill-accents-blue-base
            items-center
            mb-4;

        svg {
            @apply mr-1;
        }
    }
}
