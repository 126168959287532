.empty-text {
    @apply w-full
        py-40
        text-center
        text-lg
        text-neutral-70;

    &.-spacing-none {
        @apply py-0;
    }

    &.-spacing-small {
        @apply py-6;
    }

    &.-table {
        @apply border-b
            border-b-neutral-30
            relative;

        padding-top: calc(10rem + 38.5px);
        
        &::before {
            @apply absolute
                block
                top-0
                w-full
                border-b
                border-b-neutral-30;
            
            content: "";
            height: 38.5px;
        }

        &.-spacing-none {
            padding-top: 38.5px;
        }

        &.-spacing-small {
            padding-top: calc(1.5rem + 38.5px);
        }
    }
}
