.product-course-series {
    &__header,
    &__content {
        @apply max-w-[1152px]
            mx-auto;
    }

    &__header {
        @apply grid
            grid-cols-2
            items-center
            mb-8
            justify-between;

        &__actions {
            @apply flex
                items-center
                justify-end
                space-x-4;
        }
    }

    &__content {
        @apply pb-8;
    }

    &__start-over {
        @apply underline;
    }

    h5 {
        @apply font-light mb-0;
    }

    .banner {
        @apply mb-8;
    }

    &__create-new-version-link {
        @apply text-neutral-70
            text-xs
            underline;
    }
}
