.admin-layout-header-profile-manager {
    &__transition {
        @apply top-0
            absolute
            right-[320px]
            -sm:right-0
            overflow-x-hidden;
    }
}

html[dir=rtl] .admin-layout-header-profile-manager {
    &__transition {
        @apply left-[320px]
            right-auto;

        @screen -sm {
            @apply left-0
                right-auto;
        }
    }
}
