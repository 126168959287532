.learner-assessment-summary-header {
    &__title {
        @apply mb-10
            mt-6;

        h1 {
            @apply inline-block
                font-light
                leading-[2.8125rem]
                text-3xl
                text-neutral-90;
        }

        span {
            @apply inline-block
                align-top
                mt-2
                ml-4;
        }
    }

    & > a {
        @apply rounded-[0.3125rem]
            font-bold
            no-underline
            bg-white
            border
            border-neutral-70
            fill-neutral-70
            text-neutral-90
            focus-visible:bg-neutral-05
            focus-visible:outline-[0.1875rem]
            focus-visible:outline-offset-1
            focus-visible:outline-accents-blue-base/40
            focus-visible:opacity-80
            hover:bg-neutral-05
            px-1
            py-1
            mr-5
            text-xs;

        &:hover {
            @apply fill-neutral-90
                    text-neutral-90;
        }
    }

    & > span {
        @apply overflow-hidden
            text-neutral-70
            text-ellipsis
            text-xs
            whitespace-nowrap;
    }
}
