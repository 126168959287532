.admin-edit-event-schedule-page {
    @apply grid
        grid-rows-[max-content_1fr]
        min-h-full
        max-w-[1152px]
        mx-auto
        p-8;

    &__header {
        .event-schedule-page-header {
            @apply mb-10;
        }
    }

    &__all-day-toggle-banner {
        @apply w-full
            mb-8;

        .card.-tight {
            @apply px-7;
        }
    }

    &__all-day-toggle {
        @apply flex
            justify-between
            py-0;
    }

    &__instruction {
        @apply mb-10;

        .paragraph,
        ul {
            @apply text-neutral-70;
        }

        ul {
            @apply list-disc
                my-4
                pl-10;
        }
    }

    &__time-zone {
        @apply mb-8
            px-7;

        width: calc(66.667% - 1.75rem);
    }

    &__content {
        @apply flex
            space-x-8;

        &__event-day-manager {
            @apply w-2/3;

            .event-day-manager {
                @apply h-full;
            }

            & > .card {
                @apply h-full
                    p-3
                    overflow-hidden;
            }
        }

        &__schedule-sidebar {
            @apply w-1/3;

            & > .card {
                @apply h-full
                    p-3
                    overflow-hidden;

                h3 {
                    @apply text-base
                        text-neutral-70
                        font-bold
                        my-3
                        text-center;
                }
            }
        }
    }
}
