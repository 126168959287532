.event-schedule-summary {
    @apply grid
        grid-rows-[max-content_1fr]
        h-full;

    &__content {
        @apply relative
            h-full;

        &__scroll {
            @apply absolute
            w-full
            h-full
            overflow-y-auto;
        }
    }

    &__container {
        @apply mt-6
            mb-6
            px-6
            flex
            flex-col
            h-full
            overflow-y-auto;
    }

    &__header {
        @apply h-10
            flex
            w-full
            text-neutral-90
            border-b-[1px]
            border-neutral-30
            whitespace-nowrap;
    }

    &__days-container {
        @apply flex
            flex-col
            w-full
            overflow-hidden;
    }

    &__day {
        @apply bg-white
            rounded
            p-4
            mb-2
            w-full;
    }

    &__empty-schedule-message {
        @apply absolute
            top-[40%]
            text-center
            text-neutral-70;
    }

    &__date,
    &__name {
        @apply mb-1;
    }

    &__date {
        @apply text-lg
            font-bold
            mb-2;
    }

    &__name {
        @apply truncate
            w-full;
    }

    &__credit-hours {
        @apply font-bold;
    }

    &__session-container {
        @apply bg-neutral-05
            rounded;
    }

    &__session-card {
        @apply p-2
            mb-2
            grid
            grid-cols-2
            gap-0;
        p {
            @apply text-sm
            mb-0;
        }
    }

    &__session-time {
        @apply font-bold
            text-right;
    }
}
